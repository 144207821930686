/*------------------------------------
  Snippets
------------------------------------*/

code {
  font-size: 100%;
}

summary {
  margin-bottom: .35rem;
}

.tab-content {
  :not(pre) > code[class*="language-"], pre[class*="language-"] {
    border-bottom-right-radius: .3125rem;
    border-bottom-left-radius: .3125rem;
  }
}

code[class*="language-"] {
  color: #00221C;
  font-size: .9375em;
  margin: 0;
  white-space: pre-wrap;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre[class*="language-"] {
  color: #00221C;
  font-size: .9375em;
  margin: 0;
  white-space: pre-wrap;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;

  &::-moz-selection, ::-moz-selection {
    text-shadow: none;
    background: #b3d4fc;
  }
}

code[class*="language-"] {
  &::-moz-selection, ::-moz-selection {
    text-shadow: none;
    background: #b3d4fc;
  }
}

pre[class*="language-"] {
  &::selection, ::selection {
    text-shadow: none;
    background: #b3d4fc;
  }
}

code[class*="language-"] {
  &::selection, ::selection {
    text-shadow: none;
    background: #b3d4fc;
  }
}

@media print {
  code[class*="language-"], pre[class*="language-"] {
    text-shadow: none;
  }
}

/* Code blocks */

pre[class*="language-"] {
  padding: 1.5rem;
  overflow: auto;
}

:not(pre) > code[class*="language-"], pre[class*="language-"] {
  background: #E7EAF3;
}

/* Light Theme */

.prism-light:not(pre) > .prism-light code[class*="language-"], pre.prism-light[class*="language-"] {
  background: #f9fafc;
}

.prism-light code[class*="language-"], pre.prism-light[class*="language-"] {
  color: #E7EAF3;
}

/* Inline code */

:not(pre) > code[class*="language-"] {
  padding: .1em;
  border-radius: .3em;
  white-space: normal;
}

.token {
  &.comment, &.prolog, &.doctype, &.cdata {
    color: slategray;
  }

  &.punctuation {
    color: #2f6f9f;
  }

  &.namespace {
    opacity: .7;
  }

  &.property, &.tag, &.boolean, &.number, &.constant, &.symbol, &.deleted {
    color: #2f6f9f;
  }

  &.selector, &.attr-name, &.string, &.char, &.builtin, &.inserted {
    color: #26a5b5;
  }

  &.operator, &.entity, &.url {
    color: #d09a5c;
    background: transparent;
  }
}

.language-css .token.string, .style .token.string {
  color: #d09a5c;
  background: transparent;
}

.token {
  &.atrule, &.attr-value, &.keyword {
    color: #d44950;
  }

  &.function, &.class-name {
    color: #DD4A68;
  }

  &.regex, &.important, &.variable {
    color: #e90;
  }

  &.important, &.bold {
    font-weight: bold;
  }

  &.italic {
    font-style: italic;
  }

  &.entity {
    cursor: help;
  }
}

div.code-toolbar {
  position: relative;

  > .toolbar {
    position: absolute;
    top: 1.0625rem;
    right: .75rem;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover > .toolbar {
    opacity: 1;
  }

  > {
    .toolbar {
      .toolbar-item {
        display: inline-block;
      }

      a {
        cursor: pointer;
      }

      button {
        background: none;
        border: 0;
        color: inherit;
        font: inherit;
        line-height: normal;
        overflow: visible;
        padding: 0;
        -webkit-user-select: none;

        /* for button */
        -moz-user-select: none;
        -ms-user-select: none;
      }

      a, button, span {
        color: #fff;
        padding: .5rem 1rem;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: .3125em;
        transition: all .2s ease-in-out;
      }

      a {
        &:hover, &:focus {
          color: #fff;
          background-color: #008060;
        }
      }

      button {
        &:hover, &:focus {
          color: #fff;
          background-color: #008060;
        }
      }

      span {
        &:hover, &:focus {
          color: #fff;
          background-color: #008060;
        }
      }
    }

    .prism-light + .toolbar {
      a, button, span {
        color: #E7EAF3;
        background-color: transparent;
      }

      a {
        &:hover, &:focus {
          color: #00221C;
          background-color: #008060;
        }
      }

      button {
        &:hover, &:focus {
          color: #00221C;
          background-color: #008060;
        }
      }

      span {
        &:hover, &:focus {
          color: #00221C;
          background-color: #008060;
        }
      }
    }
  }
}

/* Light Theme  */

/* AnchorJS Link */

.hs-docs-heading {
  font-size: 1.3125rem;
  margin-top: 3rem;
  margin-bottom: 1rem;

  ~ p {
    margin-top: -.5rem;
  }

  &::before {
    display: block;
    height: 6rem;
    margin-top: -6rem;
    content: "";
  }
}

@media (max-width: 1200px) {
  h2, .h2 {
    font-size: calc(1.25625rem + 0.075vw);
  }
}

[data-anchorjs-icon]::after {
  content: attr(data-anchorjs-icon);
}

.anchorjs-link {
  padding-left: .5rem;
  font-size: 90%;
  color: rgba(55, 125, 255, 0.5) !important;
  transition: color .16s linear;
  opacity: 0;

  &:hover {
    color: rgba(55, 125, 255, 1) !important;
  }
}

.hs-docs-heading:hover .anchorjs-link {
  opacity: 1;
}

/* Copy to clipboard style */

.hs-docs-copy-to-clipboard-text {
  font-size: 80%;
  color: #77838f;
}

.hs-docs-copy-to-clipboard:hover .hs-docs-copy-to-clipboard-text {
  color: #fff;
  background-color: #008060;
}

/* Scrollspy */

.scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: .5rem;
  overflow: auto;
}

/* Card */

.card-header {
  border-width: 1px;
}

.card .card .card-header {
  border-width: 0;
}

/* Page Header */

.docs-page-header {
  border-bottom: 0.0625rem solid #e7eaf3;
  padding-bottom: 1.75rem;
  margin-bottom: 2.25rem;
}

.docs-page-header-title {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
}

@media (max-width: 1200px) {
  h1, .h1 {
    font-size: calc(1.26609rem + 0.19312vw);
  }
}

.docs-page-header-text {
  font-size: 1.125rem;
  margin-bottom: 0.75rem;

  &:last-child {
    margin-bottom: 0;
  }
}

/* Navbar */

.docs-navbar {
  z-index: auto;
}

/* Navbar Sidebar */
@media (min-width: 991px) {
  .docs-navbar-sidebar-container {
    max-width: 17rem;
  }
}

@media (min-width: 991.98px) {
  .docs-navbar-sidebar-aside-body {
    padding-top: 8.5rem !important;
  }
}

/* Snippets */
@media (min-width: 992px) {
  .hs-snippets-main-img-height {
    height: calc(100vh - 8rem);
  }
}

/* Card */

.card-nav-tab-content-height .tab-pane {
  max-height: 40rem;
  overflow-y: auto;
}

/* Text Highlight */

[class*=text-highlight-] {
  background: left 1em/1em 0.2em;
  background-repeat: repeat-x;
}

.text-highlight-warning {
  background-image: linear-gradient(to bottom, rgba(241, 185, 128, 0.5), rgba(241, 185, 128, 0.5));
}