.hs-menu-initialized {
    position: relative;
    z-index: 10;
  
    .animated {
      animation-duration: .3s;
    }
  }
  
  .hs-overflow-x-locked {
    overflow-x: hidden;
  }
  
  .hs-mega-menu {
    display: none;
    background-color: #fff;
  }
  
  .hs-sub-menu {
    display: none;
    background-color: #fff;
    min-width: 180px;
  }
  
  @media (min-width: 576px) {
    .hs-mega-menu-desktop-sm, .hs-sub-menu-desktop-sm {
      position: absolute;
      left: 0;
      top: 100%;
      z-index: 2;
      margin-top: -.002rem;
    }
  }
  
  @media (min-width: 768px) {
    .hs-mega-menu-desktop-md, .hs-sub-menu-desktop-md {
      position: absolute;
      left: 0;
      top: 100%;
      z-index: 2;
      margin-top: -.002rem;
    }
  }
  
  @media (min-width: 992px) {
    .hs-mega-menu-desktop-lg, .hs-sub-menu-desktop-lg {
      position: absolute;
      left: 0;
      top: 100%;
      z-index: 2;
      margin-top: -.002rem;
    }
  }
  
  @media (min-width: 1200px) {
    .hs-mega-menu-desktop-xl, .hs-sub-menu-desktop-xl {
      position: absolute;
      left: 0;
      top: 100%;
      z-index: 2;
      margin-top: -.002rem;
    }
  }
  
  .hs-has-sub-menu {
    position: relative;
  }
  
  .hs-sub-menu-opened {
    overflow: visible;
  }
  
  .hs-mega-menu {
    .hs-mega-menu, .hs-sub-menu {
      top: 0;
      left: 100%;
      margin-top: 0;
    }
  }
  
  .hs-menu-vertical {
    .hs-mega-menu, .hs-sub-menu {
      top: 0;
      left: 100%;
      margin-top: 0;
    }
  }
  
  .hs-sub-menu {
    .hs-mega-menu, .hs-sub-menu {
      top: 0;
      left: 100%;
      margin-top: 0;
    }
  }
  
  .hs-menu-vertical {
    .hs-sub-menu {
      width: auto;
    }
  
    .hs-mega-menu {
      height: 100%;
    }
  }
  
  .hs-mobile-state {
    .hs-mega-menu, .hs-sub-menu {
      position: static;
      visibility: visible;
    }
  
    .hs-has-mega-menu[data-max-width] > .hs-mega-menu, .hs-has-sub-menu[data-max-width] > .hs-sub-menu {
      max-width: none !important;
    }
  }
  
  .hs-menu-initialized {
    &.hs-rtl {
      direction: rtl;
      unicode-bidi: embed;
  
      .hs-mega-menu, .hs-sub-menu {
        left: auto;
        right: 0;
      }
  
      .hs-mega-menu {
        .hs-mega-menu, .hs-sub-menu {
          left: auto;
          right: 100%;
        }
      }
  
      .hs-sub-menu {
        .hs-mega-menu, .hs-sub-menu {
          left: auto;
          right: 100%;
        }
      }
    }
  
    &:not(.hs-mobile-state) {
      .hs-mega-menu.hs-reversed, .hs-sub-menu.hs-reversed {
        left: auto;
        right: 0;
      }
  
      .hs-mega-menu {
        .hs-mega-menu.hs-reversed, .hs-sub-menu.hs-reversed {
          left: auto;
          right: 100%;
        }
      }
  
      .hs-sub-menu {
        .hs-mega-menu.hs-reversed, .hs-sub-menu.hs-reversed {
          left: auto;
          right: 100%;
        }
      }
    }
  
    &.hs-rtl:not(.hs-mobile-state) {
      .hs-mega-menu.hs-reversed, .hs-sub-menu.hs-reversed {
        right: auto;
        left: 0;
      }
  
      .hs-mega-menu {
        .hs-mega-menu.hs-reversed, .hs-sub-menu.hs-reversed {
          right: auto;
          left: 100%;
        }
      }
  
      .hs-sub-menu {
        .hs-mega-menu.hs-reversed, .hs-sub-menu.hs-reversed {
          right: auto;
          left: 100%;
        }
      }
    }
  
    &.hs-menu-horizontal .hs-mega-menu {
      &.hs-position-left {
        left: 0;
        right: auto;
      }
  
      &.hs-position-right {
        left: auto;
        right: 0;
      }
  
      &.hs-position-center {
        right: auto;
        left: 50%;
        transform: translate(-50%);
      }
    }
  }