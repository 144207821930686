// four-dashboards stripe clone
.four-dashboards {
  --stripe-b1: 39%;

  .b1 {
    .b1-1 {
      width: var(--stripe-b1);
    }
    .b1-2 {
      width: calc(100% - var(--stripe-b1));
    }

    @media (min-width: 768px) {
      width: 30%;
    }
  }

  .b2 {
    @media (min-width: 768px) {
      width: 70%;

      .img2 {
        width: 70%;
      }
    }
  }
}
// four-dashboards stripe clone

// square icons
.ic10 {
  height: 10px;
  width: 10px;
}
.ic15 {
  height: 15px;
  width: 15px;
}
.ic30 {
  height: 30px;
  width: 30px;
}
// square icons

// line clamp
.lc1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lc2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
// line clamp

/*------- tab switch -------*/
.tab-content[active="1"] .sl-1,
.tab-content[active="2"] .sl-2 {
  animation: fadeIn 0.3s linear;
}

.tab-content[active="1"] .sl-2,
.tab-content[active="2"] .sl-1 {
  animation: fadeOut 0.3s;
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*------- tab switch -------*/

.transition-duration-3s {
  transition-duration: 3s;
}

.dashboard8 {
  .checkout-card {
    top: 14%;
    max-width: 220px;
    right: 10%;
    &:nth-child(2) {
      transform: translate(24%, 50%);
      z-index: 2;
    }
  }
}

/*-------slider -------*/
.slider {
  .cell-600 {
    width: 100%;
    max-width: 600px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 10;
    left: 0;
  }

  .swiper-button-next {
    right: 0;
    left: unset;
  }
}
/*-------slider -------*/

/*-------aspect ratio -------*/
.aspect-60-25 {
  position: relative;
  width: 100%;
  padding-top: 41.66%;
  > div {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
/*-------aspect ratio -------*/
