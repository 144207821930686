@charset "UTF-8";

/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

:root {
  --bs-blue: #334ac0;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #692340;
  --bs-orange: #fd7e14;
  --bs-yellow: #f1b980;
  --bs-green: #321844;
  --bs-teal: #077c76;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #8c98a4;
  --bs-gray-dark: #51596c;
  --bs-gray-100: #f9fafc;
  --bs-gray-200: #f8fafd;
  --bs-gray-300: #e7eaf3;
  --bs-gray-400: #bdc5d1;
  --bs-gray-500: #97a4af;
  --bs-gray-600: #8c98a4;
  --bs-gray-700: #677788;
  --bs-gray-800: #51596c;
  --bs-gray-900: #223b41;
  --bs-primary: #321844;
  --bs-secondary: #51596c;
  --bs-success: #077c76;
  --bs-info: #334ac0;
  --bs-warning: #f1b980;
  --bs-danger: #692340;
  --bs-light: #f9fafc;
  --bs-dark: #321844;
  --bs-primary-rgb: 50,24,68;
  --bs-secondary-rgb: 81, 89, 108;
  --bs-success-rgb: 7, 124, 118;
  --bs-info-rgb: 51, 74, 192;
  --bs-warning-rgb: 241, 185, 128;
  --bs-danger-rgb: 105, 35, 64;
  --bs-light-rgb: 249, 250, 252;
  --bs-dark-rgb: 0, 34, 28;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 81, 89, 108;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: Roboto, sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #51596c;
  --bs-body-bg: #fff;
}

* {
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body,
html,
#root {
  height: 100%;
  scroll-behavior: smooth;
}
hr {
  margin: 1rem 0;
  color: rgba(0, 34, 28, 0.1);
  background-color: currentColor;
  border: 0;
  opacity: 1;

  &:not([size]) {
    height: 0.0625rem;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #321844;
}

h1,
.h1 {
  font-size: calc(1.475rem + 2.7vw);
}

@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 3.5rem;
  }
}

h2,
.h2 {
  font-size: calc(1.425rem + 2.1vw);
}

@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 3rem;
  }
}

h3,
.h3 {
  font-size: calc(1.3375rem + 1.05vw);
}

@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 2.125rem;
  }
}

h4,
.h4 {
  font-size: calc(1.2875rem + 0.45vw);
}

@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.625rem;
  }
}

h5,
.h5 {
  font-size: calc(1.2625rem + 0.15vw);
}

@media (min-width: 1200px) {
  h5,
  .h5 {
    font-size: 1.375rem;
  }
}

h6,
.h6 {
  font-size: 1.125rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr {
  &[title],
  &[data-bs-original-title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
  }
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #321844;
  text-decoration: none;

  &:hover {
    color: #321844;
  }

  &:not([href]):not([class]) {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 1em;

  code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }
}

code {
  font-size: 1em;
  color: #d63384;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 1em;
  color: #321844;
  background-color: #e7eaf3;
  border-radius: 0.125rem;

  kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700;
  }
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #8c98a4;
  text-align: left;
}

th {
  font-weight: 400;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;

  &:focus:not(:focus-visible) {
    outline: 0;
  }
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;

  &:disabled {
    opacity: 1;
  }
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;

  + * {
    clear: left;
  }
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 400;
}

@media (min-width: 1200px) {
  .lead {
    font-size: 1.5rem;
  }
}

.display-1 {
  font-size: calc(1.75rem + 6vw);
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 6.25rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.5rem + 3vw);
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.75rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled,
.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 0;
  font-size: calc(1.3rem + 0.6vw);

  > :last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .blockquote {
    font-size: 1.75rem;
  }
}

.blockquote-footer {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.25rem;
  color: #321844;

  &::before {
    content: "\2014\00A0";
  }
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 0.0625rem solid #e7eaf3;
  border-radius: 0.3125rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #8c98a4;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1340px;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));

  > * {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }
}

.col {
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
}

.row-cols-auto > * {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4 > * {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 2rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 2rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 2.5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 2.5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 3rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 3rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 3.5rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 3.5rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 4rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 4rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 4.5rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 4.5rem;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-sm-4 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-sm-2 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-sm-3 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-sm-5 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-sm-6 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-sm-8 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-sm-9 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-sm-11 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-sm-12 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }

  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 2rem;
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 2rem;
  }

  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 3rem;
  }

  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 3.5rem;
  }

  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 3.5rem;
  }

  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 4rem;
  }

  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 4rem;
  }

  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 4.5rem;
  }

  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 4.5rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-md-4 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-md-2 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-md-3 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-md-5 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-md-6 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-md-8 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-md-9 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-md-11 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-md-12 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }

  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 2rem;
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 2rem;
  }

  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 3rem;
  }

  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 3rem;
  }

  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 3.5rem;
  }

  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 3.5rem;
  }

  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 4rem;
  }

  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 4rem;
  }

  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 4.5rem;
  }

  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 4.5rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-lg-4 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-lg-2 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-lg-3 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-lg-5 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-lg-6 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-lg-8 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-lg-9 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-lg-11 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-lg-12 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 2rem;
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 2rem;
  }

  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 3rem;
  }

  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 3.5rem;
  }

  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 3.5rem;
  }

  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 4rem;
  }

  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 4rem;
  }

  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 4.5rem;
  }

  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 4.5rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-xl-4 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-xl-2 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xl-3 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-xl-5 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-xl-6 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-xl-8 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-xl-9 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-xl-11 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-xl-12 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }

  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 2rem;
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 2rem;
  }

  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 3rem;
  }

  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 3.5rem;
  }

  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 3.5rem;
  }

  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 4rem;
  }

  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 4rem;
  }

  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 4.5rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-xxl-4 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xxl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-xxl-2 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xxl-3 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-xxl-5 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-xxl-6 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-xxl-8 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-xxl-9 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-xxl-11 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-xxl-12 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66667%;
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 2rem;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 2rem;
  }

  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 3rem;
  }

  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 3.5rem;
  }

  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 3.5rem;
  }

  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 4rem;
  }

  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 4rem;
  }

  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 4.5rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #51596c;
  --bs-table-striped-bg: #f9fafc;
  --bs-table-active-color: #51596c;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #51596c;
  --bs-table-hover-bg: rgba(231, 234, 243, 0.4);
  width: 100%;
  margin-bottom: 1rem;
  color: #51596c;
  vertical-align: top;
  border-color: rgba(0, 34, 28, 0.1);

  > {
    :not(caption) > * > * {
      padding: 0.75rem 0.75rem;
      background-color: var(--bs-table-bg);
      border-bottom-width: 0.0625rem;
      box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    }

    tbody {
      vertical-align: inherit;
    }

    thead {
      vertical-align: bottom;
    }

    :not(:first-child) {
      border-top: 0.125rem solid currentColor;
    }
  }
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
}

.table-bordered > :not(caption) > * {
  border-width: 0.0625rem 0;

  > * {
    border-width: 0 0.0625rem;
  }
}

.table-borderless > {
  :not(caption) > * > * {
    border-bottom-width: 0;
  }

  :not(:first-child) {
    border-top-width: 0;
  }
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #cce6df;
  --bs-table-striped-bg: #c2dbd4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8cfc9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdd5ce;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b8cfc9;
}

.table-secondary {
  --bs-table-bg: #dcdee2;
  --bs-table-striped-bg: #d1d3d7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c6c8cb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cccdd1;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c6c8cb;
}

.table-success {
  --bs-table-bg: #cde5e4;
  --bs-table-striped-bg: #c3dad9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b9cecd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bed4d3;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b9cecd;
}

.table-info {
  --bs-table-bg: #d6dbf2;
  --bs-table-striped-bg: #cbd0e6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c1c5da;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c6cbe0;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c1c5da;
}

.table-warning {
  --bs-table-bg: #fcf1e6;
  --bs-table-striped-bg: #efe5db;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e3d9cf;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e9dfd5;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e3d9cf;
}

.table-danger {
  --bs-table-bg: #e1d3d9;
  --bs-table-striped-bg: #d6c8ce;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbbec3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d0c3c9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbbec3;
}

.table-light {
  --bs-table-bg: #f9fafc;
  --bs-table-striped-bg: #edeeef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e0e1e3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e6e7e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e0e1e3;
}

.table-dark {
  --bs-table-bg: #321844;
  --bs-table-striped-bg: #0d2d27;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #1a3833;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #13332d;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #1a3833;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.col-form-label {
  padding-top: 0.675rem;
  padding-bottom: 0.675rem;
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 500;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem;
  font-size: 1.125rem;
}

.col-form-label-sm {
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
  font-size: 0.8125rem;
}

.form-text {
  margin-top: 1rem;
  font-size: 0.875em;
  color: #8c98a4;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.6125rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #51596c;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid rgba(0, 34, 28, 0.1);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &[type="file"] {
    overflow: hidden;

    &:not(:disabled):not([readonly]) {
      cursor: pointer;
    }
  }

  &:focus {
    color: #51596c;
    background-color: #fff;
    border-color: rgba(140, 152, 164, 0.25);
    outline: 0;
    box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
  }

  &::-webkit-date-and-time-value {
    height: 1.5em;
  }

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder,
  &::placeholder {
    color: #8c98a4;
    opacity: 1;
  }

  &:disabled,
  &[readonly] {
    background-color: #f8fafd;
    opacity: 1;
  }

  &::file-selector-button {
    padding: 0.6125rem 1rem;
    margin: -0.6125rem -1rem;
    -webkit-margin-end: 1rem;
    -moz-margin-end: 1rem;
    margin-inline-end: 1rem;
    color: #51596c;
    background-color: #fff;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 0.0625rem;
    border-radius: 0;
    transition: all 0.2s ease-in-out;
  }

  &:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #f2f2f2;
  }

  &::-webkit-file-upload-button {
    padding: 0.6125rem 1rem;
    margin: -0.6125rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem;
    color: #51596c;
    background-color: #fff;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 0.0625rem;
    border-radius: 0;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #f2f2f2;
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.6125rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #51596c;
  background-color: transparent;
  border: solid transparent;
  border-width: 0.0625rem 0;

  &.form-control-sm,
  &.form-control-lg {
    padding-right: 0;
    padding-left: 0;
  }
}

.form-control-sm {
  min-height: calc(1.5em + 1.125rem);
  padding: 0.5rem 0.875rem;
  font-size: 0.8125rem;
  border-radius: 0.3125rem;

  &::file-selector-button {
    padding: 0.5rem 0.875rem;
    margin: -0.5rem -0.875rem;
    -webkit-margin-end: 0.875rem;
    -moz-margin-end: 0.875rem;
    margin-inline-end: 0.875rem;
  }

  &::-webkit-file-upload-button {
    padding: 0.5rem 0.875rem;
    margin: -0.5rem -0.875rem;
    -webkit-margin-end: 0.875rem;
    margin-inline-end: 0.875rem;
  }
}

.form-control-lg {
  min-height: calc(1.5em + 1.625rem);
  padding: 0.75rem 1rem;
  font-size: 1.125rem;
  border-radius: 0.3125rem;

  &::file-selector-button {
    padding: 0.75rem 1rem;
    margin: -0.75rem -1rem;
    -webkit-margin-end: 1rem;
    -moz-margin-end: 1rem;
    margin-inline-end: 1rem;
  }

  &::-webkit-file-upload-button {
    padding: 0.75rem 1rem;
    margin: -0.75rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem;
  }
}

textarea {
  &.form-control {
    min-height: calc(1.5em + 1.35rem);
  }

  &.form-control-sm {
    min-height: calc(1.5em + 1.125rem);
  }

  &.form-control-lg {
    min-height: calc(1.5em + 1.625rem);
  }
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.6125rem;

  &:not(:disabled):not([readonly]) {
    cursor: pointer;
  }

  &::-moz-color-swatch,
  &::-webkit-color-swatch {
    height: 1.5em;
    border-radius: 0.3125rem;
  }
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.6125rem 3rem 0.6125rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #51596c;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%2351596C' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 0.0625rem solid rgba(0, 34, 28, 0.1);
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    border-color: rgba(140, 152, 164, 0.25);
    outline: 0;
    box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
  }

  &[multiple],
  &[size]:not([size="1"]) {
    padding-right: 1rem;
    background-image: none;
  }

  &:disabled {
    background-color: #f8fafd;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #51596c;
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select-sm {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.875rem;
  font-size: 0.8125rem;
  border-radius: 0.3125rem;
}

.form-select-lg {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  font-size: 1.125rem;
  border-radius: 0.3125rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;

  .form-check-input {
    float: left;
    margin-left: -1.5em;
  }
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 34, 28, 0.1);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;

  &[type="checkbox"] {
    border-radius: 0.25em;
  }

  &[type="radio"] {
    border-radius: 50%;
  }

  &:active {
    -webkit-filter: 100%;
    filter: 100%;
  }

  &:focus {
    border-color: rgba(140, 152, 164, 0.25);
    outline: 0;
    box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
  }

  &:checked {
    background-color: #321844;
    border-color: #321844;

    &[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }

    &[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
    }
  }

  &[type="checkbox"]:indeterminate {
    background-color: #321844;
    border-color: #321844;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  }

  &:disabled {
    pointer-events: none;
    -webkit-filter: none;
    filter: none;
    opacity: 0.5;
  }

  &[disabled] ~ .form-check-label,
  &:disabled ~ .form-check-label {
    opacity: 0.5;
  }
}

.form-check-label {
  color: #51596c;
}

.form-switch {
  padding-left: 2.5em;

  .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out;

    &:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28140, 152, 164, 0.25%29'/%3e%3c/svg%3e");
    }

    &:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;

  &[disabled] + .btn,
  &:disabled + .btn {
    pointer-events: none;
    -webkit-filter: none;
    filter: none;
    opacity: 0.65;
  }
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    outline: 0;

    &::-webkit-slider-thumb,
    &::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 1rem 0 rgba(140, 152, 164, 0.25);
    }
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #321844;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;

    &:active {
      background-color: #b3d9cf;
    }
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e7eaf3;
    border-color: transparent;
    border-radius: 1rem;
  }

  &::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #321844;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none;

    &:active {
      background-color: #b3d9cf;
    }
  }

  &::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e7eaf3;
    border-color: transparent;
    border-radius: 1rem;
  }

  &:disabled {
    pointer-events: none;

    &::-webkit-slider-thumb,
    &::-moz-range-thumb {
      background-color: #97a4af;
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.form-floating {
  position: relative;

  > {
    .form-control,
    .form-select {
      height: 3.625rem;
      line-height: 1.25;
    }

    label {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      padding: 1rem 1rem;
      pointer-events: none;
      border: 0.0625rem solid transparent;
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      transition: opacity 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
      transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
      transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out,
        -webkit-transform 0.1s ease-in-out;
    }

    .form-control {
      padding: 1rem 1rem;

      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder,
      &::-ms-input-placeholder,
      &::placeholder {
        color: transparent;
      }

      &:not(:-moz-placeholder-shown),
      &:not(:-ms-input-placeholder),
      &:focus,
      &:not(:placeholder-shown),
      &:-webkit-autofill {
        padding-top: 1.625rem;
        padding-bottom: 0.625rem;
      }
    }

    .form-select {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem;
    }

    .form-control {
      &:not(:-moz-placeholder-shown) ~ label,
      &:not(:-ms-input-placeholder) ~ label {
        opacity: 0.65;
        transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
      }

      &:focus ~ label,
      &:not(:placeholder-shown) ~ label {
        opacity: 0.65;
        -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
        transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
      }
    }

    .form-select ~ label,
    .form-control:-webkit-autofill ~ label {
      opacity: 0.65;
      -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
      transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;

  > {
    .form-control,
    .form-select {
      position: relative;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      width: 1%;
      min-width: 0;
    }

    .form-control:focus,
    .form-select:focus {
      z-index: 3;
    }
  }

  .btn {
    position: relative;
    z-index: 2;

    &:focus {
      z-index: 3;
    }
  }
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.6125rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8c98a4;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 0.0625rem solid rgba(0, 34, 28, 0.1);
  border-radius: 0.3125rem;
}

.input-group-lg > {
  .form-control,
  .form-select,
  .input-group-text,
  .btn {
    padding: 0.75rem 1rem;
    font-size: 1.125rem;
    border-radius: 0.3125rem;
  }
}

.input-group-sm > {
  .form-control,
  .form-select,
  .input-group-text,
  .btn {
    padding: 0.5rem 0.875rem;
    font-size: 0.8125rem;
    border-radius: 0.3125rem;
  }
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4rem;
}

.input-group {
  &:not(.has-validation) > {
    :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
    .dropdown-toggle:nth-last-child(n + 3) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &.has-validation > {
    :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
    .dropdown-toggle:nth-last-child(n + 4) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -0.0625rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #077c76;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.8125rem;
  color: #fff;
  background-color: #077c76;
  border-radius: 0.3125rem;
}

.was-validated :valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.is-valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #077c76;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #077c76;
  box-shadow: 0 0 1rem 0 rgba(7, 124, 118, 0.25);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: #077c76;
}

.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: #077c76;
  box-shadow: 0 0 1rem 0 rgba(7, 124, 118, 0.25);
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: #077c76;
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #077c76;
}

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 1rem 0 rgba(7, 124, 118, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #077c76;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid,
.input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}

.was-validated .input-group .form-control:valid:focus,
.input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #692340;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.8125rem;
  color: #fff;
  background-color: #692340;
  border-radius: 0.3125rem;
}

.was-validated :invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.is-invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #692340;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #692340;
  box-shadow: 0 0 1rem 0 rgba(105, 35, 64, 0.25);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: #692340;
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: #692340;
  box-shadow: 0 0 1rem 0 rgba(105, 35, 64, 0.25);
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: #692340;
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: #692340;
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 1rem 0 rgba(105, 35, 64, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #692340;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid,
.input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}

.was-validated .input-group .form-control:invalid:focus,
.input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #51596c;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 0.0625rem solid transparent;
  padding: 0.6125rem 1rem;
  font-size: 1rem;
  border-radius: 0.3125rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: #51596c;
  }
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn-check:focus + .btn {
  outline: 0;
  box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
}

.btn {
  &:focus {
    outline: 0;
    box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    opacity: 0.65;
  }
}

fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #321844;
  border-color: #321844;

  &:hover {
    color: #fff;
    background-color: #321844;
    border-color: #321844;
  }
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #321844;
  border-color: #321844;
  box-shadow: #3218444a;
}

.btn-check {
  &:checked + .btn-primary,
  &:active + .btn-primary {
    color: #fff;
    background-color: #321844;
    border-color: #321844;
  }
}

.btn-primary {
  &:active,
  &.active {
    color: #fff;
    background-color: #321844;
    border-color: #321844;
  }
}

.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #321844;
  border-color: #321844;
}

.btn-check {
  &:checked + .btn-primary:focus,
  &:active + .btn-primary:focus {
    box-shadow: 0 0 0 0 rgba(38, 147, 120, 0.5);
  }
}

.btn-primary {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 0 rgba(38, 147, 120, 0.5);
  }
}

.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(38, 147, 120, 0.5);
}

.btn-primary {
  &:disabled,
  &.disabled {
    color: #fff;
    background-color: #321844;
    border-color: #321844;
  }
}

.btn-secondary {
  color: #fff;
  background-color: #51596c;
  border-color: #51596c;

  &:hover {
    color: #fff;
    background-color: #454c5c;
    border-color: #414756;
  }
}

.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #fff;
  background-color: #454c5c;
  border-color: #414756;
  box-shadow: 0 0 0 0 rgba(107, 114, 130, 0.5);
}

.btn-check {
  &:checked + .btn-secondary,
  &:active + .btn-secondary {
    color: #fff;
    background-color: #414756;
    border-color: #3d4351;
  }
}

.btn-secondary {
  &:active,
  &.active {
    color: #fff;
    background-color: #414756;
    border-color: #3d4351;
  }
}

.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #414756;
  border-color: #3d4351;
}

.btn-check {
  &:checked + .btn-secondary:focus,
  &:active + .btn-secondary:focus {
    box-shadow: 0 0 0 0 rgba(107, 114, 130, 0.5);
  }
}

.btn-secondary {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 0 rgba(107, 114, 130, 0.5);
  }
}

.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(107, 114, 130, 0.5);
}

.btn-secondary {
  &:disabled,
  &.disabled {
    color: #fff;
    background-color: #51596c;
    border-color: #51596c;
  }
}

.btn-success {
  color: #fff;
  background-color: #077c76;
  border-color: #077c76;

  &:hover {
    color: #fff;
    background-color: #066964;
    border-color: #06635e;
  }
}

.btn-check:focus + .btn-success,
.btn-success:focus {
  color: #fff;
  background-color: #066964;
  border-color: #06635e;
  box-shadow: 0 0 0 0 rgba(44, 144, 139, 0.5);
}

.btn-check {
  &:checked + .btn-success,
  &:active + .btn-success {
    color: #fff;
    background-color: #06635e;
    border-color: #055d59;
  }
}

.btn-success {
  &:active,
  &.active {
    color: #fff;
    background-color: #06635e;
    border-color: #055d59;
  }
}

.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #06635e;
  border-color: #055d59;
}

.btn-check {
  &:checked + .btn-success:focus,
  &:active + .btn-success:focus {
    box-shadow: 0 0 0 0 rgba(44, 144, 139, 0.5);
  }
}

.btn-success {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 0 rgba(44, 144, 139, 0.5);
  }
}

.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(44, 144, 139, 0.5);
}

.btn-success {
  &:disabled,
  &.disabled {
    color: #fff;
    background-color: #077c76;
    border-color: #077c76;
  }
}

.btn-info {
  color: #fff;
  background-color: #334ac0;
  border-color: #334ac0;

  &:hover {
    color: #fff;
    background-color: #2b3fa3;
    border-color: #293b9a;
  }
}

.btn-check:focus + .btn-info,
.btn-info:focus {
  color: #fff;
  background-color: #2b3fa3;
  border-color: #293b9a;
  box-shadow: 0 0 0 0 rgba(82, 101, 201, 0.5);
}

.btn-check {
  &:checked + .btn-info,
  &:active + .btn-info {
    color: #fff;
    background-color: #293b9a;
    border-color: #263890;
  }
}

.btn-info {
  &:active,
  &.active {
    color: #fff;
    background-color: #293b9a;
    border-color: #263890;
  }
}

.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #293b9a;
  border-color: #263890;
}

.btn-check {
  &:checked + .btn-info:focus,
  &:active + .btn-info:focus {
    box-shadow: 0 0 0 0 rgba(82, 101, 201, 0.5);
  }
}

.btn-info {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 0 rgba(82, 101, 201, 0.5);
  }
}

.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(82, 101, 201, 0.5);
}

.btn-info {
  &:disabled,
  &.disabled {
    color: #fff;
    background-color: #334ac0;
    border-color: #334ac0;
  }
}

.btn-warning {
  color: #000;
  background-color: #f1b980;
  border-color: #f1b980;

  &:hover {
    color: #000;
    background-color: #f3c493;
    border-color: #f2c08d;
  }
}

.btn-check:focus + .btn-warning,
.btn-warning:focus {
  color: #000;
  background-color: #f3c493;
  border-color: #f2c08d;
  box-shadow: 0 0 0 0 rgba(205, 157, 109, 0.5);
}

.btn-check {
  &:checked + .btn-warning,
  &:active + .btn-warning {
    color: #000;
    background-color: #f4c799;
    border-color: #f2c08d;
  }
}

.btn-warning {
  &:active,
  &.active {
    color: #000;
    background-color: #f4c799;
    border-color: #f2c08d;
  }
}

.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #f4c799;
  border-color: #f2c08d;
}

.btn-check {
  &:checked + .btn-warning:focus,
  &:active + .btn-warning:focus {
    box-shadow: 0 0 0 0 rgba(205, 157, 109, 0.5);
  }
}

.btn-warning {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 0 rgba(205, 157, 109, 0.5);
  }
}

.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(205, 157, 109, 0.5);
}

.btn-warning {
  &:disabled,
  &.disabled {
    color: #000;
    background-color: #f1b980;
    border-color: #f1b980;
  }
}

.btn-danger {
  color: #fff;
  background-color: #692340;
  border-color: #692340;

  &:hover {
    color: #fff;
    background-color: #591e36;
    border-color: #541c33;
  }
}

.btn-check:focus + .btn-danger,
.btn-danger:focus {
  color: #fff;
  background-color: #591e36;
  border-color: #541c33;
  box-shadow: 0 0 0 0 rgba(128, 68, 93, 0.5);
}

.btn-check {
  &:checked + .btn-danger,
  &:active + .btn-danger {
    color: #fff;
    background-color: #541c33;
    border-color: #4f1a30;
  }
}

.btn-danger {
  &:active,
  &.active {
    color: #fff;
    background-color: #541c33;
    border-color: #4f1a30;
  }
}

.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #541c33;
  border-color: #4f1a30;
}

.btn-check {
  &:checked + .btn-danger:focus,
  &:active + .btn-danger:focus {
    box-shadow: 0 0 0 0 rgba(128, 68, 93, 0.5);
  }
}

.btn-danger {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 0 rgba(128, 68, 93, 0.5);
  }
}

.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(128, 68, 93, 0.5);
}

.btn-danger {
  &:disabled,
  &.disabled {
    color: #fff;
    background-color: #692340;
    border-color: #692340;
  }
}

.btn-light {
  color: #000;
  background-color: #f9fafc;
  border-color: #f9fafc;

  &:hover {
    color: #000;
    background-color: #fafbfc;
    border-color: #fafbfc;
  }
}

.btn-check:focus + .btn-light,
.btn-light:focus {
  color: #000;
  background-color: #fafbfc;
  border-color: #fafbfc;
  box-shadow: 0 0 0 0 rgba(212, 213, 214, 0.5);
}

.btn-check {
  &:checked + .btn-light,
  &:active + .btn-light {
    color: #000;
    background-color: #fafbfd;
    border-color: #fafbfc;
  }
}

.btn-light {
  &:active,
  &.active {
    color: #000;
    background-color: #fafbfd;
    border-color: #fafbfc;
  }
}

.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #fafbfd;
  border-color: #fafbfc;
}

.btn-check {
  &:checked + .btn-light:focus,
  &:active + .btn-light:focus {
    box-shadow: 0 0 0 0 rgba(212, 213, 214, 0.5);
  }
}

.btn-light {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 0 rgba(212, 213, 214, 0.5);
  }
}

.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(212, 213, 214, 0.5);
}

.btn-light {
  &:disabled,
  &.disabled {
    color: #000;
    background-color: #f9fafc;
    border-color: #f9fafc;
  }
}

.btn-dark {
  color: #fff;
  background-color: #321844;
  border-color: #321844;

  &:hover {
    color: #fff;
    background-color: #001d18;
    border-color: #001b16;
  }
}

.btn-check:focus + .btn-dark,
.btn-dark:focus {
  color: #fff;
  background-color: #001d18;
  border-color: #001b16;
  box-shadow: 0 0 0 0 rgba(38, 67, 62, 0.5);
}

.btn-check {
  &:checked + .btn-dark,
  &:active + .btn-dark {
    color: #fff;
    background-color: #001b16;
    border-color: #001a15;
  }
}

.btn-dark {
  &:active,
  &.active {
    color: #fff;
    background-color: #001b16;
    border-color: #001a15;
  }
}

.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #001b16;
  border-color: #001a15;
}

.btn-check {
  &:checked + .btn-dark:focus,
  &:active + .btn-dark:focus {
    box-shadow: 0 0 0 0 rgba(38, 67, 62, 0.5);
  }
}

.btn-dark {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 0 rgba(38, 67, 62, 0.5);
  }
}

.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(38, 67, 62, 0.5);
}

.btn-dark {
  &:disabled,
  &.disabled {
    color: #fff;
    background-color: #321844;
    border-color: #321844;
  }
}

.btn-outline-primary {
  color: #321844;
  border-color: #321844;

  &:hover {
    color: #fff;
    background-color: #321844;
    border-color: #321844;
  }
}

.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0 rgba(0, 128, 96, 0.5);
}

.btn-check {
  &:checked + .btn-outline-primary,
  &:active + .btn-outline-primary {
    color: #fff;
    background-color: #321844;
    border-color: #321844;
  }
}

.btn-outline-primary {
  &:active,
  &.active,
  &.dropdown-toggle.show {
    color: #fff;
    background-color: #321844;
    border-color: #321844;
  }
}

.btn-check {
  &:checked + .btn-outline-primary:focus,
  &:active + .btn-outline-primary:focus {
    box-shadow: 0 0 0 0 rgba(0, 128, 96, 0.5);
  }
}

.btn-outline-primary {
  &:active:focus,
  &.active:focus,
  &.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0 rgba(0, 128, 96, 0.5);
  }

  &:disabled,
  &.disabled {
    color: #321844;
    background-color: transparent;
  }
}

.btn-outline-secondary {
  color: #51596c;
  border-color: #51596c;

  &:hover {
    color: #fff;
    background-color: #51596c;
    border-color: #51596c;
  }
}

.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0 rgba(81, 89, 108, 0.5);
}

.btn-check {
  &:checked + .btn-outline-secondary,
  &:active + .btn-outline-secondary {
    color: #fff;
    background-color: #51596c;
    border-color: #51596c;
  }
}

.btn-outline-secondary {
  &:active,
  &.active,
  &.dropdown-toggle.show {
    color: #fff;
    background-color: #51596c;
    border-color: #51596c;
  }
}

.btn-check {
  &:checked + .btn-outline-secondary:focus,
  &:active + .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0 rgba(81, 89, 108, 0.5);
  }
}

.btn-outline-secondary {
  &:active:focus,
  &.active:focus,
  &.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0 rgba(81, 89, 108, 0.5);
  }

  &:disabled,
  &.disabled {
    color: #51596c;
    background-color: transparent;
  }
}

.btn-outline-success {
  color: #077c76;
  border-color: #077c76;

  &:hover {
    color: #fff;
    background-color: #077c76;
    border-color: #077c76;
  }
}

.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0 rgba(7, 124, 118, 0.5);
}

.btn-check {
  &:checked + .btn-outline-success,
  &:active + .btn-outline-success {
    color: #fff;
    background-color: #077c76;
    border-color: #077c76;
  }
}

.btn-outline-success {
  &:active,
  &.active,
  &.dropdown-toggle.show {
    color: #fff;
    background-color: #077c76;
    border-color: #077c76;
  }
}

.btn-check {
  &:checked + .btn-outline-success:focus,
  &:active + .btn-outline-success:focus {
    box-shadow: 0 0 0 0 rgba(7, 124, 118, 0.5);
  }
}

.btn-outline-success {
  &:active:focus,
  &.active:focus,
  &.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0 rgba(7, 124, 118, 0.5);
  }

  &:disabled,
  &.disabled {
    color: #077c76;
    background-color: transparent;
  }
}

.btn-outline-info {
  color: #334ac0;
  border-color: #334ac0;

  &:hover {
    color: #fff;
    background-color: #334ac0;
    border-color: #334ac0;
  }
}

.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0 rgba(51, 74, 192, 0.5);
}

.btn-check {
  &:checked + .btn-outline-info,
  &:active + .btn-outline-info {
    color: #fff;
    background-color: #334ac0;
    border-color: #334ac0;
  }
}

.btn-outline-info {
  &:active,
  &.active,
  &.dropdown-toggle.show {
    color: #fff;
    background-color: #334ac0;
    border-color: #334ac0;
  }
}

.btn-check {
  &:checked + .btn-outline-info:focus,
  &:active + .btn-outline-info:focus {
    box-shadow: 0 0 0 0 rgba(51, 74, 192, 0.5);
  }
}

.btn-outline-info {
  &:active:focus,
  &.active:focus,
  &.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0 rgba(51, 74, 192, 0.5);
  }

  &:disabled,
  &.disabled {
    color: #334ac0;
    background-color: transparent;
  }
}

.btn-outline-warning {
  color: #f1b980;
  border-color: #f1b980;

  &:hover {
    color: #000;
    background-color: #f1b980;
    border-color: #f1b980;
  }
}

.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0 rgba(241, 185, 128, 0.5);
}

.btn-check {
  &:checked + .btn-outline-warning,
  &:active + .btn-outline-warning {
    color: #000;
    background-color: #f1b980;
    border-color: #f1b980;
  }
}

.btn-outline-warning {
  &:active,
  &.active,
  &.dropdown-toggle.show {
    color: #000;
    background-color: #f1b980;
    border-color: #f1b980;
  }
}

.btn-check {
  &:checked + .btn-outline-warning:focus,
  &:active + .btn-outline-warning:focus {
    box-shadow: 0 0 0 0 rgba(241, 185, 128, 0.5);
  }
}

.btn-outline-warning {
  &:active:focus,
  &.active:focus,
  &.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0 rgba(241, 185, 128, 0.5);
  }

  &:disabled,
  &.disabled {
    color: #f1b980;
    background-color: transparent;
  }
}

.btn-outline-danger {
  color: #692340;
  border-color: #692340;

  &:hover {
    color: #fff;
    background-color: #692340;
    border-color: #692340;
  }
}

.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0 rgba(105, 35, 64, 0.5);
}

.btn-check {
  &:checked + .btn-outline-danger,
  &:active + .btn-outline-danger {
    color: #fff;
    background-color: #692340;
    border-color: #692340;
  }
}

.btn-outline-danger {
  &:active,
  &.active,
  &.dropdown-toggle.show {
    color: #fff;
    background-color: #692340;
    border-color: #692340;
  }
}

.btn-check {
  &:checked + .btn-outline-danger:focus,
  &:active + .btn-outline-danger:focus {
    box-shadow: 0 0 0 0 rgba(105, 35, 64, 0.5);
  }
}

.btn-outline-danger {
  &:active:focus,
  &.active:focus,
  &.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0 rgba(105, 35, 64, 0.5);
  }

  &:disabled,
  &.disabled {
    color: #692340;
    background-color: transparent;
  }
}

.btn-outline-light {
  color: #f9fafc;
  border-color: #f9fafc;

  &:hover {
    color: #000;
    background-color: #f9fafc;
    border-color: #f9fafc;
  }
}

.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0 rgba(249, 250, 252, 0.5);
}

.btn-check {
  &:checked + .btn-outline-light,
  &:active + .btn-outline-light {
    color: #000;
    background-color: #f9fafc;
    border-color: #f9fafc;
  }
}

.btn-outline-light {
  &:active,
  &.active,
  &.dropdown-toggle.show {
    color: #000;
    background-color: #f9fafc;
    border-color: #f9fafc;
  }
}

.btn-check {
  &:checked + .btn-outline-light:focus,
  &:active + .btn-outline-light:focus {
    box-shadow: 0 0 0 0 rgba(249, 250, 252, 0.5);
  }
}

.btn-outline-light {
  &:active:focus,
  &.active:focus,
  &.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0 rgba(249, 250, 252, 0.5);
  }

  &:disabled,
  &.disabled {
    color: #f9fafc;
    background-color: transparent;
  }
}

.btn-outline-dark {
  color: #321844;
  border-color: #321844;

  &:hover {
    color: #fff;
    background-color: #321844;
    border-color: #321844;
  }
}

.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0 rgba(0, 34, 28, 0.5);
}

.btn-check {
  &:checked + .btn-outline-dark,
  &:active + .btn-outline-dark {
    color: #fff;
    background-color: #321844;
    border-color: #321844;
  }
}

.btn-outline-dark {
  &:active,
  &.active,
  &.dropdown-toggle.show {
    color: #fff;
    background-color: #321844;
    border-color: #321844;
  }
}

.btn-check {
  &:checked + .btn-outline-dark:focus,
  &:active + .btn-outline-dark:focus {
    box-shadow: 0 0 0 0 rgba(0, 34, 28, 0.5);
  }
}

.btn-outline-dark {
  &:active:focus,
  &.active:focus,
  &.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0 rgba(0, 34, 28, 0.5);
  }

  &:disabled,
  &.disabled {
    color: #321844;
    background-color: transparent;
  }
}

.btn-link {
  font-weight: 400;
  color: #321844;
  text-decoration: none;

  &:hover {
    color: #321844;
  }

  &:disabled,
  &.disabled {
    color: #8c98a4;
  }
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.75rem 1rem;
  font-size: 1.125rem;
  border-radius: 0.3125rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.5rem 0.875rem;
  font-size: 0.8125rem;
  border-radius: 0.3125rem;
}

.fade {
  transition: opacity 0.15s linear;

  &:not(.show) {
    opacity: 0;
  }
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;

  &.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease;
  }
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0.5rem;
  margin: 0;
  font-size: 1rem;
  color: #51596c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;

  &[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: 0.625rem;
  }
}

.dropdown-menu-start {
  --bs-position: start;

  &[data-bs-popper] {
    right: auto;
    left: 0;
  }
}

.dropdown-menu-end {
  --bs-position: end;

  &[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;

    &[data-bs-popper] {
      right: auto;
      left: 0;
    }
  }

  .dropdown-menu-sm-end {
    --bs-position: end;

    &[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;

    &[data-bs-popper] {
      right: auto;
      left: 0;
    }
  }

  .dropdown-menu-md-end {
    --bs-position: end;

    &[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;

    &[data-bs-popper] {
      right: auto;
      left: 0;
    }
  }

  .dropdown-menu-lg-end {
    --bs-position: end;

    &[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;

    &[data-bs-popper] {
      right: auto;
      left: 0;
    }
  }

  .dropdown-menu-xl-end {
    --bs-position: end;

    &[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;

    &[data-bs-popper] {
      right: auto;
      left: 0;
    }
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;

    &[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.625rem;
}

.dropend {
  .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.625rem;
  }

  .dropdown-toggle::after {
    vertical-align: 0;
  }
}

.dropstart {
  .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.625rem;
  }

  .dropdown-toggle::before {
    vertical-align: 0;
  }
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e7eaf3;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  clear: both;
  font-weight: 400;
  color: #223b41;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;

  &:hover,
  &:focus {
    color: #1f353b;
    background-color: rgba(189, 197, 209, 0.3);
  }

  &.active,
  &:active {
    color: #321844;
    text-decoration: none;
    background-color: rgba(189, 197, 209, 0.3);
  }

  &.disabled,
  &:disabled {
    color: #97a4af;
    pointer-events: none;
    background-color: transparent;
  }
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.8125rem;
  color: #8c98a4;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.5rem 1rem;
  color: #223b41;
}

.dropdown-menu-dark {
  color: #e7eaf3;
  background-color: #51596c;
  border-color: rgba(0, 0, 0, 0.15);

  .dropdown-item {
    color: #e7eaf3;

    &:hover,
    &:focus {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.15);
    }

    &.active,
    &:active {
      color: #321844;
      background-color: rgba(189, 197, 209, 0.3);
    }

    &.disabled,
    &:disabled {
      color: #97a4af;
    }
  }

  .dropdown-divider {
    border-color: #e7eaf3;
  }

  .dropdown-item-text {
    color: #e7eaf3;
  }

  .dropdown-header {
    color: #97a4af;
  }
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.btn-group > {
  .btn-check {
    &:checked + .btn,
    &:focus + .btn {
      z-index: 1;
    }
  }

  .btn {
    &:hover,
    &:focus,
    &:active,
    &.active {
      z-index: 1;
    }
  }
}

.btn-group-vertical > {
  .btn-check {
    &:checked + .btn,
    &:focus + .btn {
      z-index: 1;
    }
  }

  .btn {
    &:hover,
    &:focus,
    &:active,
    &.active {
      z-index: 1;
    }
  }
}

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;

  .input-group {
    width: auto;
  }
}

.btn-group > {
  .btn:not(:first-child),
  .btn-group:not(:first-child) {
    margin-left: -0.0625rem;
  }

  .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .btn:nth-child(n + 3),
  :not(.btn-check) + .btn,
  .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;

  &::after {
    margin-left: 0;
  }
}

.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.65625rem;
  padding-left: 0.65625rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;

  > {
    .btn,
    .btn-group {
      width: 100%;
    }

    .btn:not(:first-child),
    .btn-group:not(:first-child) {
      margin-top: -0.0625rem;
    }

    .btn:not(:last-child):not(.dropdown-toggle),
    .btn-group:not(:last-child) > .btn {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .btn ~ .btn,
    .btn-group:not(:first-child) > .btn {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #321844;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;

  &:hover,
  &:focus {
    color: #321844;
  }

  &.disabled {
    color: #8c98a4;
    pointer-events: none;
    cursor: default;
  }
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-tabs {
  border-bottom: 0.1875rem solid rgba(0, 34, 28, 0.1);

  .nav-link {
    margin-bottom: -0.1875rem;
    background: none;
    border: 0.1875rem solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &:hover,
    &:focus {
      border-color: rgba(0, 34, 28, 0.1);
      isolation: isolate;
    }

    &.disabled {
      color: #8c98a4;
      background-color: transparent;
      border-color: transparent;
    }

    &.active {
      color: #321844;
      background-color: transparent;
      border-color: #321844;
    }
  }

  .nav-item.show .nav-link {
    color: #321844;
    background-color: transparent;
    border-color: #321844;
  }

  .dropdown-menu {
    margin-top: -0.1875rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.nav-pills {
  .nav-link {
    background: none;
    border: 0;
    border-radius: 0.3125rem;

    &.active {
      color: initial;
      background-color: #32184417;
    }
  }

  .show > .nav-link {
    color: initial;
    background-color: rgba(0, 189, 142, 0.1);
  }
}

.nav-fill {
  > .nav-link,
  .nav-item {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center;
  }
}

.nav-justified {
  > .nav-link,
  .nav-item {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
  }
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > {
  .tab-pane {
    display: none;
  }

  .active {
    display: block;
  }
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  > {
    .container,
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: inherit;
      flex-wrap: inherit;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
  }
}

.navbar-brand {
  padding-top: 0.40625rem;
  padding-bottom: 0.40625rem;
  margin-right: 1rem;
  font-size: 1.125rem;
  white-space: nowrap;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  .nav-link {
    padding-right: 0;
    padding-left: 0;
  }

  .dropdown-menu {
    position: static;
  }
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.5rem 0.5rem;
  font-size: 1.125rem;
  line-height: 1;
  background-color: transparent;
  border-radius: 0.3125rem;
  transition: box-shadow 0.15s ease-in-out;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0;
  }
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;

    .navbar-nav {
      -ms-flex-direction: row;
      flex-direction: row;

      .dropdown-menu {
        position: absolute;
      }

      .nav-link {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
      }
    }

    .navbar-nav-scroll {
      overflow: visible;
    }

    .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }

    .navbar-toggler,
    .offcanvas-header {
      display: none;
    }

    .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      -ms-flex-positive: 1;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      -webkit-transform: none;
      transform: none;
    }

    .offcanvas-top,
    .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0;
    }

    .offcanvas-body {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
    }
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;

    .navbar-nav {
      -ms-flex-direction: row;
      flex-direction: row;

      .dropdown-menu {
        position: absolute;
      }

      .nav-link {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
      }
    }

    .navbar-nav-scroll {
      overflow: visible;
    }

    .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }

    .navbar-toggler,
    .offcanvas-header {
      display: none;
    }

    .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      -ms-flex-positive: 1;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      -webkit-transform: none;
      transform: none;
    }

    .offcanvas-top,
    .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0;
    }

    .offcanvas-body {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
    }
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;

    .navbar-nav {
      -ms-flex-direction: row;
      flex-direction: row;

      .dropdown-menu {
        position: absolute;
      }

      .nav-link {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
      }
    }

    .navbar-nav-scroll {
      overflow: visible;
    }

    .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }

    .navbar-toggler,
    .offcanvas-header {
      display: none;
    }

    .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      -ms-flex-positive: 1;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      -webkit-transform: none;
      transform: none;
    }

    .offcanvas-top,
    .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0;
    }

    .offcanvas-body {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
    }
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;

    .navbar-nav {
      -ms-flex-direction: row;
      flex-direction: row;

      .dropdown-menu {
        position: absolute;
      }

      .nav-link {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
      }
    }

    .navbar-nav-scroll {
      overflow: visible;
    }

    .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }

    .navbar-toggler,
    .offcanvas-header {
      display: none;
    }

    .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      -ms-flex-positive: 1;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      -webkit-transform: none;
      transform: none;
    }

    .offcanvas-top,
    .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0;
    }

    .offcanvas-body {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
    }
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;

    .navbar-nav {
      -ms-flex-direction: row;
      flex-direction: row;

      .dropdown-menu {
        position: absolute;
      }

      .nav-link {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
      }
    }

    .navbar-nav-scroll {
      overflow: visible;
    }

    .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }

    .navbar-toggler,
    .offcanvas-header {
      display: none;
    }

    .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      -ms-flex-positive: 1;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      -webkit-transform: none;
      transform: none;
    }

    .offcanvas-top,
    .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0;
    }

    .offcanvas-body {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
    }
  }
}

.navbar-expand {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;

  .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;

    .dropdown-menu {
      position: absolute;
    }

    .nav-link {
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }
  }

  .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-toggler,
  .offcanvas-header {
    display: none;
  }

  .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -ms-flex-positive: 1;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    -webkit-transform: none;
    transform: none;
  }

  .offcanvas-top,
  .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .offcanvas-body {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

.navbar-light {
  .navbar-brand {
    color: #51596c;

    &:hover,
    &:focus {
      color: rgba(0, 0, 0, 0.9);
    }
  }

  .navbar-nav {
    .nav-link {
      color: #51596c;

      &:hover,
      &:focus {
        color: #321844;
      }

      &.disabled {
        color: rgba(0, 0, 0, 0.3);
      }
    }

    .show > .nav-link,
    .nav-link.active {
      color: rgba(0, 0, 0, 0.9);
    }
  }

  .navbar-toggler {
    color: #51596c;
    border-color: transparent;
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%2351596C' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  .navbar-text {
    color: #51596c;

    a {
      color: rgba(0, 0, 0, 0.9);

      &:hover,
      &:focus {
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }
}

.navbar-dark {
  .navbar-brand {
    color: #fff;

    &:hover,
    &:focus {
      color: #fff;
    }
  }

  .navbar-nav {
    .nav-link {
      color: rgba(255, 255, 255, 0.55);

      &:hover,
      &:focus {
        color: #fff;
      }

      &.disabled {
        color: rgba(255, 255, 255, 0.25);
      }
    }

    .show > .nav-link,
    .nav-link.active {
      color: #fff;
    }
  }

  .navbar-toggler {
    color: rgba(255, 255, 255, 0.55);
    border-color: rgba(255, 255, 255, 0.1);
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  .navbar-text {
    color: rgba(255, 255, 255, 0.55);

    a {
      color: #fff;

      &:hover,
      &:focus {
        color: #fff;
      }
    }
  }
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0.0625rem solid rgba(0, 34, 28, 0.1);
  border-radius: 0.3125rem;

  > {
    hr {
      margin-right: 0;
      margin-left: 0;
    }

    .list-group {
      border-top: inherit;
      border-bottom: inherit;

      &:first-child {
        border-top-width: 0;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
      }

      &:last-child {
        border-bottom-width: 0;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }
    }

    .card-header + .list-group,
    .list-group + .card-footer {
      border-top: 0;
    }
  }
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem 1.25rem;
}

.card-title {
  margin-bottom: 0.25rem;
}

.card-subtitle {
  margin-top: -0.125rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 1.25rem 1.25rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0.0625rem solid rgba(0, 34, 28, 0.1);

  &:first-child {
    border-radius: 0.25rem 0.25rem 0 0;
  }
}

.card-footer {
  padding: 1.25rem 1.25rem;
  background-color: transparent;
  border-top: 0.0625rem solid rgba(0, 34, 28, 0.1);

  &:last-child {
    border-radius: 0 0 0.25rem 0.25rem;
  }
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -1.25rem;
  margin-left: -0.625rem;
  border-bottom: 0;

  .nav-link.active {
    background-color: #fff;
    border-bottom-color: #fff;
  }
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem 1.25rem;
  border-radius: 0.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-group > .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;

    > .card {
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      margin-bottom: 0;

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        .card-img-top,
        .card-header {
          border-top-right-radius: 0;
        }

        .card-img-bottom,
        .card-footer {
          border-bottom-right-radius: 0;
        }
      }

      &:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        .card-img-top,
        .card-header {
          border-top-left-radius: 0;
        }

        .card-img-bottom,
        .card-footer {
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}

.accordion-button {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 1.5rem 2rem;
  font-size: 1rem;
  color: #321844;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: all 0.2s ease-in-out, border-radius 0.15s ease;

  &:not(.collapsed) {
    color: #321844;
    background-color: #32184417;
    box-shadow: inset 0 -0.0625rem 0 rgba(0, 34, 28, 0.1);

    &::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23008060'%3E%3Cpath d='M5.5,13a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h13a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5Z'/%3E%3C/svg%3E");
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }

  &::after {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2300221C'%3E%3Cpath d='M19,11.5v1a.5.5,0,0,1-.5.5H13v5.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V13H5.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H11V5.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V11h5.5A.5.5,0,0,1,19,11.5Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  }

  &:hover {
    z-index: 2;
  }

  &:focus {
    z-index: 3;
    border-color: rgba(140, 152, 164, 0.25);
    outline: 0;
    box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
  }
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 0.0625rem solid rgba(0, 34, 28, 0.1);

  &:first-of-type {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;

    .accordion-button {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
  }

  &:not(:first-of-type) {
    border-top: 0;
  }

  &:last-of-type {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;

    .accordion-button.collapsed {
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }

    .accordion-collapse {
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;
    }
  }
}

.accordion-body {
  padding: 1.5rem 2rem;
}

.accordion-flush {
  .accordion-collapse {
    border-width: 0;
  }

  .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 0;
    }

    .accordion-button {
      border-radius: 0;
    }
  }
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
}

.breadcrumb-item {
  + .breadcrumb-item {
    padding-left: 0.5rem;

    &::before {
      float: left;
      padding-right: 0.5rem;
      color: #97a4af;
      content: var(--bs-breadcrumb-divider, "/");
    }
  }

  &.active {
    color: #8c98a4;
  }
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #677788;
  background-color: #fff;
  border: 0.0625rem solid #e7eaf3;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    z-index: 2;
    color: #321844;
    background-color: #f8fafd;
    border-color: #e7eaf3;
  }

  &:focus {
    z-index: 3;
    color: #321844;
    background-color: #f8fafd;
    outline: 0;
    box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
  }
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-item {
  &:not(:first-child) .page-link {
    margin-left: -0.0625rem;
  }

  &.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #321844;
    border-color: #321844;
  }

  &.disabled .page-link {
    color: #8c98a4;
    pointer-events: none;
    background-color: #fff;
    border-color: #e7eaf3;
  }
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item {
  &:first-child .page-link {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  &:last-child .page-link {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }
}

.pagination-lg {
  .page-link {
    padding: 0.75rem 1.25rem;
    font-size: 1.125rem;
  }

  .page-item {
    &:first-child .page-link {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;
    }

    &:last-child .page-link {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0.3125rem;
    }
  }
}

.pagination-sm {
  .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.8125rem;
  }

  .page-item {
    &:first-child .page-link {
      border-top-left-radius: 0.125rem;
      border-bottom-left-radius: 0.125rem;
    }

    &:last-child .page-link {
      border-top-right-radius: 0.125rem;
      border-bottom-right-radius: 0.125rem;
    }
  }
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.3125rem;

  &:empty {
    display: none;
  }
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 0.0625rem solid transparent;
  border-radius: 0.3125rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem;
  }
}

.alert-primary {
  color: #004d3a;
  background-color: #cce6df;
  border-color: #b3d9cf;

  .alert-link {
    color: #003e2e;
  }
}

.alert-secondary {
  color: #313541;
  background-color: #dcdee2;
  border-color: #cbcdd3;

  .alert-link {
    color: #272a34;
  }
}

.alert-success {
  color: #044a47;
  background-color: #cde5e4;
  border-color: #b5d8d6;

  .alert-link {
    color: #033b39;
  }
}

.alert-info {
  color: #1f2c73;
  background-color: #d6dbf2;
  border-color: #c2c9ec;

  .alert-link {
    color: #19235c;
  }
}

.alert-warning {
  color: #604a33;
  background-color: #fcf1e6;
  border-color: #fbead9;

  .alert-link {
    color: #4d3b29;
  }
}

.alert-danger {
  color: #3f1526;
  background-color: #e1d3d9;
  border-color: #d2bdc6;

  .alert-link {
    color: #32111e;
  }
}

.alert-light {
  color: #646465;
  background-color: #fefefe;
  border-color: #fdfefe;

  .alert-link {
    color: #505051;
  }
}

.alert-dark {
  color: #001411;
  background-color: #ccd3d2;
  border-color: #b3bdbb;

  .alert-link {
    color: #00100e;
  }
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 0.5rem;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 0.5rem;
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 0.5rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e7eaf3;
  border-radius: 0.3125rem;
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #321844;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 0.5rem 0.5rem;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.3125rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;

  > li::before {
    content: counters(section, ".") ". ";
    counter-increment: section;
  }
}

.list-group-item-action {
  width: 100%;
  color: #677788;
  text-align: inherit;

  &:hover,
  &:focus {
    z-index: 1;
    color: #677788;
    text-decoration: none;
    background-color: #f9fafc;
  }

  &:active {
    color: #51596c;
    background-color: #f8fafd;
  }
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #223b41;
  background-color: #fff;
  border: 0.0625rem solid #e7eaf3;

  &:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  &:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }

  &.disabled,
  &:disabled {
    color: #8c98a4;
    pointer-events: none;
    background-color: #fff;
  }

  &.active {
    z-index: 2;
    color: #fff;
    background-color: #321844;
    border-color: #321844;
  }

  + .list-group-item {
    border-top-width: 0;

    &.active {
      margin-top: -0.0625rem;
      border-top-width: 0.0625rem;
    }
  }
}

.list-group-horizontal {
  -ms-flex-direction: row;
  flex-direction: row;

  > .list-group-item {
    &:first-child {
      border-bottom-left-radius: 0.3125rem;
      border-top-right-radius: 0;
    }

    &:last-child {
      border-top-right-radius: 0.3125rem;
      border-bottom-left-radius: 0;
    }

    &.active {
      margin-top: 0;
    }

    + .list-group-item {
      border-top-width: 0.0625rem;
      border-left-width: 0;

      &.active {
        margin-left: -0.0625rem;
        border-left-width: 0.0625rem;
      }
    }
  }
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
    flex-direction: row;

    > .list-group-item {
      &:first-child {
        border-bottom-left-radius: 0.3125rem;
        border-top-right-radius: 0;
      }

      &:last-child {
        border-top-right-radius: 0.3125rem;
        border-bottom-left-radius: 0;
      }

      &.active {
        margin-top: 0;
      }

      + .list-group-item {
        border-top-width: 0.0625rem;
        border-left-width: 0;

        &.active {
          margin-left: -0.0625rem;
          border-left-width: 0.0625rem;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
    flex-direction: row;

    > .list-group-item {
      &:first-child {
        border-bottom-left-radius: 0.3125rem;
        border-top-right-radius: 0;
      }

      &:last-child {
        border-top-right-radius: 0.3125rem;
        border-bottom-left-radius: 0;
      }

      &.active {
        margin-top: 0;
      }

      + .list-group-item {
        border-top-width: 0.0625rem;
        border-left-width: 0;

        &.active {
          margin-left: -0.0625rem;
          border-left-width: 0.0625rem;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
    flex-direction: row;

    > .list-group-item {
      &:first-child {
        border-bottom-left-radius: 0.3125rem;
        border-top-right-radius: 0;
      }

      &:last-child {
        border-top-right-radius: 0.3125rem;
        border-bottom-left-radius: 0;
      }

      &.active {
        margin-top: 0;
      }

      + .list-group-item {
        border-top-width: 0.0625rem;
        border-left-width: 0;

        &.active {
          margin-left: -0.0625rem;
          border-left-width: 0.0625rem;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
    flex-direction: row;

    > .list-group-item {
      &:first-child {
        border-bottom-left-radius: 0.3125rem;
        border-top-right-radius: 0;
      }

      &:last-child {
        border-top-right-radius: 0.3125rem;
        border-bottom-left-radius: 0;
      }

      &.active {
        margin-top: 0;
      }

      + .list-group-item {
        border-top-width: 0.0625rem;
        border-left-width: 0;

        &.active {
          margin-left: -0.0625rem;
          border-left-width: 0.0625rem;
        }
      }
    }
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    -ms-flex-direction: row;
    flex-direction: row;

    > .list-group-item {
      &:first-child {
        border-bottom-left-radius: 0.3125rem;
        border-top-right-radius: 0;
      }

      &:last-child {
        border-top-right-radius: 0.3125rem;
        border-bottom-left-radius: 0;
      }

      &.active {
        margin-top: 0;
      }

      + .list-group-item {
        border-top-width: 0.0625rem;
        border-left-width: 0;

        &.active {
          margin-left: -0.0625rem;
          border-left-width: 0.0625rem;
        }
      }
    }
  }
}

.list-group-flush {
  border-radius: 0;

  > .list-group-item {
    border-width: 0 0 0.0625rem;

    &:last-child {
      border-bottom-width: 0;
    }
  }
}

.list-group-item-primary {
  color: #004d3a;
  background-color: #cce6df;

  &.list-group-item-action {
    &:hover,
    &:focus {
      color: #004d3a;
      background-color: #b8cfc9;
    }

    &.active {
      color: #fff;
      background-color: #004d3a;
      border-color: #004d3a;
    }
  }
}

.list-group-item-secondary {
  color: #313541;
  background-color: #dcdee2;

  &.list-group-item-action {
    &:hover,
    &:focus {
      color: #313541;
      background-color: #c6c8cb;
    }

    &.active {
      color: #fff;
      background-color: #313541;
      border-color: #313541;
    }
  }
}

.list-group-item-success {
  color: #044a47;
  background-color: #cde5e4;

  &.list-group-item-action {
    &:hover,
    &:focus {
      color: #044a47;
      background-color: #b9cecd;
    }

    &.active {
      color: #fff;
      background-color: #044a47;
      border-color: #044a47;
    }
  }
}

.list-group-item-info {
  color: #1f2c73;
  background-color: #d6dbf2;

  &.list-group-item-action {
    &:hover,
    &:focus {
      color: #1f2c73;
      background-color: #c1c5da;
    }

    &.active {
      color: #fff;
      background-color: #1f2c73;
      border-color: #1f2c73;
    }
  }
}

.list-group-item-warning {
  color: #604a33;
  background-color: #fcf1e6;

  &.list-group-item-action {
    &:hover,
    &:focus {
      color: #604a33;
      background-color: #e3d9cf;
    }

    &.active {
      color: #fff;
      background-color: #604a33;
      border-color: #604a33;
    }
  }
}

.list-group-item-danger {
  color: #3f1526;
  background-color: #e1d3d9;

  &.list-group-item-action {
    &:hover,
    &:focus {
      color: #3f1526;
      background-color: #cbbec3;
    }

    &.active {
      color: #fff;
      background-color: #3f1526;
      border-color: #3f1526;
    }
  }
}

.list-group-item-light {
  color: #646465;
  background-color: #fefefe;

  &.list-group-item-action {
    &:hover,
    &:focus {
      color: #646465;
      background-color: #e5e5e5;
    }

    &.active {
      color: #fff;
      background-color: #646465;
      border-color: #646465;
    }
  }
}

.list-group-item-dark {
  color: #001411;
  background-color: #ccd3d2;

  &.list-group-item-action {
    &:hover,
    &:focus {
      color: #001411;
      background-color: #b8bebd;
    }

    &.active {
      color: #fff;
      background-color: #001411;
      border-color: #001411;
    }
  }
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    (center / 1em) auto no-repeat;
  border: 0;
  border-radius: 0.3125rem;
  opacity: 0.5;

  &:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
    opacity: 1;
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: 0.25;
  }
}

.btn-close-white {
  -webkit-filter: invert(1) grayscale(100%) brightness(200%);
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 34, 28, 0.1);
  box-shadow: 0rem 0.375rem 0.75rem rgba(140, 152, 164, 0.075);
  border-radius: 0.3125rem;

  &.showing {
    opacity: 0;
  }

  &:not(.show) {
    display: none;
  }
}

.toast-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;

  > :not(:last-child) {
    margin-bottom: 0.75rem;
  }
}

.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem 1rem;
  color: #8c98a4;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.3125rem - 1px);
  border-top-right-radius: calc(0.3125rem - 1px);

  .btn-close {
    margin-right: -0.5rem;
    margin-left: 1rem;
  }
}

.toast-body {
  padding: 1rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal {
  &.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
  }

  &.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
  }

  &.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);

  .modal-content {
    max-height: 100%;
    overflow: hidden;
  }

  .modal-body {
    overflow-y: auto;
  }
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid transparent;
  border-radius: 0.3125rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 34, 28, 0.25);

  &.fade {
    opacity: 0;
  }

  &.show {
    opacity: 1;
  }
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-bottom: 0 solid rgba(0, 34, 28, 0.1);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;

  .btn-close {
    padding: 0.5rem 0.75rem;
    margin: -0.5rem -0.75rem -0.5rem auto;
  }
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.5rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1.25rem;
  border-top: 0 solid rgba(0, 34, 28, 0.1);
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;

  > * {
    margin: 0.25rem;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 550px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 400px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;

  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-header {
    border-radius: 0;
  }

  .modal-body {
    overflow-y: auto;
  }

  .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;

    .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
    }

    .modal-header {
      border-radius: 0;
    }

    .modal-body {
      overflow-y: auto;
    }

    .modal-footer {
      border-radius: 0;
    }
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;

    .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
    }

    .modal-header {
      border-radius: 0;
    }

    .modal-body {
      overflow-y: auto;
    }

    .modal-footer {
      border-radius: 0;
    }
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;

    .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
    }

    .modal-header {
      border-radius: 0;
    }

    .modal-body {
      overflow-y: auto;
    }

    .modal-footer {
      border-radius: 0;
    }
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;

    .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
    }

    .modal-header {
      border-radius: 0;
    }

    .modal-body {
      overflow-y: auto;
    }

    .modal-footer {
      border-radius: 0;
    }
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;

    .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
    }

    .modal-header {
      border-radius: 0;
    }

    .modal-body {
      overflow-y: auto;
    }

    .modal-footer {
      border-radius: 0;
    }
  }
}

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  opacity: 0;

  &.show {
    opacity: 1;
  }

  .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;

    &::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #321844;
}

.bs-tooltip-end,
.bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #321844;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #321844;
}

.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #321844;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #321844;
  border-radius: 0.3125rem;
}

.list-checked-item {
  font-size: 14px;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid transparent;
  border-radius: 0.3125rem;

  .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }
}

.bs-popover-top > .popover-arrow,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: -0.5625rem;
}

.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: transparent;
}

.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: 0.0625rem;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: -0.5625rem;
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: transparent;
}

.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: 0.0625rem;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: -0.5625rem;
}

.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: transparent;
}

.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: 0.0625rem;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 0.0625rem solid #f8fafd;
}

.bs-popover-start > .popover-arrow,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: -0.5625rem;
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: transparent;
}

.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: 0.0625rem;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 1rem 1.5rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #321844;
  background-color: #f8fafd;
  border-bottom: 0.0625rem solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;

  &:empty {
    display: none;
  }
}

.popover-body {
  padding: 1.5rem 1.5rem;
  color: #51596c;
}

.carousel {
  position: relative;

  &.pointer-event {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
  }
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;

  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;

  &.active {
    display: block;
  }
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

/* rtl:end:ignore */

.carousel-fade {
  .carousel-item {
    opacity: 0;
    transition-property: opacity;
    -webkit-transform: none;
    transform: none;

    &.active {
      z-index: 1;
      opacity: 1;
    }
  }

  .carousel-item-next.carousel-item-start,
  .carousel-item-prev.carousel-item-end {
    z-index: 1;
    opacity: 1;
  }

  .active {
    &.carousel-item-start,
    &.carousel-item-end {
      z-index: 0;
      opacity: 0;
      transition: opacity 0s 0.6s;
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active {
    &.carousel-item-start,
    &.carousel-item-end {
      transition: none;
    }
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev {
  &:hover,
  &:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
  }
}

.carousel-control-next {
  &:hover,
  &:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
  }
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;

  [data-bs-target] {
    box-sizing: content-box;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
  }

  .active {
    opacity: 1;
  }
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark {
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    -webkit-filter: invert(1) grayscale(100);
    filter: invert(1) grayscale(100);
  }

  .carousel-indicators [data-bs-target] {
    background-color: #000;
  }

  .carousel-caption {
    color: #000;
  }
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 34, 28, 0.25);

  &.fade {
    opacity: 0;
  }

  &.show {
    opacity: 1;
  }
}

.offcanvas-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1.5rem 1.5rem;

  .btn-close {
    padding: 0.75rem 0.75rem;
    margin-top: -0.75rem;
    margin-right: -0.75rem;
    margin-bottom: -0.75rem;
  }
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 1.5rem 1.5rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 0.0625rem solid transparent;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 0.0625rem solid transparent;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 0.0625rem solid transparent;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 0.0625rem solid transparent;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.offcanvas.show {
  -webkit-transform: none;
  transform: none;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;

  &.btn::before {
    display: inline-block;
    content: "";
  }
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  -webkit-animation: placeholder-glow 2s ease-in-out infinite;
  animation: placeholder-glow 2s ease-in-out infinite;
}

@-webkit-keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

.placeholder-wave {
  -webkit-mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  -webkit-animation: placeholder-wave 2s linear infinite;
  animation: placeholder-wave 2s linear infinite;
}

@-webkit-keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
    mask-position: -200% 0%;
  }
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
    mask-position: -200% 0%;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #321844;

  &:hover,
  &:focus {
    color: #00664d;
  }
}

.link-secondary {
  color: #51596c;

  &:hover,
  &:focus {
    color: #414756;
  }
}

.link-success {
  color: #077c76;

  &:hover,
  &:focus {
    color: #06635e;
  }
}

.link-info {
  color: #334ac0;

  &:hover,
  &:focus {
    color: #293b9a;
  }
}

.link-warning {
  color: #f1b980;

  &:hover,
  &:focus {
    color: #f4c799;
  }
}

.link-danger {
  color: #692340;

  &:hover,
  &:focus {
    color: #541c33;
  }
}

.link-light {
  color: #f9fafc;

  &:hover,
  &:focus {
    color: #fafbfd;
  }
}

.link-dark {
  color: #321844;

  &:hover,
  &:focus {
    color: #001b16;
  }
}

.ratio {
  position: relative;
  width: 100%;

  &::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: "";
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.hstack {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.vstack {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 1;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125) !important;
}

.shadow-sm {
  box-shadow: 0rem 0.375rem 0.75rem rgba(140, 152, 164, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.shadow-xl {
  box-shadow: 0rem 3.75rem 5rem 0rem rgba(140, 152, 164, 0.3) !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  -webkit-transform: translateX(-50%) !important;
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  -webkit-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
}

.border {
  border: 0.0625rem solid rgba(0, 34, 28, 0.1) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 0.0625rem solid rgba(0, 34, 28, 0.1) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 0.0625rem solid rgba(0, 34, 28, 0.1) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 0.0625rem solid rgba(0, 34, 28, 0.1) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 0.0625rem solid rgba(0, 34, 28, 0.1) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #321844 !important;
}

.border-secondary {
  border-color: #51596c !important;
}

.border-success {
  border-color: #077c76 !important;
}

.border-info {
  border-color: #334ac0 !important;
}

.border-warning {
  border-color: #f1b980 !important;
}

.border-danger {
  border-color: #692340 !important;
}

.border-light {
  border-color: #f9fafc !important;
}

.border-dark {
  border-color: #321844 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-white-10 {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}
.w-30 {
  width: 30% !important;
}
.w-40 {
  width: 40% !important;
}
.w-50 {
  width: 50% !important;
}
.w-60 {
  width: 60% !important;
}
.w-70 {
  width: 70% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.w-65 {
  width: 65% !important;
}
.w-85 {
  width: 85% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 2rem !important;
}

.gap-6 {
  gap: 2.5rem !important;
}

.gap-7 {
  gap: 3rem !important;
}

.gap-8 {
  gap: 3.5rem !important;
}

.gap-9 {
  gap: 4rem !important;
}

.gap-10 {
  gap: 4.5rem !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.justify-content-evenly {
  -ms-flex-pack: space-evenly !important;
  justify-content: space-evenly !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

.order-first {
  -ms-flex-order: -1 !important;
  order: -1 !important;
}

.order-0 {
  -ms-flex-order: 0 !important;
  order: 0 !important;
}

.order-1 {
  -ms-flex-order: 1 !important;
  order: 1 !important;
}

.order-2 {
  -ms-flex-order: 2 !important;
  order: 2 !important;
}

.order-3 {
  -ms-flex-order: 3 !important;
  order: 3 !important;
}

.order-4 {
  -ms-flex-order: 4 !important;
  order: 4 !important;
}

.order-5 {
  -ms-flex-order: 5 !important;
  order: 5 !important;
}

.order-last {
  -ms-flex-order: 6 !important;
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.m-8 {
  margin: 3.5rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.m-10 {
  margin: 4.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-6 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-7 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-8 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-9 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-10 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-6 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-7 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-8 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-9 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-10 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 2.5rem !important;
}

.mt-7 {
  margin-top: 3rem !important;
}

.mt-8 {
  margin-top: 3.5rem !important;
}

.mt-9 {
  margin-top: 4rem !important;
}

.mt-10 {
  margin-top: 4.5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 2rem !important;
}

.me-6 {
  margin-right: 2.5rem !important;
}

.me-7 {
  margin-right: 3rem !important;
}

.me-8 {
  margin-right: 3.5rem !important;
}

.me-9 {
  margin-right: 4rem !important;
}

.me-10 {
  margin-right: 4.5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.mb-6 {
  margin-bottom: 2.5rem !important;
}

.mb-7 {
  margin-bottom: 3rem !important;
}

.mb-8 {
  margin-bottom: 3.5rem !important;
}

.mb-9 {
  margin-bottom: 4rem !important;
}

.mb-10 {
  margin-bottom: 4.5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 2rem !important;
}

.ms-6 {
  margin-left: 2.5rem !important;
}

.ms-7 {
  margin-left: 3rem !important;
}

.ms-8 {
  margin-left: 3.5rem !important;
}

.ms-9 {
  margin-left: 4rem !important;
}

.ms-10 {
  margin-left: 4.5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.p-6 {
  padding: 2.5rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.p-8 {
  padding: 3.5rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.p-10 {
  padding: 4.5rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-6 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-7 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-8 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-9 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-10 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-6 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-7 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-8 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-9 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-10 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pt-6 {
  padding-top: 2.5rem !important;
}

.pt-7 {
  padding-top: 3rem !important;
}

.pt-8 {
  padding-top: 3.5rem !important;
}

.pt-9 {
  padding-top: 4rem !important;
}

.pt-10 {
  padding-top: 4.5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 2rem !important;
}

.pe-6 {
  padding-right: 2.5rem !important;
}

.pe-7 {
  padding-right: 3rem !important;
}

.pe-8 {
  padding-right: 3.5rem !important;
}

.pe-9 {
  padding-right: 4rem !important;
}

.pe-10 {
  padding-right: 4.5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2rem !important;
}

.pb-6 {
  padding-bottom: 2.5rem !important;
}

.pb-7 {
  padding-bottom: 3rem !important;
}

.pb-8 {
  padding-bottom: 3.5rem !important;
}

.pb-9 {
  padding-bottom: 4rem !important;
}

.pb-10 {
  padding-bottom: 4.5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 2rem !important;
}

.ps-6 {
  padding-left: 2.5rem !important;
}

.ps-7 {
  padding-left: 3rem !important;
}

.ps-8 {
  padding-left: 3.5rem !important;
}

.ps-9 {
  padding-left: 4rem !important;
}

.ps-10 {
  padding-left: 4.5rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.475rem + 2.7vw) !important;
}

.fs-2 {
  font-size: calc(1.425rem + 2.1vw) !important;
}

.fs-3 {
  font-size: calc(1.3375rem + 1.05vw) !important;
}

.fs-4 {
  font-size: calc(1.2875rem + 0.45vw) !important;
}

.fs-5 {
  font-size: calc(1.2625rem + 0.15vw) !important;
}

.fs-6 {
  font-size: 1.125rem !important;
}
.fs-09 {
  font-size: 0.9em;
}
.fs-08 {
  font-size: 0.8em;
}
.fs-07 {
  font-size: 0.7em;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-grey {
  color: #adbdcc !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-900 {
  color: var(--bs-gray-900);
}
.text-gray-800 {
  color: var(--bs-gray-800);
}

.text-gray-700 {
  color: var(--bs-gray-700);
}
.text-gray-600 {
  color: var(--bs-gray-600);
}
.text-gray-500 {
  color: var(--bs-gray-500);
}
.text-gray-400 {
  color: var(--bs-gray-400);
}
.text-gray-300 {
  color: var(--bs-gray-300);
}
.text-gray-200 {
  color: var(--bs-gray-200);
}
.text-gray-100 {
  color: var(--bs-gray-100);
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #8c98a4 !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-white-70 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.7) !important;
}

.text-primary-light {
  --bs-text-opacity: 1;
  color: #fff !important;
}

.text-primary-dark {
  --bs-text-opacity: 1;
  color: #321844 !important;
}

.text-dark-blue {
  --bs-text-opacity: 1;
  color: #0a071b !important
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-success-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-900 {
  background-color: var(--bs-gray-900);
}
.bg-gray-800 {
  background-color: var(--bs-gray-800);
}
.bg-gray-700 {
  background-color: var(--bs-gray-700);
}
.bg-gray-600 {
  background-color: var(--bs-gray-600);
}
.bg-gray-500 {
  background-color: var(--bs-gray-500);
}
.bg-gray-400 {
  background-color: var(--bs-light-400);
}
.bg-gray-300 {
  background-color: var(--bs-gray-300);
}
.bg-gray-200 {
  background-color: var(--bs-gray-200);
}
.bg-gray-100 {
  background-color: var(--bs-gray-100);
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-body-bg-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-primary-dark {
  --bs-bg-opacity: 1;
  background-color: #321844 !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.3125rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.125rem !important;
}

.rounded-2,
.rounded-3 {
  border-radius: 0.3125rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 6.1875rem !important;
}

.rounded-top {
  border-top-left-radius: 0.3125rem !important;
  border-top-right-radius: 0.3125rem !important;
}

.rounded-end {
  border-top-right-radius: 0.3125rem !important;
  border-bottom-right-radius: 0.3125rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.3125rem !important;
  border-bottom-left-radius: 0.3125rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.3125rem !important;
  border-top-left-radius: 0.3125rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.content-space-t-0 {
  padding-top: 0 !important;
}

.content-space-t-1 {
  padding-top: 4rem !important;
}

.content-space-t-2 {
  padding-top: 5rem !important;
}

.content-space-t-3 {
  padding-top: 7.5rem !important;
}

.content-space-t-4 {
  padding-top: 10rem !important;
}

.content-space-t-auto {
  padding-top: auto !important;
}

.content-space-b-0 {
  padding-bottom: 0 !important;
}

.content-space-b-1 {
  padding-bottom: 4rem !important;
}

.content-space-b-2 {
  padding-bottom: 5rem !important;
}

.content-space-b-3 {
  padding-bottom: 7.5rem !important;
}

.content-space-b-4 {
  padding-bottom: 10rem !important;
}

.content-space-b-auto {
  padding-bottom: auto !important;
}

.content-space-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.content-space-1 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.content-space-2 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.content-space-3 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}

.content-space-4 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.content-space-auto {
  padding-top: auto !important;
  padding-bottom: auto !important;
}

.zi-n1 {
  z-index: -1 !important;
}

.zi-0 {
  z-index: 0 !important;
}

.zi-1 {
  z-index: 1 !important;
}

.zi-2 {
  z-index: 2 !important;
}

.zi-3 {
  z-index: 3 !important;
}

.bg-soft-primary {
  background-color: rgba(0, 128, 96, 0.1) !important;
}

.bg-soft-secondary {
  background-color: rgba(81, 89, 108, 0.1) !important;
}

.bg-soft-success {
  background-color: rgba(7, 124, 118, 0.1) !important;
}

.bg-soft-info {
  background-color: #f6f9fc !important;
}

.bg-soft-warning {
  background-color: rgba(241, 185, 128, 0.1) !important;
}

.bg-soft-danger {
  background-color: rgba(105, 35, 64, 0.1) !important;
}

.bg-soft-light {
  background-color: rgba(249, 250, 252, 0.1) !important;
}

.bg-soft-dark {
  background-color: rgba(0, 34, 28, 0.1) !important;
}

.bg-soft-primary-light {
  background-color: #321844 !important;
}

.bg-dark-blue {
  background-color: #0a071b !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .position-sm-static {
    position: static !important;
  }

  .position-sm-relative {
    position: relative !important;
  }

  .position-sm-absolute {
    position: absolute !important;
  }

  .position-sm-fixed {
    position: fixed !important;
  }

  .position-sm-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .top-sm-0 {
    top: 0 !important;
  }

  .top-sm-50 {
    top: 50% !important;
  }

  .top-sm-100 {
    top: 100% !important;
  }

  .bottom-sm-0 {
    bottom: 0 !important;
  }

  .bottom-sm-50 {
    bottom: 50% !important;
  }

  .bottom-sm-100 {
    bottom: 100% !important;
  }

  .start-sm-0 {
    left: 0 !important;
  }

  .start-sm-50 {
    left: 50% !important;
  }

  .start-sm-100 {
    left: 100% !important;
  }

  .end-sm-0 {
    right: 0 !important;
  }

  .end-sm-50 {
    right: 50% !important;
  }

  .end-sm-100 {
    right: 100% !important;
  }

  .translate-middle-sm {
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
  }

  .translate-middle-sm-x {
    -webkit-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
  }

  .translate-middle-sm-y {
    -webkit-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-auto {
    width: auto !important;
  }

  .w-sm-65 {
    width: 65% !important;
  }

  .w-sm-85 {
    width: 85% !important;
  }

  .vh-sm-100 {
    height: 100vh !important;
  }

  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 2rem !important;
  }

  .gap-sm-6 {
    gap: 2.5rem !important;
  }

  .gap-sm-7 {
    gap: 3rem !important;
  }

  .gap-sm-8 {
    gap: 3.5rem !important;
  }

  .gap-sm-9 {
    gap: 4rem !important;
  }

  .gap-sm-10 {
    gap: 4.5rem !important;
  }

  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }

  .order-sm-first {
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }

  .order-sm-0 {
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  .order-sm-1 {
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .order-sm-2 {
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .order-sm-3 {
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .order-sm-4 {
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .order-sm-5 {
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  .order-sm-last {
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 2rem !important;
  }

  .m-sm-6 {
    margin: 2.5rem !important;
  }

  .m-sm-7 {
    margin: 3rem !important;
  }

  .m-sm-8 {
    margin: 3.5rem !important;
  }

  .m-sm-9 {
    margin: 4rem !important;
  }

  .m-sm-10 {
    margin: 4.5rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-sm-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-sm-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-sm-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-sm-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-sm-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 2rem !important;
  }

  .mt-sm-6 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-7 {
    margin-top: 3rem !important;
  }

  .mt-sm-8 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-9 {
    margin-top: 4rem !important;
  }

  .mt-sm-10 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 2rem !important;
  }

  .me-sm-6 {
    margin-right: 2.5rem !important;
  }

  .me-sm-7 {
    margin-right: 3rem !important;
  }

  .me-sm-8 {
    margin-right: 3.5rem !important;
  }

  .me-sm-9 {
    margin-right: 4rem !important;
  }

  .me-sm-10 {
    margin-right: 4.5rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 2rem !important;
  }

  .ms-sm-6 {
    margin-left: 2.5rem !important;
  }

  .ms-sm-7 {
    margin-left: 3rem !important;
  }

  .ms-sm-8 {
    margin-left: 3.5rem !important;
  }

  .ms-sm-9 {
    margin-left: 4rem !important;
  }

  .ms-sm-10 {
    margin-left: 4.5rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 2rem !important;
  }

  .p-sm-6 {
    padding: 2.5rem !important;
  }

  .p-sm-7 {
    padding: 3rem !important;
  }

  .p-sm-8 {
    padding: 3.5rem !important;
  }

  .p-sm-9 {
    padding: 4rem !important;
  }

  .p-sm-10 {
    padding: 4.5rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-sm-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-sm-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-sm-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-sm-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-sm-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-sm-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 2rem !important;
  }

  .pt-sm-6 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-7 {
    padding-top: 3rem !important;
  }

  .pt-sm-8 {
    padding-top: 3.5rem !important;
  }

  .pt-sm-9 {
    padding-top: 4rem !important;
  }

  .pt-sm-10 {
    padding-top: 4.5rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 2rem !important;
  }

  .pe-sm-6 {
    padding-right: 2.5rem !important;
  }

  .pe-sm-7 {
    padding-right: 3rem !important;
  }

  .pe-sm-8 {
    padding-right: 3.5rem !important;
  }

  .pe-sm-9 {
    padding-right: 4rem !important;
  }

  .pe-sm-10 {
    padding-right: 4.5rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 4.5rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 2rem !important;
  }

  .ps-sm-6 {
    padding-left: 2.5rem !important;
  }

  .ps-sm-7 {
    padding-left: 3rem !important;
  }

  .ps-sm-8 {
    padding-left: 3.5rem !important;
  }

  .ps-sm-9 {
    padding-left: 4rem !important;
  }

  .ps-sm-10 {
    padding-left: 4.5rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .content-space-t-sm-0 {
    padding-top: 0 !important;
  }

  .content-space-t-sm-1 {
    padding-top: 4rem !important;
  }

  .content-space-t-sm-2 {
    padding-top: 5rem !important;
  }

  .content-space-t-sm-3 {
    padding-top: 7.5rem !important;
  }

  .content-space-t-sm-4 {
    padding-top: 10rem !important;
  }

  .content-space-t-sm-auto {
    padding-top: auto !important;
  }

  .content-space-b-sm-0 {
    padding-bottom: 0 !important;
  }

  .content-space-b-sm-1 {
    padding-bottom: 4rem !important;
  }

  .content-space-b-sm-2 {
    padding-bottom: 5rem !important;
  }

  .content-space-b-sm-3 {
    padding-bottom: 7.5rem !important;
  }

  .content-space-b-sm-4 {
    padding-bottom: 10rem !important;
  }

  .content-space-b-sm-auto {
    padding-bottom: auto !important;
  }

  .content-space-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .content-space-sm-1 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .content-space-sm-2 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .content-space-sm-3 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .content-space-sm-4 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .content-space-sm-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .position-md-static {
    position: static !important;
  }

  .position-md-relative {
    position: relative !important;
  }

  .position-md-absolute {
    position: absolute !important;
  }

  .position-md-fixed {
    position: fixed !important;
  }

  .position-md-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .top-md-0 {
    top: 0 !important;
  }

  .top-md-50 {
    top: 50% !important;
  }

  .top-md-100 {
    top: 100% !important;
  }

  .bottom-md-0 {
    bottom: 0 !important;
  }

  .bottom-md-50 {
    bottom: 50% !important;
  }

  .bottom-md-100 {
    bottom: 100% !important;
  }

  .start-md-0 {
    left: 0 !important;
  }

  .start-md-50 {
    left: 50% !important;
  }

  .start-md-100 {
    left: 100% !important;
  }

  .end-md-0 {
    right: 0 !important;
  }

  .end-md-50 {
    right: 50% !important;
  }

  .end-md-100 {
    right: 100% !important;
  }

  .translate-middle-md {
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
  }

  .translate-middle-md-x {
    -webkit-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
  }

  .translate-middle-md-y {
    -webkit-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
  }

  .w-md-25 {
    width: 25% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .w-md-auto {
    width: auto !important;
  }

  .w-md-65 {
    width: 65% !important;
  }

  .w-md-85 {
    width: 85% !important;
  }

  .vh-md-100 {
    height: 100vh !important;
  }

  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 2rem !important;
  }

  .gap-md-6 {
    gap: 2.5rem !important;
  }

  .gap-md-7 {
    gap: 3rem !important;
  }

  .gap-md-8 {
    gap: 3.5rem !important;
  }

  .gap-md-9 {
    gap: 4rem !important;
  }

  .gap-md-10 {
    gap: 4.5rem !important;
  }

  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }

  .order-md-first {
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }

  .order-md-0 {
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  .order-md-1 {
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .order-md-2 {
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .order-md-3 {
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .order-md-4 {
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .order-md-5 {
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  .order-md-last {
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 2rem !important;
  }

  .m-md-6 {
    margin: 2.5rem !important;
  }

  .m-md-7 {
    margin: 3rem !important;
  }

  .m-md-8 {
    margin: 3.5rem !important;
  }

  .m-md-9 {
    margin: 4rem !important;
  }

  .m-md-10 {
    margin: 4.5rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-md-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-md-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-md-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-md-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-md-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 2rem !important;
  }

  .mt-md-6 {
    margin-top: 2.5rem !important;
  }

  .mt-md-7 {
    margin-top: 3rem !important;
  }

  .mt-md-8 {
    margin-top: 3.5rem !important;
  }

  .mt-md-9 {
    margin-top: 4rem !important;
  }

  .mt-md-10 {
    margin-top: 4.5rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 2rem !important;
  }

  .me-md-6 {
    margin-right: 2.5rem !important;
  }

  .me-md-7 {
    margin-right: 3rem !important;
  }

  .me-md-8 {
    margin-right: 3.5rem !important;
  }

  .me-md-9 {
    margin-right: 4rem !important;
  }

  .me-md-10 {
    margin-right: 4.5rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 2rem !important;
  }

  .mb-md-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 3rem !important;
  }

  .mb-md-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-9 {
    margin-bottom: 4rem !important;
  }

  .mb-md-10 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 2rem !important;
  }

  .ms-md-6 {
    margin-left: 2.5rem !important;
  }

  .ms-md-7 {
    margin-left: 3rem !important;
  }

  .ms-md-8 {
    margin-left: 3.5rem !important;
  }

  .ms-md-9 {
    margin-left: 4rem !important;
  }

  .ms-md-10 {
    margin-left: 4.5rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 2rem !important;
  }

  .p-md-6 {
    padding: 2.5rem !important;
  }

  .p-md-7 {
    padding: 3rem !important;
  }

  .p-md-8 {
    padding: 3.5rem !important;
  }

  .p-md-9 {
    padding: 4rem !important;
  }

  .p-md-10 {
    padding: 4.5rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-md-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-md-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-md-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-md-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-md-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-md-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 2rem !important;
  }

  .pt-md-6 {
    padding-top: 2.5rem !important;
  }

  .pt-md-7 {
    padding-top: 3rem !important;
  }

  .pt-md-8 {
    padding-top: 3.5rem !important;
  }

  .pt-md-9 {
    padding-top: 4rem !important;
  }

  .pt-md-10 {
    padding-top: 4.5rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 2rem !important;
  }

  .pe-md-6 {
    padding-right: 2.5rem !important;
  }

  .pe-md-7 {
    padding-right: 3rem !important;
  }

  .pe-md-8 {
    padding-right: 3.5rem !important;
  }

  .pe-md-9 {
    padding-right: 4rem !important;
  }

  .pe-md-10 {
    padding-right: 4.5rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 2rem !important;
  }

  .pb-md-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-7 {
    padding-bottom: 3rem !important;
  }

  .pb-md-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-md-9 {
    padding-bottom: 4rem !important;
  }

  .pb-md-10 {
    padding-bottom: 4.5rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 2rem !important;
  }

  .ps-md-6 {
    padding-left: 2.5rem !important;
  }

  .ps-md-7 {
    padding-left: 3rem !important;
  }

  .ps-md-8 {
    padding-left: 3.5rem !important;
  }

  .ps-md-9 {
    padding-left: 4rem !important;
  }

  .ps-md-10 {
    padding-left: 4.5rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .content-space-t-md-0 {
    padding-top: 0 !important;
  }

  .content-space-t-md-1 {
    padding-top: 4rem !important;
  }

  .content-space-t-md-2 {
    padding-top: 5rem !important;
  }

  .content-space-t-md-3 {
    padding-top: 7.5rem !important;
  }

  .content-space-t-md-4 {
    padding-top: 10rem !important;
  }

  .content-space-t-md-auto {
    padding-top: auto !important;
  }

  .content-space-b-md-0 {
    padding-bottom: 0 !important;
  }

  .content-space-b-md-1 {
    padding-bottom: 4rem !important;
  }

  .content-space-b-md-2 {
    padding-bottom: 5rem !important;
  }

  .content-space-b-md-3 {
    padding-bottom: 7.5rem !important;
  }

  .content-space-b-md-4 {
    padding-bottom: 10rem !important;
  }

  .content-space-b-md-auto {
    padding-bottom: auto !important;
  }

  .content-space-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .content-space-md-1 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .content-space-md-2 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .content-space-md-3 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .content-space-md-4 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .content-space-md-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .position-lg-static {
    position: static !important;
  }

  .position-lg-relative {
    position: relative !important;
  }

  .position-lg-absolute {
    position: absolute !important;
  }

  .position-lg-fixed {
    position: fixed !important;
  }

  .position-lg-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .top-lg-0 {
    top: 0 !important;
  }

  .top-lg-50 {
    top: 50% !important;
  }

  .top-lg-100 {
    top: 100% !important;
  }

  .bottom-lg-0 {
    bottom: 0 !important;
  }

  .bottom-lg-50 {
    bottom: 50% !important;
  }

  .bottom-lg-100 {
    bottom: 100% !important;
  }

  .start-lg-0 {
    left: 0 !important;
  }

  .start-lg-50 {
    left: 50% !important;
  }

  .start-lg-100 {
    left: 100% !important;
  }

  .end-lg-0 {
    right: 0 !important;
  }

  .end-lg-50 {
    right: 50% !important;
  }

  .end-lg-100 {
    right: 100% !important;
  }

  .translate-middle-lg {
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
  }

  .translate-middle-lg-x {
    -webkit-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
  }

  .translate-middle-lg-y {
    -webkit-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-auto {
    width: auto !important;
  }

  .w-lg-65 {
    width: 65% !important;
  }

  .w-lg-85 {
    width: 85% !important;
  }

  .vh-lg-100 {
    height: 100vh !important;
  }

  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 2rem !important;
  }

  .gap-lg-6 {
    gap: 2.5rem !important;
  }

  .gap-lg-7 {
    gap: 3rem !important;
  }

  .gap-lg-8 {
    gap: 3.5rem !important;
  }

  .gap-lg-9 {
    gap: 4rem !important;
  }

  .gap-lg-10 {
    gap: 4.5rem !important;
  }

  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }

  .order-lg-first {
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }

  .order-lg-0 {
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  .order-lg-1 {
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .order-lg-2 {
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .order-lg-3 {
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .order-lg-4 {
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .order-lg-5 {
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  .order-lg-last {
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 2rem !important;
  }

  .m-lg-6 {
    margin: 2.5rem !important;
  }

  .m-lg-7 {
    margin: 3rem !important;
  }

  .m-lg-8 {
    margin: 3.5rem !important;
  }

  .m-lg-9 {
    margin: 4rem !important;
  }

  .m-lg-10 {
    margin: 4.5rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-lg-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-lg-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-lg-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-lg-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-lg-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 2rem !important;
  }

  .mt-lg-6 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-7 {
    margin-top: 3rem !important;
  }

  .mt-lg-8 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-9 {
    margin-top: 4rem !important;
  }

  .mt-lg-10 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 2rem !important;
  }

  .me-lg-6 {
    margin-right: 2.5rem !important;
  }

  .me-lg-7 {
    margin-right: 3rem !important;
  }

  .me-lg-8 {
    margin-right: 3.5rem !important;
  }

  .me-lg-9 {
    margin-right: 4rem !important;
  }

  .me-lg-10 {
    margin-right: 4.5rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 2rem !important;
  }

  .ms-lg-6 {
    margin-left: 2.5rem !important;
  }

  .ms-lg-7 {
    margin-left: 3rem !important;
  }

  .ms-lg-8 {
    margin-left: 3.5rem !important;
  }

  .ms-lg-9 {
    margin-left: 4rem !important;
  }

  .ms-lg-10 {
    margin-left: 4.5rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 2rem !important;
  }

  .p-lg-6 {
    padding: 2.5rem !important;
  }

  .p-lg-7 {
    padding: 3rem !important;
  }

  .p-lg-8 {
    padding: 3.5rem !important;
  }

  .p-lg-9 {
    padding: 4rem !important;
  }

  .p-lg-10 {
    padding: 4.5rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-lg-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-lg-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-lg-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-lg-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-lg-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-lg-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 2rem !important;
  }

  .pt-lg-6 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-7 {
    padding-top: 3rem !important;
  }

  .pt-lg-8 {
    padding-top: 3.5rem !important;
  }

  .pt-lg-9 {
    padding-top: 4rem !important;
  }

  .pt-lg-10 {
    padding-top: 4.5rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 2rem !important;
  }

  .pe-lg-6 {
    padding-right: 2.5rem !important;
  }

  .pe-lg-7 {
    padding-right: 3rem !important;
  }

  .pe-lg-8 {
    padding-right: 3.5rem !important;
  }

  .pe-lg-9 {
    padding-right: 4rem !important;
  }

  .pe-lg-10 {
    padding-right: 4.5rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 4.5rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 2rem !important;
  }

  .ps-lg-6 {
    padding-left: 2.5rem !important;
  }

  .ps-lg-7 {
    padding-left: 3rem !important;
  }

  .ps-lg-8 {
    padding-left: 3.5rem !important;
  }

  .ps-lg-9 {
    padding-left: 4rem !important;
  }

  .ps-lg-10 {
    padding-left: 4.5rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .content-space-t-lg-0 {
    padding-top: 0 !important;
  }

  .content-space-t-lg-1 {
    padding-top: 4rem !important;
  }

  .content-space-t-lg-2 {
    padding-top: 5rem !important;
  }

  .content-space-t-lg-3 {
    padding-top: 7.5rem !important;
  }

  .content-space-t-lg-4 {
    padding-top: 10rem !important;
  }

  .content-space-t-lg-auto {
    padding-top: auto !important;
  }

  .content-space-b-lg-0 {
    padding-bottom: 0 !important;
  }

  .content-space-b-lg-1 {
    padding-bottom: 4rem !important;
  }

  .content-space-b-lg-2 {
    padding-bottom: 5rem !important;
  }

  .content-space-b-lg-3 {
    padding-bottom: 7.5rem !important;
  }

  .content-space-b-lg-4 {
    padding-bottom: 10rem !important;
  }

  .content-space-b-lg-auto {
    padding-bottom: auto !important;
  }

  .content-space-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .content-space-lg-1 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .content-space-lg-2 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .content-space-lg-3 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .content-space-lg-4 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .content-space-lg-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .position-xl-static {
    position: static !important;
  }

  .position-xl-relative {
    position: relative !important;
  }

  .position-xl-absolute {
    position: absolute !important;
  }

  .position-xl-fixed {
    position: fixed !important;
  }

  .position-xl-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .top-xl-0 {
    top: 0 !important;
  }

  .top-xl-50 {
    top: 50% !important;
  }

  .top-xl-100 {
    top: 100% !important;
  }

  .bottom-xl-0 {
    bottom: 0 !important;
  }

  .bottom-xl-50 {
    bottom: 50% !important;
  }

  .bottom-xl-100 {
    bottom: 100% !important;
  }

  .start-xl-0 {
    left: 0 !important;
  }

  .start-xl-50 {
    left: 50% !important;
  }

  .start-xl-100 {
    left: 100% !important;
  }

  .end-xl-0 {
    right: 0 !important;
  }

  .end-xl-50 {
    right: 50% !important;
  }

  .end-xl-100 {
    right: 100% !important;
  }

  .translate-middle-xl {
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
  }

  .translate-middle-xl-x {
    -webkit-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
  }

  .translate-middle-xl-y {
    -webkit-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
  }

  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-auto {
    width: auto !important;
  }

  .w-xl-65 {
    width: 65% !important;
  }

  .w-xl-85 {
    width: 85% !important;
  }

  .vh-xl-100 {
    height: 100vh !important;
  }

  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 2rem !important;
  }

  .gap-xl-6 {
    gap: 2.5rem !important;
  }

  .gap-xl-7 {
    gap: 3rem !important;
  }

  .gap-xl-8 {
    gap: 3.5rem !important;
  }

  .gap-xl-9 {
    gap: 4rem !important;
  }

  .gap-xl-10 {
    gap: 4.5rem !important;
  }

  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }

  .order-xl-first {
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }

  .order-xl-0 {
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  .order-xl-1 {
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .order-xl-2 {
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .order-xl-3 {
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .order-xl-4 {
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .order-xl-5 {
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  .order-xl-last {
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 2rem !important;
  }

  .m-xl-6 {
    margin: 2.5rem !important;
  }

  .m-xl-7 {
    margin: 3rem !important;
  }

  .m-xl-8 {
    margin: 3.5rem !important;
  }

  .m-xl-9 {
    margin: 4rem !important;
  }

  .m-xl-10 {
    margin: 4.5rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xl-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 2rem !important;
  }

  .mt-xl-6 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-7 {
    margin-top: 3rem !important;
  }

  .mt-xl-8 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-9 {
    margin-top: 4rem !important;
  }

  .mt-xl-10 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 2rem !important;
  }

  .me-xl-6 {
    margin-right: 2.5rem !important;
  }

  .me-xl-7 {
    margin-right: 3rem !important;
  }

  .me-xl-8 {
    margin-right: 3.5rem !important;
  }

  .me-xl-9 {
    margin-right: 4rem !important;
  }

  .me-xl-10 {
    margin-right: 4.5rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 2rem !important;
  }

  .ms-xl-6 {
    margin-left: 2.5rem !important;
  }

  .ms-xl-7 {
    margin-left: 3rem !important;
  }

  .ms-xl-8 {
    margin-left: 3.5rem !important;
  }

  .ms-xl-9 {
    margin-left: 4rem !important;
  }

  .ms-xl-10 {
    margin-left: 4.5rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 2rem !important;
  }

  .p-xl-6 {
    padding: 2.5rem !important;
  }

  .p-xl-7 {
    padding: 3rem !important;
  }

  .p-xl-8 {
    padding: 3.5rem !important;
  }

  .p-xl-9 {
    padding: 4rem !important;
  }

  .p-xl-10 {
    padding: 4.5rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xl-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 2rem !important;
  }

  .pt-xl-6 {
    padding-top: 2.5rem !important;
  }

  .pt-xl-7 {
    padding-top: 3rem !important;
  }

  .pt-xl-8 {
    padding-top: 3.5rem !important;
  }

  .pt-xl-9 {
    padding-top: 4rem !important;
  }

  .pt-xl-10 {
    padding-top: 4.5rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 2rem !important;
  }

  .pe-xl-6 {
    padding-right: 2.5rem !important;
  }

  .pe-xl-7 {
    padding-right: 3rem !important;
  }

  .pe-xl-8 {
    padding-right: 3.5rem !important;
  }

  .pe-xl-9 {
    padding-right: 4rem !important;
  }

  .pe-xl-10 {
    padding-right: 4.5rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 4.5rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 2rem !important;
  }

  .ps-xl-6 {
    padding-left: 2.5rem !important;
  }

  .ps-xl-7 {
    padding-left: 3rem !important;
  }

  .ps-xl-8 {
    padding-left: 3.5rem !important;
  }

  .ps-xl-9 {
    padding-left: 4rem !important;
  }

  .ps-xl-10 {
    padding-left: 4.5rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .content-space-t-xl-0 {
    padding-top: 0 !important;
  }

  .content-space-t-xl-1 {
    padding-top: 4rem !important;
  }

  .content-space-t-xl-2 {
    padding-top: 5rem !important;
  }

  .content-space-t-xl-3 {
    padding-top: 7.5rem !important;
  }

  .content-space-t-xl-4 {
    padding-top: 10rem !important;
  }

  .content-space-t-xl-auto {
    padding-top: auto !important;
  }

  .content-space-b-xl-0 {
    padding-bottom: 0 !important;
  }

  .content-space-b-xl-1 {
    padding-bottom: 4rem !important;
  }

  .content-space-b-xl-2 {
    padding-bottom: 5rem !important;
  }

  .content-space-b-xl-3 {
    padding-bottom: 7.5rem !important;
  }

  .content-space-b-xl-4 {
    padding-bottom: 10rem !important;
  }

  .content-space-b-xl-auto {
    padding-bottom: auto !important;
  }

  .content-space-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .content-space-xl-1 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .content-space-xl-2 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .content-space-xl-3 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .content-space-xl-4 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .content-space-xl-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .position-xxl-static {
    position: static !important;
  }

  .position-xxl-relative {
    position: relative !important;
  }

  .position-xxl-absolute {
    position: absolute !important;
  }

  .position-xxl-fixed {
    position: fixed !important;
  }

  .position-xxl-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .top-xxl-0 {
    top: 0 !important;
  }

  .top-xxl-50 {
    top: 50% !important;
  }

  .top-xxl-100 {
    top: 100% !important;
  }

  .bottom-xxl-0 {
    bottom: 0 !important;
  }

  .bottom-xxl-50 {
    bottom: 50% !important;
  }

  .bottom-xxl-100 {
    bottom: 100% !important;
  }

  .start-xxl-0 {
    left: 0 !important;
  }

  .start-xxl-50 {
    left: 50% !important;
  }

  .start-xxl-100 {
    left: 100% !important;
  }

  .end-xxl-0 {
    right: 0 !important;
  }

  .end-xxl-50 {
    right: 50% !important;
  }

  .end-xxl-100 {
    right: 100% !important;
  }

  .translate-middle-xxl {
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
  }

  .translate-middle-xxl-x {
    -webkit-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
  }

  .translate-middle-xxl-y {
    -webkit-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
  }

  .w-xxl-25 {
    width: 25% !important;
  }

  .w-xxl-50 {
    width: 50% !important;
  }

  .w-xxl-75 {
    width: 75% !important;
  }

  .w-xxl-100 {
    width: 100% !important;
  }

  .w-xxl-auto {
    width: auto !important;
  }

  .w-xxl-65 {
    width: 65% !important;
  }

  .w-xxl-85 {
    width: 85% !important;
  }

  .vh-xxl-100 {
    height: 100vh !important;
  }

  .flex-xxl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xxl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 2rem !important;
  }

  .gap-xxl-6 {
    gap: 2.5rem !important;
  }

  .gap-xxl-7 {
    gap: 3rem !important;
  }

  .gap-xxl-8 {
    gap: 3.5rem !important;
  }

  .gap-xxl-9 {
    gap: 4rem !important;
  }

  .gap-xxl-10 {
    gap: 4.5rem !important;
  }

  .justify-content-xxl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-xxl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xxl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }

  .order-xxl-first {
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }

  .order-xxl-0 {
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  .order-xxl-1 {
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .order-xxl-2 {
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .order-xxl-3 {
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .order-xxl-4 {
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .order-xxl-5 {
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  .order-xxl-last {
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 2rem !important;
  }

  .m-xxl-6 {
    margin: 2.5rem !important;
  }

  .m-xxl-7 {
    margin: 3rem !important;
  }

  .m-xxl-8 {
    margin: 3.5rem !important;
  }

  .m-xxl-9 {
    margin: 4rem !important;
  }

  .m-xxl-10 {
    margin: 4.5rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xxl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xxl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xxl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xxl-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xxl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xxl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 2rem !important;
  }

  .mt-xxl-6 {
    margin-top: 2.5rem !important;
  }

  .mt-xxl-7 {
    margin-top: 3rem !important;
  }

  .mt-xxl-8 {
    margin-top: 3.5rem !important;
  }

  .mt-xxl-9 {
    margin-top: 4rem !important;
  }

  .mt-xxl-10 {
    margin-top: 4.5rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 2rem !important;
  }

  .me-xxl-6 {
    margin-right: 2.5rem !important;
  }

  .me-xxl-7 {
    margin-right: 3rem !important;
  }

  .me-xxl-8 {
    margin-right: 3.5rem !important;
  }

  .me-xxl-9 {
    margin-right: 4rem !important;
  }

  .me-xxl-10 {
    margin-right: 4.5rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 2rem !important;
  }

  .ms-xxl-6 {
    margin-left: 2.5rem !important;
  }

  .ms-xxl-7 {
    margin-left: 3rem !important;
  }

  .ms-xxl-8 {
    margin-left: 3.5rem !important;
  }

  .ms-xxl-9 {
    margin-left: 4rem !important;
  }

  .ms-xxl-10 {
    margin-left: 4.5rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 2rem !important;
  }

  .p-xxl-6 {
    padding: 2.5rem !important;
  }

  .p-xxl-7 {
    padding: 3rem !important;
  }

  .p-xxl-8 {
    padding: 3.5rem !important;
  }

  .p-xxl-9 {
    padding: 4rem !important;
  }

  .p-xxl-10 {
    padding: 4.5rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xxl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xxl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xxl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xxl-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xxl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xxl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 2rem !important;
  }

  .pt-xxl-6 {
    padding-top: 2.5rem !important;
  }

  .pt-xxl-7 {
    padding-top: 3rem !important;
  }

  .pt-xxl-8 {
    padding-top: 3.5rem !important;
  }

  .pt-xxl-9 {
    padding-top: 4rem !important;
  }

  .pt-xxl-10 {
    padding-top: 4.5rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 2rem !important;
  }

  .pe-xxl-6 {
    padding-right: 2.5rem !important;
  }

  .pe-xxl-7 {
    padding-right: 3rem !important;
  }

  .pe-xxl-8 {
    padding-right: 3.5rem !important;
  }

  .pe-xxl-9 {
    padding-right: 4rem !important;
  }

  .pe-xxl-10 {
    padding-right: 4.5rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 4.5rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 2rem !important;
  }

  .ps-xxl-6 {
    padding-left: 2.5rem !important;
  }

  .ps-xxl-7 {
    padding-left: 3rem !important;
  }

  .ps-xxl-8 {
    padding-left: 3.5rem !important;
  }

  .ps-xxl-9 {
    padding-left: 4rem !important;
  }

  .ps-xxl-10 {
    padding-left: 4.5rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }

  .content-space-t-xxl-0 {
    padding-top: 0 !important;
  }

  .content-space-t-xxl-1 {
    padding-top: 4rem !important;
  }

  .content-space-t-xxl-2 {
    padding-top: 5rem !important;
  }

  .content-space-t-xxl-3 {
    padding-top: 7.5rem !important;
  }

  .content-space-t-xxl-4 {
    padding-top: 10rem !important;
  }

  .content-space-t-xxl-auto {
    padding-top: auto !important;
  }

  .content-space-b-xxl-0 {
    padding-bottom: 0 !important;
  }

  .content-space-b-xxl-1 {
    padding-bottom: 4rem !important;
  }

  .content-space-b-xxl-2 {
    padding-bottom: 5rem !important;
  }

  .content-space-b-xxl-3 {
    padding-bottom: 7.5rem !important;
  }

  .content-space-b-xxl-4 {
    padding-bottom: 10rem !important;
  }

  .content-space-b-xxl-auto {
    padding-bottom: auto !important;
  }

  .content-space-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .content-space-xxl-1 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .content-space-xxl-2 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .content-space-xxl-3 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .content-space-xxl-4 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .content-space-xxl-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 3.5rem !important;
  }

  .fs-2 {
    font-size: 3rem !important;
  }

  .fs-3 {
    font-size: 2.125rem !important;
  }

  .fs-4 {
    font-size: 1.625rem !important;
  }

  .fs-5 {
    font-size: 1.375rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

/*----------------------------------------------------------------------
  * Space - Multipurpose Responsive Template version v1.0
  * Copyright 2021 Htmlstream
  * Licensed under Bootstrap Themes (https://themes.getbootstrap.com/licenses/)
------------------------------------------------------------------------*/
/*------------------------------------
  Default Styles
------------------------------------*/

a {
  text-decoration: none;
}

:focus,
a:focus,
button:focus {
  outline-color: rgba(0, 128, 96, 0.5);
}

figure {
  margin-bottom: 0;
}

/* Chrome, Safari, Edge, Opera */

input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
}

/* Firefox */

/* Highlight Color */

::-moz-selection,
::selection {
  color: #321844;
  background-color: rgba(0, 128, 96, 0.1);
}

.bg-primary {
  ::-moz-selection,
  ::selection {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
  }
}

/*------------------------------------
  Animation
------------------------------------*/

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

/*------------------------------------
  Accordion
------------------------------------*/

.accordion-button {
  &:focus {
    box-shadow: none;
  }

  color: #321844;
  font-size: 1.125rem;
  font-weight: 500;
  padding: 1.5rem 2rem;
}

@media (max-width: 575.98px) {
  .accordion-button {
    padding: 1rem 1.33333rem;
  }
}

@media (max-width: 575.98px) {
  .accordion-button,
  .accordion-body {
    padding: 1rem 1.33333rem;
  }
}

.accordion-collapse {
  background-color: #32184417;
}

.accordion-flush {
  .accordion-item {
    border-width: 0;

    &:last-of-type .accordion-collapse {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .accordion-button:not(.collapsed) {
    box-shadow: none;
  }

  .accordion-body {
    padding-top: 0;
  }
}

/*------------------------------------
  Alert Styles
------------------------------------*/

.alert-primary {
  color: #fff;
  background-color: #321844;
  border-color: #321844;

  .alert-link {
    color: #fff;
  }
}

.alert-secondary {
  color: #fff;
  background-color: #51596c;
  border-color: #51596c;

  .alert-link {
    color: #fff;
  }
}

.alert-success {
  color: #fff;
  background-color: #077c76;
  border-color: #077c76;

  .alert-link {
    color: #fff;
  }
}

.alert-info {
  color: #fff;
  background-color: #334ac0;
  border-color: #334ac0;

  .alert-link {
    color: #fff;
  }
}

.alert-warning {
  color: #000;
  background-color: #f1b980;
  border-color: #f1b980;

  .alert-link {
    color: #000;
  }
}

.alert-danger {
  color: #fff;
  background-color: #692340;
  border-color: #692340;

  .alert-link {
    color: #fff;
  }
}

.alert-light {
  color: #000;
  background-color: #f9fafc;
  border-color: #f9fafc;

  .alert-link {
    color: #000;
  }
}

.alert-dark {
  color: #fff;
  background-color: #321844;
  border-color: #321844;

  .alert-link {
    color: #fff;
  }
}

.alert-soft-primary {
  color: #321844;
  background-color: rgba(0, 128, 96, 0.15);

  .alert-link {
    color: #321844;

    &:hover {
      color: #321844;
    }
  }
}

.alert-soft-secondary {
  color: #51596c;
  background-color: rgba(81, 89, 108, 0.15);

  .alert-link {
    color: #51596c;

    &:hover {
      color: #424858;
    }
  }
}

.alert-soft-success {
  color: #077c76;
  background-color: rgba(7, 124, 118, 0.15);

  .alert-link {
    color: #077c76;

    &:hover {
      color: #055a56;
    }
  }
}

.alert-soft-info {
  color: #334ac0;
  background-color: rgba(51, 74, 192, 0.15);

  .alert-link {
    color: #334ac0;

    &:hover {
      color: #2c3fa4;
    }
  }
}

.alert-soft-warning {
  color: #f1b980;
  background-color: rgba(241, 185, 128, 0.15);

  .alert-link {
    color: #f1b980;

    &:hover {
      color: #eda760;
    }
  }
}

.alert-soft-danger {
  color: #692340;
  background-color: rgba(105, 35, 64, 0.15);

  .alert-link {
    color: #692340;

    &:hover {
      color: #4e1a30;
    }
  }
}

.alert-soft-light {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.15);

  .alert-link {
    color: #f9fafc;

    &:hover {
      color: #e1e6f0;
    }
  }
}

.alert-soft-dark {
  color: #321844;
  background-color: rgba(0, 34, 28, 0.15);

  .alert-link {
    color: #321844;

    &:hover {
      color: black;
    }
  }
}

/*------------------------------------
  Avatar
------------------------------------*/

.avatar {
  position: relative;
  display: inline-block;
  width: 2.875rem;
  height: 2.875rem;
  border-radius: 0.3125rem;

  &:not(img) {
    background-color: #fff;
  }
}

.avatar-img {
  max-width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 0.3125rem;
}

.avatar-initials {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  font-weight: 500;
  pointer-events: none;
  text-transform: uppercase;
  border-radius: 0.3125rem;
}

.avatar-circle {
  border-radius: 50%;

  .avatar-initials,
  .avatar-img {
    border-radius: 50%;
  }
}

.avatar-centered {
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
}

/*------------------------------------
  Avatar Group
------------------------------------*/

.avatar-group {
  display: -ms-flexbox;
  display: flex;

  .avatar:hover {
    z-index: 2;
  }

  .avatar-circle .avatar-initials {
    border-radius: 50%;
  }

  .avatar-xs {
    .avatar-img,
    .avatar-initials {
      border: 2px solid #fff;
    }
  }

  .avatar-sm {
    .avatar-img,
    .avatar-initials {
      border: 2px solid #fff;
    }
  }

  .avatar {
    .avatar-img,
    .avatar-initials {
      border: 2px solid #fff;
    }
  }

  .avatar-lg {
    .avatar-img,
    .avatar-initials {
      border: 5px solid #fff;
    }
  }

  .avatar-xl {
    .avatar-img,
    .avatar-initials {
      border: 7px solid #fff;
    }
  }

  .avatar + .avatar {
    margin-left: -1rem;
  }

  .avatar-xs + .avatar-xs {
    margin-left: -0.6125rem;
  }

  .avatar-sm + .avatar-sm {
    margin-left: -0.875rem;
  }

  .avatar-lg + .avatar-lg {
    margin-left: -1.5rem;
  }

  .avatar-xl + .avatar-xl {
    margin-left: -2rem;
  }

  .avatar {
    width: 2.875rem;
    height: 2.875rem;

    .avatar-initials {
      font-size: 1rem;
    }

    + .avatar {
      margin-left: -1rem;
    }
  }
}

/*------------------------------------
  Avatar Group Sizes
------------------------------------*/

.avatar-group-xs .avatar {
  width: 1.75rem;
  height: 1.75rem;

  .avatar-initials {
    font-size: 0.75rem;
  }

  + .avatar {
    margin-left: -0.6125rem;
  }
}

.avatar-group-sm .avatar {
  width: 2.3125rem;
  height: 2.3125rem;

  .avatar-initials {
    font-size: 0.8125rem;
  }

  + .avatar {
    margin-left: -0.875rem;
  }
}

.avatar-group-lg .avatar {
  width: 4.25rem;
  height: 4.25rem;

  .avatar-initials {
    font-size: 1.125rem;
  }

  + .avatar {
    margin-left: -1.5rem;
  }
}

.avatar-group-xl .avatar {
  width: 5.625rem;
  height: 5.625rem;

  .avatar-initials {
    font-size: 2rem;
  }

  + .avatar {
    margin-left: -1.5rem;
  }
}

.avatar-group-xxl .avatar {
  width: 7rem;
  height: 7rem;

  .avatar-initials {
    font-size: 3rem;
  }

  + .avatar {
    margin-left: -2rem;
  }
}

/*------------------------------------
  Avatar Sizes
------------------------------------*/

.avatar.avatar-circle {
  .avatar-status {
    bottom: -0.21875rem;
    right: -0.21875rem;
  }

  .avatar-sm-status {
    bottom: 0;
    right: 0;
  }

  .avatar-lg-status {
    bottom: -0.325rem;
    right: -0.325rem;
  }
}

.avatar-xss {
  width: 1rem;
  height: 1rem;

  .avatar-initials {
    font-size: 0.75rem;
  }
}

.avatar-xs {
  width: 1.75rem;
  height: 1.75rem;

  .avatar-initials {
    font-size: 0.75rem;
  }

  &.avatar-circle {
    .avatar-status {
      bottom: -0.21875rem;
      right: -0.21875rem;
    }

    .avatar-sm-status {
      bottom: -0.11719rem;
      right: -0.11719rem;
    }

    .avatar-lg-status {
      bottom: -0.325rem;
      right: -0.325rem;
    }
  }

  width: 1.75rem;
  height: 1.75rem;

  .avatar-initials {
    font-size: 0.75rem;
  }

  &.avatar-circle {
    .avatar-status {
      bottom: -0.21875rem;
      right: -0.21875rem;
    }

    .avatar-sm-status {
      bottom: -0.11719rem;
      right: -0.11719rem;
    }

    .avatar-lg-status {
      bottom: -0.325rem;
      right: -0.325rem;
    }
  }
}

.avatar-sm {
  width: 2.3125rem;
  height: 2.3125rem;

  .avatar-initials {
    font-size: 0.8125rem;
  }

  &.avatar-circle {
    .avatar-status {
      bottom: -0.21875rem;
      right: -0.21875rem;
    }

    .avatar-sm-status {
      bottom: -0.09375rem;
      right: -0.09375rem;
    }

    .avatar-lg-status {
      bottom: -0.40625rem;
      right: -0.40625rem;
    }
  }
}

.avatar-lg {
  width: 4.25rem;
  height: 4.25rem;

  .avatar-initials {
    font-size: 1.125rem;
  }

  &.avatar-circle {
    .avatar-status {
      bottom: -0.13125rem;
      right: -0.13125rem;
    }

    .avatar-sm-status {
      bottom: 0.09375rem;
      right: 0.09375rem;
    }

    .avatar-lg-status {
      bottom: -0.20312rem;
      right: -0.20312rem;
    }
  }
}

.avatar-xl {
  width: 5.625rem;
  height: 5.625rem;

  .avatar-initials {
    font-size: 2rem;
  }

  &.avatar-circle {
    .avatar-status {
      bottom: 0.16406rem;
      right: 0.16406rem;
    }

    .avatar-sm-status {
      bottom: 0.23438rem;
      right: 0.23438rem;
    }

    .avatar-lg-status {
      bottom: 0.13542rem;
      right: 0.13542rem;
    }
  }
}

.avatar-xxl {
  width: 7rem;
  height: 7rem;

  .avatar-initials {
    font-size: 3rem;
  }

  &.avatar-circle {
    .avatar-status {
      bottom: 0.4375rem;
      right: 0.4375rem;
    }

    .avatar-sm-status {
      bottom: 0.58333rem;
      right: 0.58333rem;
    }

    .avatar-lg-status {
      bottom: 0.29545rem;
      right: 0.29545rem;
    }
  }
}

/*------------------------------------
  Avatar Status
------------------------------------*/

.avatar-status {
  position: absolute;
  bottom: -0.525rem;
  right: -0.525rem;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  text-align: center;
  vertical-align: baseline;
  border: 0.125rem solid #fff;
  width: 1.3125rem;
  height: 1.3125rem;
  line-height: 1;
  font-size: 0.625rem;
  border-radius: 50%;
}

.avatar-sm-status {
  bottom: -0.2625rem;
  right: -0.2625rem;
  width: 0.9375rem;
  height: 0.9375rem;
  font-size: 0.5rem;
}

.avatar-lg-status {
  width: 1.625rem;
  height: 1.625rem;
  font-size: 0.75rem;
}

/*------------------------------------
  Avatar Styles
------------------------------------*/

.avatar-primary .avatar-initials,
.avatar-status-primary {
  color: #fff;
  background-color: #321844;
}

.avatar-secondary .avatar-initials,
.avatar-status-secondary {
  color: #fff;
  background-color: #51596c;
}

.avatar-success .avatar-initials,
.avatar-status-success {
  color: #fff;
  background-color: #077c76;
}

.avatar-info .avatar-initials,
.avatar-status-info {
  color: #fff;
  background-color: #334ac0;
}

.avatar-warning .avatar-initials,
.avatar-status-warning {
  color: #000;
  background-color: #f1b980;
}

.avatar-danger .avatar-initials,
.avatar-status-danger {
  color: #fff;
  background-color: #692340;
}

.avatar-light .avatar-initials,
.avatar-status-light {
  color: #000;
  background-color: #f9fafc;
}

.avatar-dark .avatar-initials,
.avatar-status-dark {
  color: #fff;
  background-color: #321844;
}

.avatar-soft-primary .avatar-initials,
.avatar-status-soft-primary {
  color: #321844;
  background-color: rgba(0, 128, 96, 0.1);
}

.avatar-soft-secondary .avatar-initials,
.avatar-status-soft-secondary {
  color: #51596c;
  background-color: rgba(81, 89, 108, 0.1);
}

.avatar-soft-success .avatar-initials,
.avatar-status-soft-success {
  color: #077c76;
  background-color: rgba(7, 124, 118, 0.1);
}

.avatar-soft-info .avatar-initials,
.avatar-status-soft-info {
  color: #334ac0;
  background-color: rgba(51, 74, 192, 0.1);
}

.avatar-soft-warning .avatar-initials,
.avatar-status-soft-warning {
  color: #f1b980;
  background-color: rgba(241, 185, 128, 0.1);
}

.avatar-soft-danger .avatar-initials,
.avatar-status-soft-danger {
  color: #692340;
  background-color: rgba(105, 35, 64, 0.1);
}

.avatar-soft-light .avatar-initials,
.avatar-status-soft-light {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
}

.avatar-soft-dark .avatar-initials,
.avatar-status-soft-dark {
  color: #321844;
  background-color: rgba(0, 34, 28, 0.1);
}

/*------------------------------------
  Avatar Ratio
------------------------------------*/

.avatar.avatar-4x3 {
  width: 3.83333rem;
  height: auto;
  border-radius: 0;

  .avatar-img {
    height: inherit;
  }
}

.avatar-xss.avatar-4x3 {
  width: 1.33333rem;
  height: auto;
  border-radius: 0;

  .avatar-img {
    height: inherit;
  }
}

.avatar-xs.avatar-4x3 {
  width: 2.33333rem;
  height: auto;
  border-radius: 0;

  .avatar-img {
    height: inherit;
  }
}

.avatar-sm.avatar-4x3 {
  width: 3.08333rem;
  height: auto;
  border-radius: 0;

  .avatar-img {
    height: inherit;
  }
}

.avatar-lg.avatar-4x3 {
  width: 5.66667rem;
  height: auto;
  border-radius: 0;

  .avatar-img {
    height: inherit;
  }
}

.avatar-xl.avatar-4x3 {
  width: 7.5rem;
  height: auto;
  border-radius: 0;

  .avatar-img {
    height: inherit;
  }
}

.avatar-xxl.avatar-4x3 {
  width: 9.33333rem;
  height: auto;
  border-radius: 0;

  .avatar-img {
    height: inherit;
  }
}

/*------------------------------------
  Badge
------------------------------------*/

.badge {
  line-height: normal;
}

/*------------------------------------
  Banner
------------------------------------*/

.banner-half-start {
  position: relative;

  &::before {
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    width: 100vw;
    height: 150vh;
    z-index: -1;
    content: "";
    background-color: #035a4b;
  }

  &::after {
    position: absolute;
    top: auto;
    right: -0.75rem;
    bottom: 0;
    display: none;
    width: 100vw;
    height: 150vh;
    z-index: -1;
    content: "";
    background-color: #035a4b;
  }
}

@media (max-width: 767.98px) {
  .banner-half-start::after {
    display: block;
  }
}

.banner-half-end {
  > * {
    position: relative;
    z-index: 1;
  }

  &::after {
    position: absolute;
    top: 7.5%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: calc(100% - 15%);
    display: block;
    content: "";
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 53%,
      rgba(0, 189, 142, 0.1) 53%
    );
  }
}

@media (max-width: 767.98px) {
  .banner-half-end {
    position: relative;

    &::after {
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 189, 142, 0.1) 0%
      );
    }
  }
}

.banner-start {
  position: relative;

  > * {
    position: relative;
    z-index: 1;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0) 22%,
      rgba(0, 189, 142, 0.1) 22%
    );
    content: "";
  }
}

@media (max-width: 767.98px) {
  .banner-start::after {
    height: 80%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 189, 142, 0.1) 0%
    );
  }
}

.banner-end {
  position: relative;

  > * {
    position: relative;
    z-index: 1;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 22%,
      rgba(0, 189, 142, 0.1) 22%
    );
    content: "";
  }
}

@media (max-width: 767.98px) {
  .banner-end::after {
    height: 80%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 189, 142, 0.1) 0%
    );
  }
}

.banner-bottom-end-n3rem {
  position: relative;

  > * {
    position: relative;
    z-index: 1;
  }

  &::after {
    position: absolute;
    bottom: -3rem;
    left: -3rem;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 189, 142, 0.1);
    content: "";
  }
}

/*------------------------------------
  Blockquote
------------------------------------*/

.blockquote {
  color: #321844;
}

.blockquote-footer {
  font-weight: 500;
  line-height: normal;
  margin-top: 1.5rem;
  margin-bottom: 0;

  &::before {
    content: "";
  }
}

.blockquote-footer-source {
  display: block;
  color: #677788;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0.25rem;
}

.blockquote-sm {
  font-size: 1.25rem;
}

/*------------------------------------
  Breadcrumb
------------------------------------*/

.breadcrumb .breadcrumb-item {
  color: #321844;
}

/*------------------------------------
  Buttons
------------------------------------*/

.btn-link:focus {
  box-shadow: none;
}

.btn.dropdown-toggle {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
}

.btn-check:focus + .btn-primary {
  box-shadow: 0 4px 11px rgba(0, 128, 96, 0.35);
}

.btn-primary {
  &:hover,
  &:focus {
    box-shadow: 0 4px 11px rgba(0, 128, 96, 0.35);
  }
}

.btn-check:focus + .btn-secondary {
  box-shadow: 0 4px 11px rgba(81, 89, 108, 0.35);
}

.btn-secondary {
  &:hover,
  &:focus {
    box-shadow: 0 4px 11px rgba(81, 89, 108, 0.35);
  }
}

.btn-check:focus + .btn-success {
  box-shadow: 0 4px 11px rgba(7, 124, 118, 0.35);
}

.btn-success {
  &:hover,
  &:focus {
    box-shadow: 0 4px 11px rgba(7, 124, 118, 0.35);
  }
}

.btn-check:focus + .btn-info {
  box-shadow: 0 4px 11px rgba(51, 74, 192, 0.35);
}

.btn-info {
  &:hover,
  &:focus {
    box-shadow: 0 4px 11px rgba(51, 74, 192, 0.35);
  }
}

.btn-check:focus + .btn-warning {
  box-shadow: 0 4px 11px rgba(241, 185, 128, 0.35);
}

.btn-warning {
  &:hover,
  &:focus {
    box-shadow: 0 4px 11px rgba(241, 185, 128, 0.35);
  }
}

.btn-check:focus + .btn-danger {
  box-shadow: 0 4px 11px rgba(105, 35, 64, 0.35);
}

.btn-danger {
  &:hover,
  &:focus {
    box-shadow: 0 4px 11px rgba(105, 35, 64, 0.35);
  }
}

.btn-check:focus + .btn-light {
  box-shadow: 0 4px 11px rgba(249, 250, 252, 0.35);
}

.btn-light {
  &:hover,
  &:focus {
    box-shadow: 0 4px 11px rgba(249, 250, 252, 0.35);
  }
}

.btn-check:focus + .btn-dark {
  box-shadow: 0 4px 11px rgba(0, 34, 28, 0.35);
}

.btn-dark {
  &:hover,
  &:focus {
    box-shadow: 0 4px 11px rgba(0, 34, 28, 0.35);
  }
}

/*------------------------------------
  Custom Buttons
------------------------------------*/

.btn-white {
  background-color: #fff;
  border-color: rgba(0, 34, 28, 0.1);

  &.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2351596C' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: all 0.2s ease-in-out;
  }
}

.btn-check:focus + .btn-white {
  color: #321844;
  box-shadow: 0px 3px 6px -2px rgba(140, 152, 164, 0.25);
}

.btn-white {
  &:hover,
  &:focus {
    color: #321844;
    box-shadow: 0px 3px 6px -2px rgba(140, 152, 164, 0.25);
  }
}

.btn-check:focus + .btn-white.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23008060' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-white {
  &:hover.dropdown-toggle::after,
  &:focus.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23008060' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }

  &.disabled,
  &:disabled {
    color: #bdc5d1;
    background-color: rgba(231, 234, 243, 0.5);
  }
}

.btn-primary.dropdown-toggle::after,
.btn-secondary.dropdown-toggle::after,
.btn-success.dropdown-toggle::after,
.btn-info.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-warning.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-danger.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-light.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-dark.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

/*------------------------------------
  Ghost Buttons Styles
------------------------------------*/

.btn-ghost-primary {
  color: #321844;
  background-color: transparent;

  &:hover {
    color: #321844;
    background-color: rgba(0, 128, 96, 0.1);
  }
}

.btn-check:focus + .btn-ghost-primary,
.btn-ghost-primary:focus {
  color: #321844;
  background-color: rgba(0, 128, 96, 0.1);
}

.btn-check {
  &:checked + .btn-ghost-primary,
  &:active + .btn-ghost-primary {
    color: #321844;
    background-color: rgba(0, 128, 96, 0.1);
  }
}

.btn-ghost-primary {
  &:active,
  &.active {
    color: #321844;
    background-color: rgba(0, 128, 96, 0.1);
  }
}

.show > .btn-ghost-primary.dropdown-toggle {
  color: #321844;
  background-color: rgba(0, 128, 96, 0.1);
}

.btn-ghost-primary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23008060' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-ghost-primary.btn-no-focus {
  color: #321844;
  background-color: unset;
  box-shadow: none;
}

.btn-ghost-primary.btn-no-focus {
  &:hover,
  &.focus,
  &:focus {
    color: #321844;
    background-color: unset;
    box-shadow: none;
  }
}

.btn-check:focus + .btn-ghost-primary.btn-no-focus:hover {
  color: #321844;
  background-color: rgba(0, 128, 96, 0.1);
}

.btn-ghost-primary.btn-no-focus {
  &:hover:hover,
  &.focus:hover,
  &:focus:hover {
    color: #321844;
    background-color: rgba(0, 128, 96, 0.1);
  }
}

.btn-ghost-secondary {
  color: #51596c;
  background-color: transparent;

  &:hover {
    color: #51596c;
    background-color: rgba(0, 128, 96, 0.1);
  }
}

.btn-check:focus + .btn-ghost-secondary,
.btn-ghost-secondary:focus {
  color: #51596c;
  background-color: rgba(0, 128, 96, 0.1);
}

.btn-check {
  &:checked + .btn-ghost-secondary,
  &:active + .btn-ghost-secondary {
    color: #51596c;
    background-color: rgba(0, 128, 96, 0.1);
  }
}

.btn-ghost-secondary {
  &:active,
  &.active {
    color: #51596c;
    background-color: rgba(0, 128, 96, 0.1);
  }
}

.show > .btn-ghost-secondary.dropdown-toggle {
  color: #51596c;
  background-color: rgba(0, 128, 96, 0.1);
}

.btn-ghost-secondary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2351596C' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-ghost-secondary.btn-no-focus {
  color: #51596c;
  background-color: unset;
  box-shadow: none;
}

.btn-ghost-secondary.btn-no-focus {
  &:hover,
  &.focus,
  &:focus {
    color: #51596c;
    background-color: unset;
    box-shadow: none;
  }
}

.btn-check:focus + .btn-ghost-secondary.btn-no-focus:hover {
  color: #51596c;
  background-color: rgba(81, 89, 108, 0.1);
}

.btn-ghost-secondary.btn-no-focus {
  &:hover:hover,
  &.focus:hover,
  &:focus:hover {
    color: #51596c;
    background-color: rgba(81, 89, 108, 0.1);
  }
}

.btn-ghost-success {
  color: #077c76;
  background-color: transparent;

  &:hover {
    color: #077c76;
    background-color: rgba(7, 124, 118, 0.1);
  }
}

.btn-check:focus + .btn-ghost-success,
.btn-ghost-success:focus {
  color: #077c76;
  background-color: rgba(7, 124, 118, 0.1);
}

.btn-check {
  &:checked + .btn-ghost-success,
  &:active + .btn-ghost-success {
    color: #077c76;
    background-color: rgba(7, 124, 118, 0.1);
  }
}

.btn-ghost-success {
  &:active,
  &.active {
    color: #077c76;
    background-color: rgba(7, 124, 118, 0.1);
  }
}

.show > .btn-ghost-success.dropdown-toggle {
  color: #077c76;
  background-color: rgba(7, 124, 118, 0.1);
}

.btn-ghost-success.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23077C76' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-ghost-success.btn-no-focus {
  color: #077c76;
  background-color: unset;
  box-shadow: none;
}

.btn-ghost-success.btn-no-focus {
  &:hover,
  &.focus,
  &:focus {
    color: #077c76;
    background-color: unset;
    box-shadow: none;
  }
}

.btn-check:focus + .btn-ghost-success.btn-no-focus:hover {
  color: #077c76;
  background-color: rgba(7, 124, 118, 0.1);
}

.btn-ghost-success.btn-no-focus {
  &:hover:hover,
  &.focus:hover,
  &:focus:hover {
    color: #077c76;
    background-color: rgba(7, 124, 118, 0.1);
  }
}

.btn-ghost-info {
  color: #334ac0;
  background-color: transparent;

  &:hover {
    color: #334ac0;
    background-color: rgba(51, 74, 192, 0.1);
  }
}

.btn-check:focus + .btn-ghost-info,
.btn-ghost-info:focus {
  color: #334ac0;
  background-color: rgba(51, 74, 192, 0.1);
}

.btn-check {
  &:checked + .btn-ghost-info,
  &:active + .btn-ghost-info {
    color: #334ac0;
    background-color: rgba(51, 74, 192, 0.1);
  }
}

.btn-ghost-info {
  &:active,
  &.active {
    color: #334ac0;
    background-color: rgba(51, 74, 192, 0.1);
  }
}

.show > .btn-ghost-info.dropdown-toggle {
  color: #334ac0;
  background-color: rgba(51, 74, 192, 0.1);
}

.btn-ghost-info.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23334AC0' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-ghost-info.btn-no-focus {
  color: #334ac0;
  background-color: unset;
  box-shadow: none;
}

.btn-ghost-info.btn-no-focus {
  &:hover,
  &.focus,
  &:focus {
    color: #334ac0;
    background-color: unset;
    box-shadow: none;
  }
}

.btn-check:focus + .btn-ghost-info.btn-no-focus:hover {
  color: #334ac0;
  background-color: rgba(51, 74, 192, 0.1);
}

.btn-ghost-info.btn-no-focus {
  &:hover:hover,
  &.focus:hover,
  &:focus:hover {
    color: #334ac0;
    background-color: rgba(51, 74, 192, 0.1);
  }
}

.btn-ghost-warning {
  color: #f1b980;
  background-color: transparent;

  &:hover {
    color: #f1b980;
    background-color: rgba(241, 185, 128, 0.1);
  }
}

.btn-check:focus + .btn-ghost-warning,
.btn-ghost-warning:focus {
  color: #f1b980;
  background-color: rgba(241, 185, 128, 0.1);
}

.btn-check {
  &:checked + .btn-ghost-warning,
  &:active + .btn-ghost-warning {
    color: #f1b980;
    background-color: rgba(241, 185, 128, 0.1);
  }
}

.btn-ghost-warning {
  &:active,
  &.active {
    color: #f1b980;
    background-color: rgba(241, 185, 128, 0.1);
  }
}

.show > .btn-ghost-warning.dropdown-toggle {
  color: #f1b980;
  background-color: rgba(241, 185, 128, 0.1);
}

.btn-ghost-warning.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23F1B980' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-ghost-warning.btn-no-focus {
  color: #f1b980;
  background-color: unset;
  box-shadow: none;
}

.btn-ghost-warning.btn-no-focus {
  &:hover,
  &.focus,
  &:focus {
    color: #f1b980;
    background-color: unset;
    box-shadow: none;
  }
}

.btn-check:focus + .btn-ghost-warning.btn-no-focus:hover {
  color: #f1b980;
  background-color: rgba(241, 185, 128, 0.1);
}

.btn-ghost-warning.btn-no-focus {
  &:hover:hover,
  &.focus:hover,
  &:focus:hover {
    color: #f1b980;
    background-color: rgba(241, 185, 128, 0.1);
  }
}

.btn-ghost-danger {
  color: #692340;
  background-color: transparent;

  &:hover {
    color: #692340;
    background-color: rgba(105, 35, 64, 0.1);
  }
}

.btn-check:focus + .btn-ghost-danger,
.btn-ghost-danger:focus {
  color: #692340;
  background-color: rgba(105, 35, 64, 0.1);
}

.btn-check {
  &:checked + .btn-ghost-danger,
  &:active + .btn-ghost-danger {
    color: #692340;
    background-color: rgba(105, 35, 64, 0.1);
  }
}

.btn-ghost-danger {
  &:active,
  &.active {
    color: #692340;
    background-color: rgba(105, 35, 64, 0.1);
  }
}

.show > .btn-ghost-danger.dropdown-toggle {
  color: #692340;
  background-color: rgba(105, 35, 64, 0.1);
}

.btn-ghost-danger.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23692340' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-ghost-danger.btn-no-focus {
  color: #692340;
  background-color: unset;
  box-shadow: none;
}

.btn-ghost-danger.btn-no-focus {
  &:hover,
  &.focus,
  &:focus {
    color: #692340;
    background-color: unset;
    box-shadow: none;
  }
}

.btn-check:focus + .btn-ghost-danger.btn-no-focus:hover {
  color: #692340;
  background-color: rgba(105, 35, 64, 0.1);
}

.btn-ghost-danger.btn-no-focus {
  &:hover:hover,
  &.focus:hover,
  &:focus:hover {
    color: #692340;
    background-color: rgba(105, 35, 64, 0.1);
  }
}

.btn-ghost-light {
  color: #f9fafc;
  background-color: transparent;

  &:hover {
    color: #f9fafc;
    background-color: rgba(249, 250, 252, 0.1);
  }
}

.btn-check:focus + .btn-ghost-light,
.btn-ghost-light:focus {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
}

.btn-check {
  &:checked + .btn-ghost-light,
  &:active + .btn-ghost-light {
    color: #f9fafc;
    background-color: rgba(249, 250, 252, 0.1);
  }
}

.btn-ghost-light {
  &:active,
  &.active {
    color: #f9fafc;
    background-color: rgba(249, 250, 252, 0.1);
  }
}

.show > .btn-ghost-light.dropdown-toggle {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
}

.btn-ghost-light.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23F9FAFC' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-ghost-light.btn-no-focus {
  color: #f9fafc;
  background-color: unset;
  box-shadow: none;
}

.btn-ghost-light.btn-no-focus {
  &:hover,
  &.focus,
  &:focus {
    color: #f9fafc;
    background-color: unset;
    box-shadow: none;
  }
}

.btn-check:focus + .btn-ghost-light.btn-no-focus:hover {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
}

.btn-ghost-light.btn-no-focus {
  &:hover:hover,
  &.focus:hover,
  &:focus:hover {
    color: #f9fafc;
    background-color: rgba(249, 250, 252, 0.1);
  }
}

.btn-ghost-dark {
  color: #321844;
  background-color: transparent;

  &:hover {
    color: #321844;
    background-color: rgba(0, 34, 28, 0.1);
  }
}

.btn-check:focus + .btn-ghost-dark,
.btn-ghost-dark:focus {
  color: #321844;
  background-color: rgba(0, 34, 28, 0.1);
}

.btn-check {
  &:checked + .btn-ghost-dark,
  &:active + .btn-ghost-dark {
    color: #321844;
    background-color: rgba(0, 34, 28, 0.1);
  }
}

.btn-ghost-dark {
  &:active,
  &.active {
    color: #321844;
    background-color: rgba(0, 34, 28, 0.1);
  }
}

.show > .btn-ghost-dark.dropdown-toggle {
  color: #321844;
  background-color: rgba(0, 34, 28, 0.1);
}

.btn-ghost-dark.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300221C' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-ghost-dark.btn-no-focus {
  color: #321844;
  background-color: unset;
  box-shadow: none;
}

.btn-ghost-dark.btn-no-focus {
  &:hover,
  &.focus,
  &:focus {
    color: #321844;
    background-color: unset;
    box-shadow: none;
  }
}

.btn-check:focus + .btn-ghost-dark.btn-no-focus:hover {
  color: #321844;
  background-color: rgba(0, 34, 28, 0.1);
}

.btn-ghost-dark.btn-no-focus {
  &:hover:hover,
  &.focus:hover,
  &:focus:hover {
    color: #321844;
    background-color: rgba(0, 34, 28, 0.1);
  }
}

.btn-check:focus + .btn-ghost-secondary {
  color: #321844;
}

.btn-ghost-secondary {
  &:hover,
  &:focus {
    color: #321844;
  }
}

.btn-check:focus + .btn-ghost-secondary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23008060' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-ghost-secondary {
  &:hover.dropdown-toggle::after,
  &:focus.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23008060' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-check:focus + .btn-ghost-secondary.btn-no-focus:hover {
  color: #321844;
  background-color: rgba(0, 128, 96, 0.1);
}

.btn-ghost-secondary.btn-no-focus {
  &:hover:hover,
  &.focus:hover,
  &:focus:hover {
    color: #321844;
    background-color: rgba(0, 128, 96, 0.1);
  }
}

/*------------------------------------
  Button Soft
------------------------------------*/

.btn-soft-primary {
  color: #321844;
  background-color: rgba(0, 128, 96, 0.1);
  border-color: transparent;

  &:hover {
    color: #fff;
    background-color: #321844;
  }
}

.btn-check:focus + .btn-soft-primary,
.btn-soft-primary:focus {
  color: #fff;
  background-color: #321844;
}

.btn-check {
  &:checked + .btn-soft-primary,
  &:active + .btn-soft-primary {
    color: #fff;
    background-color: #321844;
    border-color: transparent;
  }
}

.btn-soft-primary {
  &:active,
  &.active {
    color: #fff;
    background-color: #321844;
    border-color: transparent;
  }
}

.show > .btn-soft-primary.dropdown-toggle {
  color: #fff;
  background-color: #321844;
  border-color: transparent;
}

.btn-soft-primary {
  &:disabled,
  &.disabled {
    color: #321844;
    background-color: #321844;
    border-color: transparent;
  }
}

.btn-soft-secondary {
  color: #51596c;
  background-color: rgba(81, 89, 108, 0.1);
  border-color: transparent;

  &:hover {
    color: #fff;
    background-color: #51596c;
  }
}

.btn-check:focus + .btn-soft-secondary,
.btn-soft-secondary:focus {
  color: #fff;
  background-color: #51596c;
}

.btn-check {
  &:checked + .btn-soft-secondary,
  &:active + .btn-soft-secondary {
    color: #fff;
    background-color: #51596c;
    border-color: transparent;
  }
}

.btn-soft-secondary {
  &:active,
  &.active {
    color: #fff;
    background-color: #51596c;
    border-color: transparent;
  }
}

.show > .btn-soft-secondary.dropdown-toggle {
  color: #fff;
  background-color: #51596c;
  border-color: transparent;
}

.btn-soft-secondary {
  &:disabled,
  &.disabled {
    color: #51596c;
    background-color: #51596c;
    border-color: transparent;
  }
}

.btn-soft-success {
  color: #077c76;
  background-color: rgba(7, 124, 118, 0.1);
  border-color: transparent;

  &:hover {
    color: #fff;
    background-color: #077c76;
  }
}

.btn-check:focus + .btn-soft-success,
.btn-soft-success:focus {
  color: #fff;
  background-color: #077c76;
}

.btn-check {
  &:checked + .btn-soft-success,
  &:active + .btn-soft-success {
    color: #fff;
    background-color: #077c76;
    border-color: transparent;
  }
}

.btn-soft-success {
  &:active,
  &.active {
    color: #fff;
    background-color: #077c76;
    border-color: transparent;
  }
}

.show > .btn-soft-success.dropdown-toggle {
  color: #fff;
  background-color: #077c76;
  border-color: transparent;
}

.btn-soft-success {
  &:disabled,
  &.disabled {
    color: #077c76;
    background-color: #077c76;
    border-color: transparent;
  }
}

.btn-soft-info {
  color: #334ac0;
  background-color: rgba(51, 74, 192, 0.1);
  border-color: transparent;

  &:hover {
    color: #fff;
    background-color: #334ac0;
  }
}

.btn-check:focus + .btn-soft-info,
.btn-soft-info:focus {
  color: #fff;
  background-color: #334ac0;
}

.btn-check {
  &:checked + .btn-soft-info,
  &:active + .btn-soft-info {
    color: #fff;
    background-color: #334ac0;
    border-color: transparent;
  }
}

.btn-soft-info {
  &:active,
  &.active {
    color: #fff;
    background-color: #334ac0;
    border-color: transparent;
  }
}

.show > .btn-soft-info.dropdown-toggle {
  color: #fff;
  background-color: #334ac0;
  border-color: transparent;
}

.btn-soft-info {
  &:disabled,
  &.disabled {
    color: #334ac0;
    background-color: #334ac0;
    border-color: transparent;
  }
}

.btn-soft-warning {
  color: #f1b980;
  background-color: rgba(241, 185, 128, 0.1);
  border-color: transparent;

  &:hover {
    color: #000;
    background-color: #f1b980;
  }
}

.btn-check:focus + .btn-soft-warning,
.btn-soft-warning:focus {
  color: #000;
  background-color: #f1b980;
}

.btn-check {
  &:checked + .btn-soft-warning,
  &:active + .btn-soft-warning {
    color: #000;
    background-color: #f1b980;
    border-color: transparent;
  }
}

.btn-soft-warning {
  &:active,
  &.active {
    color: #000;
    background-color: #f1b980;
    border-color: transparent;
  }
}

.show > .btn-soft-warning.dropdown-toggle {
  color: #000;
  background-color: #f1b980;
  border-color: transparent;
}

.btn-soft-warning {
  &:disabled,
  &.disabled {
    color: #f1b980;
    background-color: #f1b980;
    border-color: transparent;
  }
}

.btn-soft-danger {
  color: #692340;
  background-color: rgba(105, 35, 64, 0.1);
  border-color: transparent;

  &:hover {
    color: #fff;
    background-color: #692340;
  }
}

.btn-check:focus + .btn-soft-danger,
.btn-soft-danger:focus {
  color: #fff;
  background-color: #692340;
}

.btn-check {
  &:checked + .btn-soft-danger,
  &:active + .btn-soft-danger {
    color: #fff;
    background-color: #692340;
    border-color: transparent;
  }
}

.btn-soft-danger {
  &:active,
  &.active {
    color: #fff;
    background-color: #692340;
    border-color: transparent;
  }
}

.show > .btn-soft-danger.dropdown-toggle {
  color: #fff;
  background-color: #692340;
  border-color: transparent;
}

.btn-soft-danger {
  &:disabled,
  &.disabled {
    color: #692340;
    background-color: #692340;
    border-color: transparent;
  }
}

.btn-soft-light {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
  border-color: transparent;

  &:hover {
    color: #000;
    background-color: #f9fafc;
  }
}

.btn-check:focus + .btn-soft-light,
.btn-soft-light:focus {
  color: #000;
  background-color: #f9fafc;
}

.btn-check {
  &:checked + .btn-soft-light,
  &:active + .btn-soft-light {
    color: #000;
    background-color: #f9fafc;
    border-color: transparent;
  }
}

.btn-soft-light {
  &:active,
  &.active {
    color: #000;
    background-color: #f9fafc;
    border-color: transparent;
  }
}

.show > .btn-soft-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafc;
  border-color: transparent;
}

.btn-soft-light {
  &:disabled,
  &.disabled {
    color: #f9fafc;
    background-color: #f9fafc;
    border-color: transparent;
  }
}

.btn-soft-dark {
  color: #321844;
  background-color: rgba(0, 34, 28, 0.1);
  border-color: transparent;

  &:hover {
    color: #fff;
    background-color: #321844;
  }
}

.btn-check:focus + .btn-soft-dark,
.btn-soft-dark:focus {
  color: #fff;
  background-color: #321844;
}

.btn-check {
  &:checked + .btn-soft-dark,
  &:active + .btn-soft-dark {
    color: #fff;
    background-color: #321844;
    border-color: transparent;
  }
}

.btn-soft-dark {
  &:active,
  &.active {
    color: #fff;
    background-color: #321844;
    border-color: transparent;
  }
}

.show > .btn-soft-dark.dropdown-toggle {
  color: #fff;
  background-color: #321844;
  border-color: transparent;
}

.btn-soft-dark {
  &:disabled,
  &.disabled {
    color: #321844;
    background-color: #321844;
    border-color: transparent;
  }
}

.btn-soft-primary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23008060' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-soft-primary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-primary {
  &:hover.dropdown-toggle::after,
  &:focus.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-soft-secondary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2351596C' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-soft-secondary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-secondary {
  &:hover.dropdown-toggle::after,
  &:focus.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-soft-success.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23077C76' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-soft-success.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-success {
  &:hover.dropdown-toggle::after,
  &:focus.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-soft-info.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23334AC0' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-soft-info.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-info {
  &:hover.dropdown-toggle::after,
  &:focus.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-soft-warning.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23F1B980' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-soft-warning.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-warning {
  &:hover.dropdown-toggle::after,
  &:focus.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-soft-danger.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23692340' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-soft-danger.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-danger {
  &:hover.dropdown-toggle::after,
  &:focus.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-soft-light.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23F9FAFC' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-soft-light.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-light {
  &:hover.dropdown-toggle::after,
  &:focus.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-soft-dark.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300221C' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-soft-dark.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-dark {
  &:hover.dropdown-toggle::after,
  &:focus.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-check:focus + .btn-soft-secondary {
  color: #fff;
}

.btn-soft-secondary {
  &:hover,
  &:focus {
    color: #fff;
  }
}

.btn-check:focus + .btn-soft-secondary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-secondary {
  &:hover.dropdown-toggle::after,
  &:focus.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

/*------------------------------------
  Button Icon
------------------------------------*/

.btn-icon {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  width: 2.875rem;
  height: 2.875rem;
  padding: 0;

  > svg {
    width: 1rem;
    height: auto;
  }
}

/*------------------------------------
  Outline Button Styles
------------------------------------*/

.btn-outline-primary {
  &.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23008060' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: all 0.2s ease-in-out;
  }

  &:hover::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-check {
  &:checked + .btn-outline-primary.dropdown-toggle::after,
  &:active + .btn-outline-primary.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-outline-primary {
  &:active.dropdown-toggle::after,
  &.active.dropdown-toggle::after,
  &.dropdown-toggle.show.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-outline-secondary {
  &.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2351596C' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: all 0.2s ease-in-out;
  }

  &:hover::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-check {
  &:checked + .btn-outline-secondary.dropdown-toggle::after,
  &:active + .btn-outline-secondary.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-outline-secondary {
  &:active.dropdown-toggle::after,
  &.active.dropdown-toggle::after,
  &.dropdown-toggle.show.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-outline-success {
  &.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23077C76' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: all 0.2s ease-in-out;
  }

  &:hover::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-check {
  &:checked + .btn-outline-success.dropdown-toggle::after,
  &:active + .btn-outline-success.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-outline-success {
  &:active.dropdown-toggle::after,
  &.active.dropdown-toggle::after,
  &.dropdown-toggle.show.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-outline-info {
  &.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23334AC0' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: all 0.2s ease-in-out;
  }

  &:hover::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-check {
  &:checked + .btn-outline-info.dropdown-toggle::after,
  &:active + .btn-outline-info.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-outline-info {
  &:active.dropdown-toggle::after,
  &.active.dropdown-toggle::after,
  &.dropdown-toggle.show.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-outline-warning {
  &.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23F1B980' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: all 0.2s ease-in-out;
  }

  &:hover::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-check {
  &:checked + .btn-outline-warning.dropdown-toggle::after,
  &:active + .btn-outline-warning.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-outline-warning {
  &:active.dropdown-toggle::after,
  &.active.dropdown-toggle::after,
  &.dropdown-toggle.show.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-outline-danger {
  &.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23692340' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: all 0.2s ease-in-out;
  }

  &:hover::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-check {
  &:checked + .btn-outline-danger.dropdown-toggle::after,
  &:active + .btn-outline-danger.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-outline-danger {
  &:active.dropdown-toggle::after,
  &.active.dropdown-toggle::after,
  &.dropdown-toggle.show.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-outline-light {
  &.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23F9FAFC' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: all 0.2s ease-in-out;
  }

  &:hover::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-check {
  &:checked + .btn-outline-light.dropdown-toggle::after,
  &:active + .btn-outline-light.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-outline-light {
  &:active.dropdown-toggle::after,
  &.active.dropdown-toggle::after,
  &.dropdown-toggle.show.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-outline-dark {
  &.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300221C' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: all 0.2s ease-in-out;
  }

  &:hover::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-check {
  &:checked + .btn-outline-dark.dropdown-toggle::after,
  &:active + .btn-outline-dark.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-outline-dark {
  &:active.dropdown-toggle::after,
  &.active.dropdown-toggle::after,
  &.dropdown-toggle.show.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  }
}

.btn-outline-primary,
.btn-outline-secondary {
  border-color: rgba(0, 34, 28, 0.1);
}

/*------------------------------------
  Button Sizes
------------------------------------*/

.btn-icon.btn-sm,
.btn-group-sm > .btn-icon.btn {
  font-size: 0.8125rem;
  width: 2.3125rem;
  height: 2.3125rem;
}

.btn-icon.btn-sm > svg,
.btn-group-sm > .btn-icon.btn > svg {
  width: 0.8125rem;
  height: auto;
}

.btn-icon.btn-lg,
.btn-group-lg > .btn-icon.btn {
  font-size: 1.125rem;
  width: 4.25rem;
  height: 4.25rem;
}

.btn-icon.btn-lg > svg,
.btn-group-lg > .btn-icon.btn > svg {
  width: 1.125rem;
  height: auto;
}

/*------------------------------------
  Card
------------------------------------*/

.card {
  border-width: 0;
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
}

.card-header,
.card-footer {
  border-width: 0;
}

.card-title {
  margin-bottom: 0;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.card-header-title {
  margin-bottom: 0;

  &:not(:last-child) {
    margin-bottom: 0.25rem;
  }
}

.card-link {
  font-weight: 500;
}

.card-bordered {
  border-width: 0.0625rem;
}

.card-transition {
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus {
    -webkit-transform: translateY(-0.1875rem) !important;
    transform: translateY(-0.1875rem) !important;
  }
}

.card-group .card {
  box-shadow: none;

  + .card {
    border-left: 0.0625rem solid rgba(0, 34, 28, 0.1);
  }
}

.card-xs {
  > {
    .card-header,
    .card-footer {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }

    .card-body,
    .collapse .card-body {
      padding: 0.5rem 0.5rem;
    }
  }

  .card-table > {
    tbody > tr {
      &:first-child > td {
        padding-top: 0.5rem;
      }

      &:last-child > td {
        padding-bottom: 0.5rem;
      }
    }

    :not(caption) > * > * {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }
}

.card-sm {
  > {
    .card-header,
    .card-footer {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    .card-body,
    .card-img-overlay,
    .collapse .card-body {
      padding: 1rem 1rem;
    }
  }

  .card-table > {
    tbody > tr {
      &:first-child > td {
        padding-top: 1rem;
      }

      &:last-child > td {
        padding-bottom: 1rem;
      }
    }

    :not(caption) > * > * {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
}

.card-lg {
  > {
    .card-header,
    .card-footer {
      padding-right: 2rem;
      padding-left: 2rem;
    }

    .card-body,
    .card-img-overlay,
    .collapse .card-body {
      padding: 2rem 2rem;
    }
  }

  .card-table > {
    tbody > tr {
      &:first-child > td {
        padding-top: 2rem;
      }

      &:last-child > td {
        padding-bottom: 2rem;
      }
    }

    :not(caption) > * > * {
      padding-right: 2rem;
      padding-left: 2rem;
    }
  }
}

.card-pinned {
  position: relative;
  display: block;
}

.card-pinned-top-start {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.card-pinned-top-end {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.card-pinned-bottom-start {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}

.card-pinned-bottom-end {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.card-flush {
  box-shadow: none;

  .card-header,
  .card-footer,
  .card-body,
  .collapse .card-body {
    padding-right: 0;
    padding-left: 0;
  }

  > .card-img-top {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }

  &.card-stretched-vertical .card-body .card-footer {
    padding-bottom: 0;
  }
}

.card {
  .table {
    margin-bottom: 0;
  }

  table > {
    tbody > tr {
      &:first-child > td {
        padding-top: 1.25rem;
      }

      &:last-child > td {
        padding-bottom: 1.25rem;
      }
    }

    :not(caption) > * > * {
      padding-right: 1.25rem;
      padding-left: 1.25rem;
    }
  }

  .thead-light th {
    &:first-child {
      border-top-left-radius: 0.3125rem;
    }

    &:last-child {
      border-top-right-radius: 0.3125rem;
    }
  }
}

.card-alert {
  border-radius: 0;
  margin-bottom: 0;
}

/*------------------------------------
  Card Group Break
------------------------------------*/
@media (max-width: 575.98px) {
  .card-group-sm-break {
    display: block;

    > .card {
      margin-bottom: 0;

      &:not(:last-child) {
        border-bottom: 0.0625rem solid rgba(0, 34, 28, 0.1);
      }

      + .card {
        border-left: none;
      }

      &:not(:first-child):not(:last-child) {
        border-radius: 0;

        .card-header,
        .card-img-top,
        .card-footer,
        .card-img-bottom {
          border-radius: 0;
        }
      }

      &:first-child {
        border-top-left-radius: 0.3125rem;
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        .card-header,
        .card-img-top {
          border-top-left-radius: 0.3125rem;
          border-top-right-radius: 0.3125rem;
        }

        .card-footer,
        .card-img-bottom {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      &:last-child {
        border-bottom-right-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        .card-header,
        .card-img-top {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }

        .card-footer,
        .card-img-bottom {
          border-bottom-right-radius: 0.3125rem;
          border-bottom-left-radius: 0.3125rem;
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .card-group-md-break {
    display: block;

    > .card {
      margin-bottom: 0;

      &:not(:last-child) {
        border-bottom: 0.0625rem solid rgba(0, 34, 28, 0.1);
      }

      + .card {
        border-left: none;
      }

      &:not(:first-child):not(:last-child) {
        border-radius: 0;

        .card-header,
        .card-img-top,
        .card-footer,
        .card-img-bottom {
          border-radius: 0;
        }
      }

      &:first-child {
        border-top-left-radius: 0.3125rem;
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        .card-header,
        .card-img-top {
          border-top-left-radius: 0.3125rem;
          border-top-right-radius: 0.3125rem;
        }

        .card-footer,
        .card-img-bottom {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      &:last-child {
        border-bottom-right-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        .card-header,
        .card-img-top {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }

        .card-footer,
        .card-img-bottom {
          border-bottom-right-radius: 0.3125rem;
          border-bottom-left-radius: 0.3125rem;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .card-group-lg-break {
    display: block;

    > .card {
      margin-bottom: 0;

      &:not(:last-child) {
        border-bottom: 0.0625rem solid rgba(0, 34, 28, 0.1);
      }

      + .card {
        border-left: none;
      }

      &:not(:first-child):not(:last-child) {
        border-radius: 0;

        .card-header,
        .card-img-top,
        .card-footer,
        .card-img-bottom {
          border-radius: 0;
        }
      }

      &:first-child {
        border-top-left-radius: 0.3125rem;
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        .card-header,
        .card-img-top {
          border-top-left-radius: 0.3125rem;
          border-top-right-radius: 0.3125rem;
        }

        .card-footer,
        .card-img-bottom {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      &:last-child {
        border-bottom-right-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        .card-header,
        .card-img-top {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }

        .card-footer,
        .card-img-bottom {
          border-bottom-right-radius: 0.3125rem;
          border-bottom-left-radius: 0.3125rem;
        }
      }
    }
  }
}

/*------------------------------------
  Card Group Row
------------------------------------*/
@media (min-width: 576px) {
  .card-group-sm-row {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    border-radius: 0.3125rem;
    margin: 0;

    > * {
      padding: 0;

      > .card {
        height: 100%;
        box-shadow: none;
        border-radius: 0;
      }

      &:not(:first-child):not(:last-child) {
        border-radius: 0;

        .card-header,
        .card-img-top,
        .card-footer,
        .card-img-bottom {
          border-radius: 0;
        }
      }

      &:first-child > .card {
        border-top-left-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        .card-header,
        .card-img-top,
        .card-footer,
        .card-img-bottom {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &:last-child > .card {
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0.3125rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        .card-header,
        .card-img-top,
        .card-footer,
        .card-img-bottom {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      + * > .card {
        border-left: 0.0625rem solid rgba(0, 34, 28, 0.1);
      }
    }

    .card-divider {
      border-left-width: 0;
      border-top: 0.0625rem solid rgba(0, 34, 28, 0.1);
    }
  }

  .card-group-sm-2 > * {
    &:first-child > .card {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-left-radius: 0.3125rem;
        border-bottom-left-radius: 0;
      }
    }

    &:nth-child(2) > .card {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child > .card {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
      }
    }
  }

  .card-group-sm-3 > * {
    &:first-child > .card {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-left-radius: 0.3125rem;
        border-bottom-left-radius: 0;
      }
    }

    &:nth-child(3) > .card {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child > .card {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
      }
    }
  }

  .card-group-sm-4 > * {
    &:first-child > .card {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-left-radius: 0.3125rem;
        border-bottom-left-radius: 0;
      }
    }

    &:nth-child(4) > .card {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child > .card {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
      }
    }
  }
}

@media (min-width: 768px) {
  .card-group-md-row {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    border-radius: 0.3125rem;
    margin: 0;

    > * {
      padding: 0;

      > .card {
        height: 100%;
        box-shadow: none;
        border-radius: 0;
      }

      &:not(:first-child):not(:last-child) {
        border-radius: 0;

        .card-header,
        .card-img-top,
        .card-footer,
        .card-img-bottom {
          border-radius: 0;
        }
      }

      &:first-child > .card {
        border-top-left-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        .card-header,
        .card-img-top,
        .card-footer,
        .card-img-bottom {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &:last-child > .card {
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0.3125rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        .card-header,
        .card-img-top,
        .card-footer,
        .card-img-bottom {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      + * > .card {
        border-left: 0.0625rem solid rgba(0, 34, 28, 0.1);
      }
    }

    .card-divider {
      border-left-width: 0;
      border-top: 0.0625rem solid rgba(0, 34, 28, 0.1);
    }
  }

  .card-group-md-2 > * {
    &:first-child > .card {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-left-radius: 0.3125rem;
        border-bottom-left-radius: 0;
      }
    }

    &:nth-child(2) > .card {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child > .card {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
      }
    }
  }

  .card-group-md-3 > * {
    &:first-child > .card {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-left-radius: 0.3125rem;
        border-bottom-left-radius: 0;
      }
    }

    &:nth-child(3) > .card {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child > .card {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
      }
    }
  }

  .card-group-md-4 > * {
    &:first-child > .card {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-left-radius: 0.3125rem;
        border-bottom-left-radius: 0;
      }
    }

    &:nth-child(4) > .card {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child > .card {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
      }
    }
  }
}

@media (min-width: 992px) {
  .card-group-lg-row {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    border-radius: 0.3125rem;
    margin: 0;

    > * {
      padding: 0;

      > .card {
        height: 100%;
        box-shadow: none;
        border-radius: 0;
      }

      &:not(:first-child):not(:last-child) {
        border-radius: 0;

        .card-header,
        .card-img-top,
        .card-footer,
        .card-img-bottom {
          border-radius: 0;
        }
      }

      &:first-child > .card {
        border-top-left-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        .card-header,
        .card-img-top,
        .card-footer,
        .card-img-bottom {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &:last-child > .card {
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0.3125rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        .card-header,
        .card-img-top,
        .card-footer,
        .card-img-bottom {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      + * > .card {
        border-left: 0.0625rem solid rgba(0, 34, 28, 0.1);
      }
    }

    .card-divider {
      border-left-width: 0;
      border-top: 0.0625rem solid rgba(0, 34, 28, 0.1);
    }
  }

  .card-group-lg-2 > * {
    &:first-child > .card {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-left-radius: 0.3125rem;
        border-bottom-left-radius: 0;
      }
    }

    &:nth-child(2) > .card {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child > .card {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
      }
    }
  }

  .card-group-lg-3 > * {
    &:first-child > .card {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-left-radius: 0.3125rem;
        border-bottom-left-radius: 0;
      }
    }

    &:nth-child(3) > .card {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child > .card {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
      }
    }
  }

  .card-group-lg-4 > * {
    &:first-child > .card {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-left-radius: 0.3125rem;
        border-bottom-left-radius: 0;
      }
    }

    &:nth-child(4) > .card {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child > .card {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
      }
    }
  }
}

@media (min-width: 1200px) {
  .card-group-xl-row {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    border-radius: 0.3125rem;
    margin: 0;

    > * {
      padding: 0;

      > .card {
        height: 100%;
        box-shadow: none;
        border-radius: 0;
      }

      &:not(:first-child):not(:last-child) {
        border-radius: 0;

        .card-header,
        .card-img-top,
        .card-footer,
        .card-img-bottom {
          border-radius: 0;
        }
      }

      &:first-child > .card {
        border-top-left-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        .card-header,
        .card-img-top,
        .card-footer,
        .card-img-bottom {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &:last-child > .card {
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0.3125rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        .card-header,
        .card-img-top,
        .card-footer,
        .card-img-bottom {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      + * > .card {
        border-left: 0.0625rem solid rgba(0, 34, 28, 0.1);
      }
    }

    .card-divider {
      border-left-width: 0;
      border-top: 0.0625rem solid rgba(0, 34, 28, 0.1);
    }
  }

  .card-group-xl-2 > * {
    &:first-child > .card {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-left-radius: 0.3125rem;
        border-bottom-left-radius: 0;
      }
    }

    &:nth-child(2) > .card {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child > .card {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
      }
    }
  }

  .card-group-xl-3 > * {
    &:first-child > .card {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-left-radius: 0.3125rem;
        border-bottom-left-radius: 0;
      }
    }

    &:nth-child(3) > .card {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child > .card {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
      }
    }
  }

  .card-group-xl-4 > * {
    &:first-child > .card {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-left-radius: 0.3125rem;
        border-bottom-left-radius: 0;
      }
    }

    &:nth-child(4) > .card {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child > .card {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
      }
    }
  }
}

@media (min-width: 1400px) {
  .card-group-xxl-row {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    border-radius: 0.3125rem;
    margin: 0;

    > * {
      padding: 0;

      > .card {
        height: 100%;
        box-shadow: none;
        border-radius: 0;
      }

      &:not(:first-child):not(:last-child) {
        border-radius: 0;

        .card-header,
        .card-img-top,
        .card-footer,
        .card-img-bottom {
          border-radius: 0;
        }
      }

      &:first-child > .card {
        border-top-left-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        .card-header,
        .card-img-top,
        .card-footer,
        .card-img-bottom {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &:last-child > .card {
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0.3125rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        .card-header,
        .card-img-top,
        .card-footer,
        .card-img-bottom {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      + * > .card {
        border-left: 0.0625rem solid rgba(0, 34, 28, 0.1);
      }
    }

    .card-divider {
      border-left-width: 0;
      border-top: 0.0625rem solid rgba(0, 34, 28, 0.1);
    }
  }

  .card-group-xxl-2 > * {
    &:first-child > .card {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-left-radius: 0.3125rem;
        border-bottom-left-radius: 0;
      }
    }

    &:nth-child(2) > .card {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child > .card {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
      }
    }
  }

  .card-group-xxl-3 > * {
    &:first-child > .card {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-left-radius: 0.3125rem;
        border-bottom-left-radius: 0;
      }
    }

    &:nth-child(3) > .card {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child > .card {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
      }
    }
  }

  .card-group-xxl-4 > * {
    &:first-child > .card {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-left-radius: 0.3125rem;
        border-bottom-left-radius: 0;
      }
    }

    &:nth-child(4) > .card {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child > .card {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
      }
    }
  }
}

.card-group-row {
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
  border-radius: 0.3125rem;
  margin: 0;

  > * {
    padding: 0;

    > .card {
      height: 100%;
      box-shadow: none;
      border-radius: 0;
    }

    &:not(:first-child):not(:last-child) {
      border-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-radius: 0;
      }
    }

    &:first-child > .card {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child > .card {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0.3125rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      .card-header,
      .card-img-top,
      .card-footer,
      .card-img-bottom {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    + * > .card {
      border-left: 0.0625rem solid rgba(0, 34, 28, 0.1);
    }
  }

  .card-divider {
    border-left-width: 0;
    border-top: 0.0625rem solid rgba(0, 34, 28, 0.1);
  }
}

.card-group-2 > * {
  &:first-child > .card {
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0;

    .card-header,
    .card-img-top,
    .card-footer,
    .card-img-bottom {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0;
    }
  }

  &:nth-child(2) > .card {
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;

    .card-header,
    .card-img-top,
    .card-footer,
    .card-img-bottom {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0;
    }
  }

  &:last-child > .card {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;

    .card-header,
    .card-img-top,
    .card-footer,
    .card-img-bottom {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;
    }
  }
}

.card-group-3 > * {
  &:first-child > .card {
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0;

    .card-header,
    .card-img-top,
    .card-footer,
    .card-img-bottom {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0;
    }
  }

  &:nth-child(3) > .card {
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;

    .card-header,
    .card-img-top,
    .card-footer,
    .card-img-bottom {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0;
    }
  }

  &:last-child > .card {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;

    .card-header,
    .card-img-top,
    .card-footer,
    .card-img-bottom {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;
    }
  }
}

.card-group-4 > * {
  &:first-child > .card {
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0;

    .card-header,
    .card-img-top,
    .card-footer,
    .card-img-bottom {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0;
    }
  }

  &:nth-child(4) > .card {
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;

    .card-header,
    .card-img-top,
    .card-footer,
    .card-img-bottom {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0;
    }
  }

  &:last-child > .card {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;

    .card-header,
    .card-img-top,
    .card-footer,
    .card-img-bottom {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;
    }
  }
}

/*------------------------------------
  Code Editor
------------------------------------*/

.code-editor {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  z-index: 2;
  color: #51596c;
  font-family: var(--bs-font-monospace);
  background-color: #fff;
  box-shadow: 0rem 3.75rem 5rem 0rem rgba(140, 152, 164, 0.3);
  border-radius: 0.3125rem;
}

.code-editor-container {
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  min-width: 25rem;
}

.code-editor-line-numbers {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: end;
  align-items: flex-end;
  min-width: 3rem;
  text-align: right;
  background-color: rgba(241, 185, 128, 0.1);
  color: #8c98a4;
  padding: 1.25rem 1rem;
}

.code-editor-line-numbers-item {
  padding: 0 0.25rem;
}

.code-editor-typing-area {
  position: relative;
  width: 22rem;
  padding: 1.25rem 1.5rem;

  &:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.0625rem;
    height: 100%;
    background-color: rgba(0, 34, 28, 0.1);
    content: "";
  }
}

@media (max-width: 575.98px) {
  .code-editor {
    width: auto !important;
  }

  .code-editor-container {
    overflow: hidden;
    min-width: auto;
  }

  .code-editor-typing-area {
    overflow-x: auto;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    white-space: nowrap;
    scroll-behavior: smooth;

    &:nth-child(n + 3) {
      display: none;
    }

    &::-webkit-scrollbar {
      width: 0.6125rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(189, 197, 209, 0.6);
    }
  }
}

/*------------------------------------
  Close Button
------------------------------------*/

.btn-close:focus {
  box-shadow: none;
}

/*------------------------------------
  Col Divider
------------------------------------*/
@media (min-width: 576px) {
  .col-sm-divider > *:not(:first-child) {
    position: relative;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 0.0625rem;
      height: 100%;
      background-color: rgba(0, 34, 28, 0.1);
      content: "";
    }
  }
}

@media (min-width: 768px) {
  .col-md-divider > *:not(:first-child) {
    position: relative;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 0.0625rem;
      height: 100%;
      background-color: rgba(0, 34, 28, 0.1);
      content: "";
    }
  }
}

@media (min-width: 992px) {
  .col-lg-divider > *:not(:first-child) {
    position: relative;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 0.0625rem;
      height: 100%;
      background-color: rgba(0, 34, 28, 0.1);
      content: "";
    }
  }
}

@media (min-width: 1200px) {
  .col-xl-divider > *:not(:first-child) {
    position: relative;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 0.0625rem;
      height: 100%;
      background-color: rgba(0, 34, 28, 0.1);
      content: "";
    }
  }
}

@media (min-width: 1400px) {
  .col-xxl-divider > *:not(:first-child) {
    position: relative;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 0.0625rem;
      height: 100%;
      background-color: rgba(0, 34, 28, 0.1);
      content: "";
    }
  }
}

.col-divider > * {
  &:not(:first-child) {
    position: relative;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 0.0625rem;
      height: 100%;
      background-color: rgba(0, 34, 28, 0.1);
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: calc(100% - 1.5rem);
      height: 0.0625rem;
      background-color: rgba(0, 34, 28, 0.1);
      content: "";
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }

    position: relative;
  }

  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

@media (max-width: 575.98px) {
  .col-sm-divider > * {
    &:not(:first-child) {
      position: relative;

      &::before {
        position: absolute;
        top: 0;
        left: 50%;
        width: calc(100% - 1.5rem);
        height: 0.0625rem;
        background-color: rgba(0, 34, 28, 0.1);
        content: "";
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }

    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

@media (max-width: 767.98px) {
  .col-md-divider > * {
    &:not(:first-child) {
      position: relative;

      &::before {
        position: absolute;
        top: 0;
        left: 50%;
        width: calc(100% - 1.5rem);
        height: 0.0625rem;
        background-color: rgba(0, 34, 28, 0.1);
        content: "";
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }

    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

@media (max-width: 991.98px) {
  .col-lg-divider > * {
    &:not(:first-child) {
      position: relative;

      &::before {
        position: absolute;
        top: 0;
        left: 50%;
        width: calc(100% - 1.5rem);
        height: 0.0625rem;
        background-color: rgba(0, 34, 28, 0.1);
        content: "";
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }

    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

@media (max-width: 1199.98px) {
  .col-xl-divider > * {
    &:not(:first-child) {
      position: relative;

      &::before {
        position: absolute;
        top: 0;
        left: 50%;
        width: calc(100% - 1.5rem);
        height: 0.0625rem;
        background-color: rgba(0, 34, 28, 0.1);
        content: "";
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }

    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

@media (max-width: 1399.98px) {
  .col-xxl-divider > * {
    &:not(:first-child) {
      position: relative;

      &::before {
        position: absolute;
        top: 0;
        left: 50%;
        width: calc(100% - 1.5rem);
        height: 0.0625rem;
        background-color: rgba(0, 34, 28, 0.1);
        content: "";
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }

    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.col-divider-light > *:not(:first-child)::before {
  background-color: rgba(255, 255, 255, 0.1);
}

/*------------------------------------
  Devices
------------------------------------*/

.devices {
  max-width: 1140px;
  position: relative;
  overflow: hidden;
  padding: 1.75rem 0.75rem 7rem;
  margin-right: -0.75rem;
  margin-left: -0.75rem;

  .device-mobile {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    margin-left: 5rem;
    margin-bottom: 5rem;
  }

  .device-browser {
    margin-left: auto;
    margin-right: 5rem;
  }
}

@media (max-width: 991.98px) {
  .devices .device-mobile {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .devices {
    .device-mobile {
      margin-right: 0.5rem;
    }

    .device-browser {
      margin-right: auto;
    }
  }
}

.devices-shadow-none {
  .device-mobile-frame,
  .device-browser {
    box-shadow: none;
  }
}

/*------------------------------------
  Mobile Device
------------------------------------*/

.device-mobile {
  position: relative;
  z-index: 1;
  max-width: 100%;
  width: 15rem;
  height: auto;
}

.device-mobile-frame {
  background: #f8fafd;
  box-shadow: 0 2.75rem 5.5rem -3.5rem rgba(189, 197, 209, 0.5),
    0 2rem 4rem -2rem rgba(0, 34, 28, 0.3),
    inset 0 -0.1875rem 0.3125rem 0 rgba(151, 164, 175, 0.5);
  border-radius: 2rem;
  padding: 0.3125rem;
}

.device-mobile-img {
  max-width: 100%;
  height: auto;
  border-radius: 1.6rem;
}

/*------------------------------------
  Browser Device
------------------------------------*/

.device-browser {
  position: relative;
  z-index: 1;
  max-width: 100%;
  width: 50rem;
  height: auto;
  box-shadow: 0 2.75rem 5.5rem -1rem rgba(189, 197, 209, 0.5),
    0 2rem 4rem -1rem rgba(0, 34, 28, 0.15);
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  margin-left: auto;
  margin-right: auto;
}

.device-browser-frame {
  background: #fff;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.device-browser-img {
  max-width: 100%;
  height: auto;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.device-browser-header {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  max-width: 50rem;
  background: #f6f9fc4d;
  border-bottom: 0.0625rem solid #f1f3f8;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 0.5rem 6.5rem;
}

.device-browser-header-btn-list {
  display: -ms-flexbox;
  display: flex;
  grid-gap: 0.25rem;
  position: absolute;
  top: calc(50% - 0.25rem);
  left: 1rem;
}

.device-browser-header-btn-list-btn {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #e7eaf3;
  border-radius: 50%;
}

.device-browser-header-browser-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #e7eaf3;
  font-size: 0.5rem;
  border-radius: 0.125rem;
}

.device-browser-lg {
  width: 60rem;

  .device-browser-header {
    max-width: 60rem;
  }
}

/*------------------------------------
  Dropdown
------------------------------------*/

.dropdown-menu {
  box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
  margin-top: 0.5rem;

  .dropdown-item.dropdown-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}

.dropdown-item {
  font-size: 0.875rem;
  border-radius: 0.3125rem;

  &:not(:last-child) {
    margin-bottom: 0.25rem;
  }

  &:active {
    color: #223b41;
    background-color: rgba(189, 197, 209, 0.3);
  }

  &.active:not(:focus):not(:active) {
    color: #321844;
    background-color: rgba(189, 197, 209, 0.3);
  }

  &[href]:hover.dropdown-toggle::after {
    background: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='#321844' xmlns='http://www.w3.org/2000/svg'><path d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/></svg>");
  }
}

.dropdown-header {
  text-transform: uppercase;
  letter-spacing: 0.03125rem;
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
}

.dropdown-item-icon {
  display: inline-block;
  opacity: 0.7;
  font-size: 1.125rem;
  width: 1.5rem;
  color: #51596c;
}

.dropdown-toggle {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;

  &::after {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2351596C' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 1rem 1rem;
    margin-left: auto;
    padding-left: 1.25rem;
    content: "";
  }
}

.dropup .dropdown-toggle::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.dropright .dropdown-toggle::after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  margin-top: 0.25rem;
}

.dropleft .dropdown-toggle::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  margin-top: -0.25rem;
}

.dropdown-toggle-collapse {
  &::after {
    transition: 0.3s;
  }

  &[aria-expanded="true"]::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
}

/*------------------------------------
  Form Check
------------------------------------*/

.form-check-input,
.form-check-label {
  cursor: pointer;
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border: 1px solid rgba(0, 34, 28, 0.1);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #51596c;
}

.form-check-custom .form-check-input:checked {
  &[type="checkbox"],
  &[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%23fff'/%3e%3c/svg%3e");
  }
}

.form-check-row-select.checked {
  background-color: rgba(0, 128, 96, 0.05);
}

/*------------------------------------
  Form Select
------------------------------------*/

.form-select {
  cursor: pointer;
}

/*------------------------------------
  Form Switch
------------------------------------*/

.form-switch {
  position: relative;

  .form-check-input {
    width: 3em;
    height: 2em;
    border-width: 0;
    background-color: #e7eaf3;
    margin-top: -0.25em;
    margin-right: 0.5rem;

    &:active {
      -webkit-filter: 100%;
      filter: 100%;
    }
  }
}

.form-check-input:checked {
  background-color: #321844;
}

.form-switch-between {
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;

  .form-check-input {
    float: none;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 0.5rem;
  }

  .form-check-label {
    cursor: inherit;
  }
}

.form-switch-promotion {
  position: relative;
  min-width: 5rem;
}

.form-switch-promotion-container {
  position: absolute;
  top: -1.25rem;
  left: 1.25rem;
}

.form-switch-promotion-body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-top: -1rem;
}

.form-switch-promotion-arrow {
  margin-right: -1.75rem;
}

.form-switch-promotion-text {
  display: block;
  margin-top: 0.3125rem;
}

/*------------------------------------
  Input Group Merge
------------------------------------*/

.input-group-merge {
  position: relative;
  overflow: hidden;
  border: 0.0625rem solid rgba(0, 34, 28, 0.1);
  border-radius: 0.3125rem;

  .input-group-prepend,
  .input-group-append {
    position: absolute;
    top: 1px;
    bottom: 1px;
    z-index: 4;
    background-color: transparent;
  }

  .input-group-prepend.input-group-text,
  .input-group-append.input-group-text {
    border-width: 0;
  }

  .input-group-prepend {
    left: 1px;
  }

  .input-group-append {
    right: 1px;
  }

  .form-select,
  .form-control {
    border-width: 0;
  }

  .form-select:not(:first-child),
  .form-control:not(:first-child) {
    padding-left: 3.375rem;
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }

  .form-select:not(:last-child),
  .form-control:not(:last-child) {
    padding-right: 3.375rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
  }

  .form-select-sm:not(:first-child),
  .form-control-sm:not(:first-child) {
    padding-left: 3.3125rem;
  }

  .form-select-sm:not(:last-child),
  .form-control-sm:not(:last-child) {
    padding-right: 3.3125rem;
  }
}

.was-validated .input-group-merge {
  &.is-valid {
    border-color: #077c76;

    &.focus {
      box-shadow: 0 0 1rem 0 rgba(7, 124, 118, 0.25);
    }
  }

  &.is-invalid {
    border-color: #692340;

    &.focus {
      box-shadow: 0 0 1rem 0 rgba(105, 35, 64, 0.25);
    }
  }
}

/*------------------------------------
  Input Card
------------------------------------*/

.input-card {
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  padding: 0.75rem 0.75rem;
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
  border-radius: 0.3125rem;

  .input-card-form {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }

  .btn {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .form-control {
    border-width: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .input-group {
    border-width: 0;
  }

  .input-card-form {
    position: relative;

    &:not(:first-child) {
      padding-left: 1rem;

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        width: 0.0625rem;
        height: 2rem;
        background-color: rgba(0, 34, 28, 0.1);
        content: "";
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }

    &:not(:last-child) {
      padding-right: 1rem;
    }
  }
}

@media (max-width: 575.98px) {
  .input-card-sm {
    display: grid;

    .btn {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }

    .input-card-form {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      padding: 1rem 0 !important;
      border-bottom: 0.0625rem solid rgba(0, 34, 28, 0.1);

      &:first-child {
        padding-top: 0 !important;
      }

      &:not(:first-child)::before {
        display: none;
      }
    }
  }
}

/*------------------------------------
  Labels
------------------------------------*/

.form-label-secondary {
  color: #8c98a4;
}

.form-label-link {
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

/*------------------------------------
  Go To
------------------------------------*/

.go-to {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 9999;
  width: 2.875rem;
  height: 2.875rem;
  background-color: #32184417;
  color: #321844;
  font-size: 1rem;
  opacity: 0.5;
  border-radius: 0.3125rem;
  transition: 0.3s ease-out;

  &:hover,
  &:focus:hover {
    color: #fff;
    background-color: #321844;
    opacity: 1;
  }
}

/*------------------------------------
  List Checked
------------------------------------*/

.list-checked {
  padding-left: 0;
  list-style: none;
}

.list-checked-item {
  position: relative;
  color: #51596c;
  padding-left: 2rem;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 1.25rem;
    height: 1.25rem;
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%2351596C'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 1.25rem 1.25rem;
    content: "";
    margin-top: 0.125rem;
  }

  &[hover]:hover {
    color: #321844;
  }
}

[class*="list-checked-bg-"] .list-checked-item::before,
[class*="list-checked-soft-bg-"] .list-checked-item::before {
  margin-top: 0.25rem;
}

.list-checked-primary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%23008060'/%3e%3c/svg%3e");
}

.list-checked-bg-primary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23008060'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-primary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23008060' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23008060'/%3e%3c/svg%3e");
}

.list-checked-secondary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%2351596C'/%3e%3c/svg%3e");
}

.list-checked-bg-secondary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2351596C'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-secondary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2351596C' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%2351596C'/%3e%3c/svg%3e");
}

.list-checked-success .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%23077C76'/%3e%3c/svg%3e");
}

.list-checked-bg-success .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23077C76'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-success .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23077C76' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23077C76'/%3e%3c/svg%3e");
}

.list-checked-info .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%23334AC0'/%3e%3c/svg%3e");
}

.list-checked-bg-info .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23334AC0'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-info .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23334AC0' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23334AC0'/%3e%3c/svg%3e");
}

.list-checked-warning .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%23F1B980'/%3e%3c/svg%3e");
}

.list-checked-bg-warning .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23F1B980'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-warning .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23F1B980' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23F1B980'/%3e%3c/svg%3e");
}

.list-checked-danger .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%23692340'/%3e%3c/svg%3e");
}

.list-checked-bg-danger .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23692340'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-danger .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23692340' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23692340'/%3e%3c/svg%3e");
}

.list-checked-light .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%23F9FAFC'/%3e%3c/svg%3e");
}

.list-checked-bg-light .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23F9FAFC'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-light .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23F9FAFC' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23F9FAFC'/%3e%3c/svg%3e");
}

.list-checked-dark .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%2300221C'/%3e%3c/svg%3e");
}

.list-checked-bg-dark .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2300221C'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-dark .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2300221C' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%2300221C'/%3e%3c/svg%3e");
}

.list-checked-sm {
  .list-checked-item {
    padding-left: 1.5rem;

    &::before {
      width: 1rem;
      height: 1rem;
      background-size: 1rem 1rem;
      margin-top: 0.3125rem;
    }
  }

  &[class*="list-checked-bg-"] .list-checked-item::before,
  &[class*="list-checked-soft-bg-"] .list-checked-item::before {
    margin-top: 0.25rem;
  }
}

.list-checked-lg {
  .list-checked-item {
    padding-left: 2.75rem;

    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }

    &::before {
      width: 1.75rem;
      height: 1.75rem;
      background-size: 1.75rem 1.75rem;
      margin-top: 0;
    }
  }

  &[class*="list-checked-bg-"] .list-checked-item::before,
  &[class*="list-checked-soft-bg-"] .list-checked-item::before {
    margin-top: -0.0625rem;
  }
}

/*------------------------------------
  List Comment
------------------------------------*/

.list-comment {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;

  &:first-child {
    margin-top: -2.5rem;
  }
}

.list-comment-item {
  margin-top: 2.5rem;

  .list-comment .list-comment-item {
    padding-left: 1rem;
    border-left: 0.1875rem solid rgba(0, 34, 28, 0.1);
  }
}

/*------------------------------------
  List Padding
------------------------------------*/

.list-py-1 > li {
  &:not(:first-child) {
    padding-top: 0.25rem;
  }

  &:not(:last-child) {
    padding-bottom: 0.25rem;
  }
}

.list-py-2 > li {
  &:not(:first-child) {
    padding-top: 0.5rem;
  }

  &:not(:last-child) {
    padding-bottom: 0.5rem;
  }
}

.list-py-3 > li {
  &:not(:first-child) {
    padding-top: 1rem;
  }

  &:not(:last-child) {
    padding-bottom: 1rem;
  }
}

/*------------------------------------
  List Separator
------------------------------------*/

.list-separator {
  margin-bottom: 0;

  .list-inline-item {
    position: relative;
    margin-left: 0;
    margin-right: -0.25rem;

    &:not(:last-child) {
      padding-right: 2rem;

      &::after {
        position: absolute;
        top: 50%;
        right: 0.8rem;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        content: "•";
        opacity: 0.5;
      }
    }
  }

  .list-separator-link {
    color: #51596c;

    &:hover {
      color: #321844;
    }
  }
}

.list-separator-light {
  .list-inline-item::after {
    color: #fff;
  }

  .list-separator-link {
    color: #fff;

    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
}

/*------------------------------------
  List Group
------------------------------------*/

.list-group-item {
  &:first-child {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
  }

  &:last-child {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
}

.list-group-icon {
  width: 1.25rem;
  text-align: center;
  margin-right: 0.25rem;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 0;
}

.list-group-striped > li:nth-of-type(odd) {
  background-color: #32184417;
}

.list-group-sm .list-group-item {
  font-size: 0.8125rem;
  padding: 0.25rem 0.5rem;
}

.list-group-lg {
  .list-group-item {
    padding: 1.5rem 1.5rem;
  }

  .list-group-icon {
    font-size: 1.5rem;
    width: 2.3125rem;
    margin-right: 0.5rem;
  }
}

/*------------------------------------
  Modal
------------------------------------*/

.modal-header {
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 0;

  .close {
    padding: 0.25rem 0.25rem;
    margin: 0 0 0 auto;
  }
}

.modal-footer > * {
  margin-top: 0;
  margin-bottom: 0;
}

.modal-footer-text:last-child {
  font-size: 0.8125rem;
  margin-bottom: 0;
}

.modal-close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  z-index: 2;
}

/*------------------------------------
  Nav
------------------------------------*/

.nav-subtitle {
  display: block;
  color: #8c98a4;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.03125rem;
}

.nav-link {
  &.dropdown-toggle {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }

  &.active {
    color: #321844;

    .nav-link-svg path {
      fill: #321844;
    }
  }
}

.nav-item.show .nav-link-svg path {
  fill: #321844;
}

.nav-title:last-child,
.nav-text:last-child {
  margin-bottom: 0;
}

.nav-icon {
  font-size: 1.125rem;
  opacity: 0.7;
  margin-right: 0.5rem;
}

.nav-divider {
  height: 2rem;
  border-left: 0.1rem solid rgba(0, 34, 28, 0.1);
  margin: 0 0.5rem;
}

.nav.nav-link-gray .nav-link {
  color: #51596c;

  &.active,
  &:hover {
    color: #321844;
  }

  &.disabled {
    color: #bdc5d1;
  }
}

.nav-tabs {
  .nav-link {
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom: 0.1875rem solid transparent;

    &.active {
      font-weight: 500;
    }
  }

  .nav-item.show .nav-link {
    font-weight: 500;
  }

  &:not(.nav-vertical) .nav-link {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}

.nav-vertical {
  -ms-flex-direction: column;
  flex-direction: column;

  &.nav .nav-item:not(:last-child) {
    margin-right: 0;
  }

  .nav-subtitle {
    padding-left: 1rem;
  }

  &.nav-tabs {
    border-bottom-width: 0;

    .nav-link {
      border-top-width: 0;
      border-right-width: 0;
      border-bottom-width: 0;
      border-left: 0.1875rem solid rgba(0, 34, 28, 0.1);
      margin-bottom: 0;
    }

    .nav-subtitle {
      border-left: 0.1875rem solid rgba(0, 34, 28, 0.1);
    }

    .nav-link.active,
    .nav-item.show .nav-link {
      border-color: #321844;
    }
  }

  &.nav.nav-tabs .nav-collapse .nav-link {
    padding-left: 3rem;
  }
}

.nav-collapse .nav-link {
  position: relative;

  &::before {
    position: absolute;
    top: 50%;
    left: 2rem;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    content: "•";
  }
}

.nav-pills {
  .nav-item {
    margin: 0.25rem 0.25rem;
  }

  .nav-link {
    padding: 1rem 1rem;

    &:hover {
      color: #321844;
      background-color: #f8fafd;
    }

    &.active:hover {
      background-color: #32184417;
      border-color: transparent;
    }
  }

  .show > .nav-link:hover {
    border-color: transparent;
  }
}

.nav-segment {
  position: relative;
  background-color: #f8fafd;
  padding: 0.25rem 0.25rem;
  border-radius: 0.3125rem;

  &:not(.nav-fill) {
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  .nav-link {
    color: #321844;
    font-size: 0.8125rem;
    padding: 0.5rem 0.875rem;
    border-radius: 0.3125rem;

    &:hover {
      color: #321844;
    }

    &.active {
      color: #321844;
      background-color: #fff;
      box-shadow: 0rem 0.1875rem 0.375rem 0rem rgba(140, 152, 164, 0.25);
    }
  }

  &.nav-pills {
    border-radius: 6.1875rem;

    .nav-link {
      border-radius: 6.1875rem;
    }
  }
}

.nav-sm .nav-link {
  font-size: 0.9375rem;
}

.nav-lg .nav-link {
  font-size: 1.375rem;
}

/*------------------------------------
  Navbar
------------------------------------*/

.navbar {
  z-index: 99;
}

.navbar-collapse {
  -ms-flex-align: start;
  align-items: flex-start;
}

.navbar-shadow {
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
}

.navbar-height {
  height: 3.875rem;
}

.navbar.navbar-scrolled {
  &.navbar-light {
    background-color: #fff;
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
  }

  &.navbar-dark {
    background-color: #321844;
  }
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-brand-logo {
  width: 100%;
  min-width: 8.5rem;
  max-width: 8.5rem;
}

.navbar-brand-collapsed,
.navbar-brand-on-scroll {
  display: none;
}

.navbar-scrolled {
  .navbar-brand-default {
    display: none;
  }

  .navbar-brand-on-scroll {
    display: inline-block;
  }
}

.navbar-nav-wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-nav-wrap-secondary-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.5rem;
}

.navbar-toggler {
  padding: 0.5rem 0.5rem;
  margin-left: auto;

  &:focus {
    box-shadow: none;
  }

  .navbar-toggler-default {
    display: -ms-flexbox;
    display: flex;
  }

  .navbar-toggler-toggled {
    display: none;
  }

  &[aria-expanded="true"] {
    .navbar-toggler-default {
      display: none;
    }

    .navbar-toggler-toggled {
      display: -ms-flexbox;
      display: flex;
    }
  }
}

.navbar {
  .navbar-nav .nav-link {
    padding: 1rem 0.75rem;
  }

  .nav-item:hover > .nav-link {
    color: #321844;
  }

  .dropdown-menu::before {
    position: absolute;
    top: -0.625rem;
    display: block;
    left: 0;
    width: 100%;
    height: 1.75rem;
    content: "";
  }

  .dropdown-item[href]:hover.dropdown-toggle::after {
    background: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='#51596C' xmlns='http://www.w3.org/2000/svg'><path d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/></svg>");
  }
}

.navbar-dropdown-menu-inner {
  padding: 0.75rem 0.75rem;
}

.navbar-dropdown-menu-media-link {
  display: block;
  text-decoration: none;
  padding: 1rem 1rem;
  border-radius: 0.3125rem;

  &:hover:not(.disabled):not(:disabled) {
    background-color: rgba(189, 197, 209, 0.3);

    .navbar-dropdown-menu-media-title {
      color: #321844;
    }
  }

  &.disabled {
    pointer-events: none;
  }

  + .navbar-dropdown-menu-media-link {
    margin-top: 0.5rem;
  }
}

.navbar-dropdown-menu-media-title {
  color: #223b41;
  font-weight: 500;
}

.navbar-dropdown-menu-media-desc {
  color: #51596c;
  font-size: 0.875rem;
  margin-bottom: 0;
}

.navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item {
  position: relative;

  &::after {
    position: absolute;
    top: -0.75rem;
    right: 0;
    border-top: 0.0625rem solid rgba(0, 34, 28, 0.1);
    width: 100%;
    content: "";
  }
}

.navbar-dropdown-menu-promo-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  margin: 0.75rem 0.75rem;

  ~ .navbar-dropdown-menu-promo-item {
    border-top: 0.0625rem solid rgba(0, 34, 28, 0.1);
  }
}

.navbar-dropdown-menu-promo .navbar-dropdown-menu-promo-item {
  border-top: none;
}

.navbar-dropdown-menu-promo-link {
  display: block;
  height: 100%;
  padding: 1rem 1rem;
  border-radius: 0.3125rem;

  &.active {
    background-color: rgba(189, 197, 209, 0.3);

    .navbar-dropdown-menu-media-title {
      color: #321844;
    }
  }

  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  &:hover:not(.disabled):not(:disabled) {
    background-color: rgba(189, 197, 209, 0.3);

    .navbar-dropdown-menu-media-title {
      color: #321844;
    }
  }
}

.navbar.navbar-vertical {
  .navbar-nav {
    .nav-subtitle,
    .nav-link {
      padding: 0.3125rem 1.5rem;
    }
  }

  .nav-tabs-borderless.nav-vertical {
    padding-right: 0;
    padding-left: 0;

    .nav-link,
    .nav-subtitle {
      border-left-color: transparent;
    }

    .nav-link.active,
    .nav-item.show .nav-link {
      border-color: #321844;
    }
  }
}

.navbar-input-group {
  /* clears the 'X' from Internet Explorer */
  /* clears the 'X' from Chrome */

  input {
    &[type="search"] {
      &::-ms-clear,
      &::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
      }
    }

    &[type="search"] {
      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        display: none;
      }
    }
  }
}

/*------------------------------------
  Absolute Positions
------------------------------------*/
@media (min-width: 576px) {
  .navbar-absolute-sm-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 768px) {
  .navbar-absolute-md-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 992px) {
  .navbar-absolute-lg-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-absolute-xl-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .navbar-absolute-xxl-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.navbar-absolute-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media (max-width: 575.98px) {
  .navbar-absolute-md-top .navbar-absolute-top-scroller {
    max-height: 75vh;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.6125rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(189, 197, 209, 0.6);
      visibility: hidden;
    }

    &:hover::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
}

@media (max-width: 767.98px) {
  .navbar-absolute-top .navbar-absolute-top-scroller,
  .navbar-absolute-sm-top .navbar-absolute-top-scroller,
  .navbar-absolute-lg-top .navbar-absolute-top-scroller {
    max-height: 75vh;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;
  }

  .navbar-absolute-top .navbar-absolute-top-scroller::-webkit-scrollbar,
  .navbar-absolute-sm-top .navbar-absolute-top-scroller::-webkit-scrollbar,
  .navbar-absolute-lg-top .navbar-absolute-top-scroller::-webkit-scrollbar {
    width: 0.6125rem;
  }

  .navbar-absolute-top .navbar-absolute-top-scroller::-webkit-scrollbar-thumb,
  .navbar-absolute-sm-top
    .navbar-absolute-top-scroller::-webkit-scrollbar-thumb,
  .navbar-absolute-lg-top
    .navbar-absolute-top-scroller::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }

  .navbar-absolute-top
    .navbar-absolute-top-scroller:hover::-webkit-scrollbar-thumb,
  .navbar-absolute-sm-top
    .navbar-absolute-top-scroller:hover::-webkit-scrollbar-thumb,
  .navbar-absolute-lg-top
    .navbar-absolute-top-scroller:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}

@media (max-width: 991.98px) {
  .navbar-absolute-xl-top .navbar-absolute-top-scroller {
    max-height: 75vh;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.6125rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(189, 197, 209, 0.6);
      visibility: hidden;
    }

    &:hover::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
}

/*------------------------------------
  Sticky Positions
------------------------------------*/
@media (min-width: 576px) {
  .navbar-sticky-sm-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 768px) {
  .navbar-sticky-md-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 992px) {
  .navbar-sticky-lg-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-sticky-xl-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .navbar-sticky-xxl-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

.navbar-sticky-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media (max-width: 575.98px) {
  .navbar-sticky-md-top .navbar-sticky-top-scroller {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.6125rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(189, 197, 209, 0.6);
      visibility: hidden;
    }

    &:hover::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
}

@media (max-width: 767.98px) {
  .navbar-sticky-top .navbar-sticky-top-scroller,
  .navbar-sticky-sm-top .navbar-sticky-top-scroller,
  .navbar-sticky-lg-top .navbar-sticky-top-scroller {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }

  .navbar-sticky-top .navbar-sticky-top-scroller::-webkit-scrollbar,
  .navbar-sticky-sm-top .navbar-sticky-top-scroller::-webkit-scrollbar,
  .navbar-sticky-lg-top .navbar-sticky-top-scroller::-webkit-scrollbar {
    width: 0.6125rem;
  }

  .navbar-sticky-top .navbar-sticky-top-scroller::-webkit-scrollbar-thumb,
  .navbar-sticky-sm-top .navbar-sticky-top-scroller::-webkit-scrollbar-thumb,
  .navbar-sticky-lg-top .navbar-sticky-top-scroller::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }

  .navbar-sticky-top .navbar-sticky-top-scroller:hover::-webkit-scrollbar-thumb,
  .navbar-sticky-sm-top
    .navbar-sticky-top-scroller:hover::-webkit-scrollbar-thumb,
  .navbar-sticky-lg-top
    .navbar-sticky-top-scroller:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}

@media (max-width: 991.98px) {
  .navbar-sticky-xl-top .navbar-sticky-top-scroller {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.6125rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(189, 197, 209, 0.6);
      visibility: hidden;
    }

    &:hover::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
}

/*------------------------------------
  Navbar Expand
------------------------------------*/
@media (min-width: 576px) {
  .navbar-expand-sm {
    .navbar-toggler {
      -ms-flex-order: 1;
      order: 1;
    }

    .navbar-collapse {
      width: auto;
      -ms-flex-order: 2;
      order: 2;
    }

    .navbar-nav-wrap-secondary-content {
      -ms-flex-order: 3;
      order: 3;
    }

    .navbar-nav {
      -ms-flex-align: center;
      align-items: center;
    }

    .nav-item {
      &:not(:last-child) {
        margin-right: 0.5rem;
      }

      .nav-item {
        margin-right: 0;
      }
    }

    .dropdown-menu {
      &[data-bs-popper] {
        margin-top: 0.5rem;
      }

      .hs-has-sub-menu .dropdown-menu {
        margin-top: -0.6875rem;
        margin-left: 0.5rem;

        &[data-bs-popper] {
          left: 100%;
        }

        &::before {
          top: 0;
          left: -1rem;
          width: 1rem;
          height: 100%;
        }
      }
    }

    &.navbar-end {
      .navbar-nav {
        -ms-flex-pack: end;
        justify-content: flex-end;
        margin-left: auto;
      }

      .navbar-sticky-top-scroller,
      .navbar-absolute-top-scroller {
        margin-left: auto;
      }
    }

    &.navbar-vertical {
      -ms-flex-flow: column;
      flex-flow: column;
      overflow-y: scroll;
      height: 100%;
      max-height: 100vh;

      &::-webkit-scrollbar {
        width: 0.6125rem;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(189, 197, 209, 0.6);
      }

      .navbar-collapse {
        width: 100%;
        display: block !important;
      }

      .navbar-nav {
        display: block;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: start;
        align-items: flex-start;
      }
    }

    .hs-position-right-fix {
      right: 25%;
      left: auto;
    }

    .navbar-dropdown-menu-promo {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
    }

    .navbar-dropdown-menu-promo-item {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      -ms-flex-direction: column;
      flex-direction: column;

      &:not(:last-child) .navbar-dropdown-menu-promo-link {
        position: relative;

        &::after {
          position: absolute;
          top: 0;
          right: 0;
          margin-right: -0.75rem;
          border-right: 0.0625rem solid rgba(0, 34, 28, 0.1);
          height: 100%;
          content: "";
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    .navbar-toggler {
      -ms-flex-order: 1;
      order: 1;
    }

    .navbar-collapse {
      width: auto;
      -ms-flex-order: 2;
      order: 2;
    }

    .navbar-nav-wrap-secondary-content {
      -ms-flex-order: 3;
      order: 3;
    }

    .navbar-nav {
      -ms-flex-align: center;
      align-items: center;
    }

    .nav-item {
      &:not(:last-child) {
        margin-right: 0.5rem;
      }

      .nav-item {
        margin-right: 0;
      }
    }

    .dropdown-menu {
      &[data-bs-popper] {
        margin-top: 0.5rem;
      }

      .hs-has-sub-menu .dropdown-menu {
        margin-top: -0.6875rem;
        margin-left: 0.5rem;

        &[data-bs-popper] {
          left: 100%;
        }

        &::before {
          top: 0;
          left: -1rem;
          width: 1rem;
          height: 100%;
        }
      }
    }

    &.navbar-end {
      .navbar-nav {
        -ms-flex-pack: end;
        justify-content: flex-end;
        margin-left: auto;
      }

      .navbar-sticky-top-scroller,
      .navbar-absolute-top-scroller {
        margin-left: auto;
      }
    }

    &.navbar-vertical {
      -ms-flex-flow: column;
      flex-flow: column;
      overflow-y: scroll;
      height: 100%;
      max-height: 100vh;

      &::-webkit-scrollbar {
        width: 0.6125rem;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(189, 197, 209, 0.6);
      }

      .navbar-collapse {
        width: 100%;
        display: block !important;
      }

      .navbar-nav {
        display: block;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: start;
        align-items: flex-start;
      }
    }

    .hs-position-right-fix {
      right: 25%;
      left: auto;
    }

    .navbar-dropdown-menu-promo {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
    }

    .navbar-dropdown-menu-promo-item {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      -ms-flex-direction: column;
      flex-direction: column;

      &:not(:last-child) .navbar-dropdown-menu-promo-link {
        position: relative;

        &::after {
          position: absolute;
          top: 0;
          right: 0;
          margin-right: -0.75rem;
          border-right: 0.0625rem solid rgba(0, 34, 28, 0.1);
          height: 100%;
          content: "";
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    .navbar-toggler {
      -ms-flex-order: 1;
      order: 1;
    }

    .navbar-collapse {
      width: auto;
      -ms-flex-order: 2;
      order: 2;
    }

    .navbar-nav-wrap-secondary-content {
      -ms-flex-order: 3;
      order: 3;
    }

    .navbar-nav {
      -ms-flex-align: center;
      align-items: center;
    }

    .nav-item {
      &:not(:last-child) {
        margin-right: 0.5rem;
      }

      .nav-item {
        margin-right: 0;
      }
    }

    .dropdown-menu {
      &[data-bs-popper] {
        margin-top: 0.5rem;
      }

      .hs-has-sub-menu .dropdown-menu {
        margin-top: -0.6875rem;
        margin-left: 0.5rem;

        &[data-bs-popper] {
          left: 100%;
        }

        &::before {
          top: 0;
          left: -1rem;
          width: 1rem;
          height: 100%;
        }
      }
    }

    &.navbar-end {
      .navbar-nav {
        -ms-flex-pack: end;
        justify-content: flex-end;
        margin-left: auto;
      }

      .navbar-sticky-top-scroller,
      .navbar-absolute-top-scroller {
        margin-left: auto;
      }
    }

    &.navbar-vertical {
      -ms-flex-flow: column;
      flex-flow: column;
      overflow-y: scroll;
      height: 100%;
      max-height: 100vh;

      &::-webkit-scrollbar {
        width: 0.6125rem;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(189, 197, 209, 0.6);
      }

      .navbar-collapse {
        width: 100%;
        display: block !important;
      }

      .navbar-nav {
        display: block;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: start;
        align-items: flex-start;
      }
    }

    .hs-position-right-fix {
      right: 25%;
      left: auto;
    }

    .navbar-dropdown-menu-promo {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
    }

    .navbar-dropdown-menu-promo-item {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      -ms-flex-direction: column;
      flex-direction: column;

      &:not(:last-child) .navbar-dropdown-menu-promo-link {
        position: relative;

        &::after {
          position: absolute;
          top: 0;
          right: 0;
          margin-right: -0.75rem;
          border-right: 0.0625rem solid rgba(0, 34, 28, 0.1);
          height: 100%;
          content: "";
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    .navbar-toggler {
      -ms-flex-order: 1;
      order: 1;
    }

    .navbar-collapse {
      width: auto;
      -ms-flex-order: 2;
      order: 2;
    }

    .navbar-nav-wrap-secondary-content {
      -ms-flex-order: 3;
      order: 3;
    }

    .navbar-nav {
      -ms-flex-align: center;
      align-items: center;
    }

    .nav-item {
      &:not(:last-child) {
        margin-right: 0.5rem;
      }

      .nav-item {
        margin-right: 0;
      }
    }

    .dropdown-menu {
      &[data-bs-popper] {
        margin-top: 0.5rem;
      }

      .hs-has-sub-menu .dropdown-menu {
        margin-top: -0.6875rem;
        margin-left: 0.5rem;

        &[data-bs-popper] {
          left: 100%;
        }

        &::before {
          top: 0;
          left: -1rem;
          width: 1rem;
          height: 100%;
        }
      }
    }

    &.navbar-end {
      .navbar-nav {
        -ms-flex-pack: end;
        justify-content: flex-end;
        margin-left: auto;
      }

      .navbar-sticky-top-scroller,
      .navbar-absolute-top-scroller {
        margin-left: auto;
      }
    }

    &.navbar-vertical {
      -ms-flex-flow: column;
      flex-flow: column;
      overflow-y: scroll;
      height: 100%;
      max-height: 100vh;

      &::-webkit-scrollbar {
        width: 0.6125rem;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(189, 197, 209, 0.6);
      }

      .navbar-collapse {
        width: 100%;
        display: block !important;
      }

      .navbar-nav {
        display: block;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: start;
        align-items: flex-start;
      }
    }

    .hs-position-right-fix {
      right: 25%;
      left: auto;
    }

    .navbar-dropdown-menu-promo {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
    }

    .navbar-dropdown-menu-promo-item {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      -ms-flex-direction: column;
      flex-direction: column;

      &:not(:last-child) .navbar-dropdown-menu-promo-link {
        position: relative;

        &::after {
          position: absolute;
          top: 0;
          right: 0;
          margin-right: -0.75rem;
          border-right: 0.0625rem solid rgba(0, 34, 28, 0.1);
          height: 100%;
          content: "";
        }
      }
    }
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    .navbar-toggler {
      -ms-flex-order: 1;
      order: 1;
    }

    .navbar-collapse {
      width: auto;
      -ms-flex-order: 2;
      order: 2;
    }

    .navbar-nav-wrap-secondary-content {
      -ms-flex-order: 3;
      order: 3;
    }

    .navbar-nav {
      -ms-flex-align: center;
      align-items: center;
    }

    .nav-item {
      &:not(:last-child) {
        margin-right: 0.5rem;
      }

      .nav-item {
        margin-right: 0;
      }
    }

    .dropdown-menu {
      &[data-bs-popper] {
        margin-top: 0.5rem;
      }

      .hs-has-sub-menu .dropdown-menu {
        margin-top: -0.6875rem;
        margin-left: 0.5rem;

        &[data-bs-popper] {
          left: 100%;
        }

        &::before {
          top: 0;
          left: -1rem;
          width: 1rem;
          height: 100%;
        }
      }
    }

    &.navbar-end {
      .navbar-nav {
        -ms-flex-pack: end;
        justify-content: flex-end;
        margin-left: auto;
      }

      .navbar-sticky-top-scroller,
      .navbar-absolute-top-scroller {
        margin-left: auto;
      }
    }

    &.navbar-vertical {
      -ms-flex-flow: column;
      flex-flow: column;
      overflow-y: scroll;
      height: 100%;
      max-height: 100vh;

      &::-webkit-scrollbar {
        width: 0.6125rem;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(189, 197, 209, 0.6);
      }

      .navbar-collapse {
        width: 100%;
        display: block !important;
      }

      .navbar-nav {
        display: block;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: start;
        align-items: flex-start;
      }
    }

    .hs-position-right-fix {
      right: 25%;
      left: auto;
    }

    .navbar-dropdown-menu-promo {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
    }

    .navbar-dropdown-menu-promo-item {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      -ms-flex-direction: column;
      flex-direction: column;

      &:not(:last-child) .navbar-dropdown-menu-promo-link {
        position: relative;

        &::after {
          position: absolute;
          top: 0;
          right: 0;
          margin-right: -0.75rem;
          border-right: 0.0625rem solid rgba(0, 34, 28, 0.1);
          height: 100%;
          content: "";
        }
      }
    }
  }
}

.navbar-expand {
  .navbar-toggler {
    -ms-flex-order: 1;
    order: 1;
  }

  .navbar-collapse {
    width: auto;
    -ms-flex-order: 2;
    order: 2;
  }

  .navbar-nav-wrap-secondary-content {
    -ms-flex-order: 3;
    order: 3;
  }

  .navbar-nav {
    -ms-flex-align: center;
    align-items: center;
  }

  .nav-item {
    &:not(:last-child) {
      margin-right: 0.5rem;
    }

    .nav-item {
      margin-right: 0;
    }
  }

  .dropdown-menu {
    &[data-bs-popper] {
      margin-top: 0.5rem;
    }

    .hs-has-sub-menu .dropdown-menu {
      margin-top: -0.6875rem;
      margin-left: 0.5rem;

      &[data-bs-popper] {
        left: 100%;
      }

      &::before {
        top: 0;
        left: -1rem;
        width: 1rem;
        height: 100%;
      }
    }
  }

  &.navbar-end {
    .navbar-nav {
      -ms-flex-pack: end;
      justify-content: flex-end;
      margin-left: auto;
    }

    .navbar-sticky-top-scroller,
    .navbar-absolute-top-scroller {
      margin-left: auto;
    }
  }

  &.navbar-vertical {
    -ms-flex-flow: column;
    flex-flow: column;
    overflow-y: scroll;
    height: 100%;
    max-height: 100vh;

    &::-webkit-scrollbar {
      width: 0.6125rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(189, 197, 209, 0.6);
    }

    .navbar-collapse {
      width: 100%;
      display: block !important;
    }

    .navbar-nav {
      display: block;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-align: start;
      align-items: flex-start;
    }
  }

  .hs-position-right-fix {
    right: 25%;
    left: auto;
  }

  .navbar-dropdown-menu-promo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }

  .navbar-dropdown-menu-promo-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;

    &:not(:last-child) .navbar-dropdown-menu-promo-link {
      position: relative;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: -0.75rem;
        border-right: 0.0625rem solid rgba(0, 34, 28, 0.1);
        height: 100%;
        content: "";
      }
    }
  }

  .navbar-nav-wrap-secondary-content {
    margin-left: auto;

    ~ .navbar-toggler {
      margin-left: 0.5rem;
    }
  }

  .navbar-collapse {
    background-color: #fff;
  }

  .navbar-nav {
    padding: 1rem 1rem;

    .nav-subtitle,
    .nav-link {
      padding: 0.5rem 0;
    }
  }

  .nav-divider {
    width: 100%;
    height: 0.0625rem;
    border-left: none;
    border-top: 0.1rem solid rgba(0, 34, 28, 0.1);
    margin: 0.5rem 0;
  }

  .hs-mega-menu {
    max-width: 100% !important;
    min-width: 100% !important;
  }

  .navbar-nav .dropdown-menu {
    box-shadow: none;
    border: 0.0625rem solid rgba(0, 34, 28, 0.1);
    margin-top: 0;
  }

  &.navbar-dark .navbar-nav {
    background-color: #321844;
  }

  &.navbar-vertical .navbar-nav {
    padding: 0;
  }

  .navbar-dropdown-menu-promo {
    &:not(:first-child) .navbar-dropdown-menu-promo-item::after {
      top: -0.375rem;
    }

    &:first-child .navbar-dropdown-menu-promo-item {
      position: relative;

      &::after {
        position: absolute;
        bottom: -0.375rem;
        right: 0;
        border-bottom: 0.0625rem solid rgba(0, 34, 28, 0.1);
        width: 100%;
        content: "";
      }
    }

    &:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
      border-top-width: 0;
    }
  }

  .navbar-absolute-top-inner {
    background-color: #fff;
  }

  .navbar-nav {
    .nav-subtitle,
    .nav-link {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
}

@media (max-width: 575.98px) {
  .navbar-expand-sm {
    .navbar-nav-wrap-secondary-content {
      margin-left: auto;

      ~ .navbar-toggler {
        margin-left: 0.5rem;
      }
    }

    .navbar-collapse {
      background-color: #fff;
    }

    .navbar-nav {
      padding: 1rem 1rem;

      .nav-subtitle,
      .nav-link {
        padding: 0.5rem 0;
      }
    }

    .nav-divider {
      width: 100%;
      height: 0.0625rem;
      border-left: none;
      border-top: 0.1rem solid rgba(0, 34, 28, 0.1);
      margin: 0.5rem 0;
    }

    .hs-mega-menu {
      max-width: 100% !important;
      min-width: 100% !important;
    }

    .navbar-nav .dropdown-menu {
      box-shadow: none;
      border: 0.0625rem solid rgba(0, 34, 28, 0.1);
      margin-top: 0;
    }

    &.navbar-dark .navbar-nav {
      background-color: #321844;
    }

    &.navbar-vertical .navbar-nav {
      padding: 0;
    }

    .navbar-dropdown-menu-promo {
      &:not(:first-child) .navbar-dropdown-menu-promo-item::after {
        top: -0.375rem;
      }

      &:first-child .navbar-dropdown-menu-promo-item {
        position: relative;

        &::after {
          position: absolute;
          bottom: -0.375rem;
          right: 0;
          border-bottom: 0.0625rem solid rgba(0, 34, 28, 0.1);
          width: 100%;
          content: "";
        }
      }

      &:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
        border-top-width: 0;
      }
    }

    .navbar-absolute-top-inner {
      background-color: #fff;
    }
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md {
    .navbar-nav-wrap-secondary-content {
      margin-left: auto;

      ~ .navbar-toggler {
        margin-left: 0.5rem;
      }
    }

    .navbar-collapse {
      background-color: #fff;
    }

    .navbar-nav {
      padding: 1rem 1rem;

      .nav-subtitle,
      .nav-link {
        padding: 0.5rem 0;
      }
    }

    .nav-divider {
      width: 100%;
      height: 0.0625rem;
      border-left: none;
      border-top: 0.1rem solid rgba(0, 34, 28, 0.1);
      margin: 0.5rem 0;
    }

    .hs-mega-menu {
      max-width: 100% !important;
      min-width: 100% !important;
    }

    .navbar-nav .dropdown-menu {
      box-shadow: none;
      border: 0.0625rem solid rgba(0, 34, 28, 0.1);
      margin-top: 0;
    }

    &.navbar-dark .navbar-nav {
      background-color: #321844;
    }

    &.navbar-vertical .navbar-nav {
      padding: 0;
    }

    .navbar-dropdown-menu-promo {
      &:not(:first-child) .navbar-dropdown-menu-promo-item::after {
        top: -0.375rem;
      }

      &:first-child .navbar-dropdown-menu-promo-item {
        position: relative;

        &::after {
          position: absolute;
          bottom: -0.375rem;
          right: 0;
          border-bottom: 0.0625rem solid rgba(0, 34, 28, 0.1);
          width: 100%;
          content: "";
        }
      }

      &:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
        border-top-width: 0;
      }
    }

    .navbar-absolute-top-inner {
      background-color: #fff;
    }
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg {
    .navbar-nav-wrap-secondary-content {
      margin-left: auto;

      ~ .navbar-toggler {
        margin-left: 0.5rem;
      }
    }

    .navbar-collapse {
      background-color: #fff;
    }

    .navbar-nav {
      padding: 1rem 1rem;

      .nav-subtitle,
      .nav-link {
        padding: 0.5rem 0;
      }
    }

    .nav-divider {
      width: 100%;
      height: 0.0625rem;
      border-left: none;
      border-top: 0.1rem solid rgba(0, 34, 28, 0.1);
      margin: 0.5rem 0;
    }

    .hs-mega-menu {
      max-width: 100% !important;
      min-width: 100% !important;
    }

    .navbar-nav .dropdown-menu {
      box-shadow: none;
      border: 0.0625rem solid rgba(0, 34, 28, 0.1);
      margin-top: 0;
    }

    &.navbar-dark .navbar-nav {
      background-color: #321844;
    }

    &.navbar-vertical .navbar-nav {
      padding: 0;
    }

    .navbar-dropdown-menu-promo {
      &:not(:first-child) .navbar-dropdown-menu-promo-item::after {
        top: -0.375rem;
      }

      &:first-child .navbar-dropdown-menu-promo-item {
        position: relative;

        &::after {
          position: absolute;
          bottom: -0.375rem;
          right: 0;
          border-bottom: 0.0625rem solid rgba(0, 34, 28, 0.1);
          width: 100%;
          content: "";
        }
      }

      &:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
        border-top-width: 0;
      }
    }

    .navbar-absolute-top-inner {
      background-color: #fff;
    }
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl {
    .navbar-nav-wrap-secondary-content {
      margin-left: auto;

      ~ .navbar-toggler {
        margin-left: 0.5rem;
      }
    }

    .navbar-collapse {
      background-color: #fff;
    }

    .navbar-nav {
      padding: 1rem 1rem;

      .nav-subtitle,
      .nav-link {
        padding: 0.5rem 0;
      }
    }

    .nav-divider {
      width: 100%;
      height: 0.0625rem;
      border-left: none;
      border-top: 0.1rem solid rgba(0, 34, 28, 0.1);
      margin: 0.5rem 0;
    }

    .hs-mega-menu {
      max-width: 100% !important;
      min-width: 100% !important;
    }

    .navbar-nav .dropdown-menu {
      box-shadow: none;
      border: 0.0625rem solid rgba(0, 34, 28, 0.1);
      margin-top: 0;
    }

    &.navbar-dark .navbar-nav {
      background-color: #321844;
    }

    &.navbar-vertical .navbar-nav {
      padding: 0;
    }

    .navbar-dropdown-menu-promo {
      &:not(:first-child) .navbar-dropdown-menu-promo-item::after {
        top: -0.375rem;
      }

      &:first-child .navbar-dropdown-menu-promo-item {
        position: relative;

        &::after {
          position: absolute;
          bottom: -0.375rem;
          right: 0;
          border-bottom: 0.0625rem solid rgba(0, 34, 28, 0.1);
          width: 100%;
          content: "";
        }
      }

      &:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
        border-top-width: 0;
      }
    }

    .navbar-absolute-top-inner {
      background-color: #fff;
    }
  }
}

@media (max-width: 1399.98px) {
  .navbar-expand-xxl {
    .navbar-nav-wrap-secondary-content {
      margin-left: auto;

      ~ .navbar-toggler {
        margin-left: 0.5rem;
      }
    }

    .navbar-collapse {
      background-color: #fff;
    }

    .navbar-nav {
      padding: 1rem 1rem;

      .nav-subtitle,
      .nav-link {
        padding: 0.5rem 0;
      }
    }

    .nav-divider {
      width: 100%;
      height: 0.0625rem;
      border-left: none;
      border-top: 0.1rem solid rgba(0, 34, 28, 0.1);
      margin: 0.5rem 0;
    }

    .hs-mega-menu {
      max-width: 100% !important;
      min-width: 100% !important;
    }

    .navbar-nav .dropdown-menu {
      box-shadow: none;
      border: 0.0625rem solid rgba(0, 34, 28, 0.1);
      margin-top: 0;
    }

    &.navbar-dark .navbar-nav {
      background-color: #321844;
    }

    &.navbar-vertical .navbar-nav {
      padding: 0;
    }

    .navbar-dropdown-menu-promo {
      &:not(:first-child) .navbar-dropdown-menu-promo-item::after {
        top: -0.375rem;
      }

      &:first-child .navbar-dropdown-menu-promo-item {
        position: relative;

        &::after {
          position: absolute;
          bottom: -0.375rem;
          right: 0;
          border-bottom: 0.0625rem solid rgba(0, 34, 28, 0.1);
          width: 100%;
          content: "";
        }
      }

      &:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
        border-top-width: 0;
      }
    }

    .navbar-absolute-top-inner {
      background-color: #fff;
    }
  }
}

/*------------------------------------
  Navbar Sidebar
------------------------------------*/

.navbar-sidebar-aside-content {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

@media (min-width: 576px) {
  .navbar-sidebar-aside-sm {
    .navbar-sidebar {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      width: 17rem;
      padding: 0;

      .navbar-collapse {
        width: 100%;
      }

      .navbar-brand-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 17rem;
        height: 5rem;
        background-color: #fff;
        z-index: 99;
        padding: 1.25rem 1.5rem;
        border-bottom: 0.0625rem solid rgba(0, 34, 28, 0.1);
      }

      .navbar-brand {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 0;
      }

      .navbar-nav {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
      }

      .navbar-sidebar-aside-body {
        padding-top: 6.25rem;
      }
    }

    .navbar-sidebar-aside-content {
      margin-left: 17rem;
    }
  }
}

@media (min-width: 768px) {
  .navbar-sidebar-aside-md {
    .navbar-sidebar {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      width: 17rem;
      padding: 0;

      .navbar-collapse {
        width: 100%;
      }

      .navbar-brand-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 17rem;
        height: 5rem;
        background-color: #fff;
        z-index: 99;
        padding: 1.25rem 1.5rem;
        border-bottom: 0.0625rem solid rgba(0, 34, 28, 0.1);
      }

      .navbar-brand {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 0;
      }

      .navbar-nav {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
      }

      .navbar-sidebar-aside-body {
        padding-top: 6.25rem;
      }
    }

    .navbar-sidebar-aside-content {
      margin-left: 17rem;
    }
  }
}

@media (min-width: 992px) {
  .navbar-sidebar-aside-lg {
    .navbar-sidebar {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      width: 17rem;
      padding: 0;

      .navbar-collapse {
        width: 100%;
      }

      .navbar-brand-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 17rem;
        height: 5rem;
        background-color: #fff;
        z-index: 99;
        padding: 1.25rem 1.5rem;
        border-bottom: 0.0625rem solid rgba(0, 34, 28, 0.1);
      }

      .navbar-brand {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 0;
      }

      .navbar-nav {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
      }

      .navbar-sidebar-aside-body {
        padding-top: 6.25rem;
      }
    }

    .navbar-sidebar-aside-content {
      margin-left: 17rem;
    }
  }
}

@media (min-width: 1200px) {
  .navbar-sidebar-aside-xl {
    .navbar-sidebar {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      width: 17rem;
      padding: 0;

      .navbar-collapse {
        width: 100%;
      }

      .navbar-brand-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 17rem;
        height: 5rem;
        background-color: #fff;
        z-index: 99;
        padding: 1.25rem 1.5rem;
        border-bottom: 0.0625rem solid rgba(0, 34, 28, 0.1);
      }

      .navbar-brand {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 0;
      }

      .navbar-nav {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
      }

      .navbar-sidebar-aside-body {
        padding-top: 6.25rem;
      }
    }

    .navbar-sidebar-aside-content {
      margin-left: 17rem;
    }
  }
}

@media (min-width: 1400px) {
  .navbar-sidebar-aside-xxl {
    .navbar-sidebar {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      width: 17rem;
      padding: 0;

      .navbar-collapse {
        width: 100%;
      }

      .navbar-brand-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 17rem;
        height: 5rem;
        background-color: #fff;
        z-index: 99;
        padding: 1.25rem 1.5rem;
        border-bottom: 0.0625rem solid rgba(0, 34, 28, 0.1);
      }

      .navbar-brand {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 0;
      }

      .navbar-nav {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
      }

      .navbar-sidebar-aside-body {
        padding-top: 6.25rem;
      }
    }

    .navbar-sidebar-aside-content {
      margin-left: 17rem;
    }
  }
}

.navbar-sidebar-aside {
  .navbar-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 17rem;
    padding: 0;

    .navbar-collapse {
      width: 100%;
    }

    .navbar-brand-wrapper {
      position: fixed;
      top: 0;
      left: 0;
      width: 17rem;
      height: 5rem;
      background-color: #fff;
      z-index: 99;
      padding: 1.25rem 1.5rem;
      border-bottom: 0.0625rem solid rgba(0, 34, 28, 0.1);
    }

    .navbar-brand {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      -ms-flex-align: center;
      align-items: center;
      margin-right: 0;
    }

    .navbar-nav {
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
    }

    .navbar-sidebar-aside-body {
      padding-top: 6.25rem;
    }
  }

  .navbar-sidebar-aside-content {
    margin-left: 17rem;
  }

  .navbar-sidebar {
    .navbar-brand,
    .navbar-brand-badge {
      display: none;
    }
  }

  .nav-segment {
    margin: 1.25rem 1.5rem;
  }
}

@media (max-width: 575.98px) {
  .navbar-sidebar-aside-sm {
    .navbar-sidebar {
      .navbar-brand,
      .navbar-brand-badge {
        display: none;
      }
    }

    .nav-segment {
      margin: 1.25rem 1.5rem;
    }
  }
}

@media (max-width: 767.98px) {
  .navbar-sidebar-aside-md {
    .navbar-sidebar {
      .navbar-brand,
      .navbar-brand-badge {
        display: none;
      }
    }

    .nav-segment {
      margin: 1.25rem 1.5rem;
    }
  }
}

@media (max-width: 991.98px) {
  .navbar-sidebar-aside-lg {
    .navbar-sidebar {
      .navbar-brand,
      .navbar-brand-badge {
        display: none;
      }
    }

    .nav-segment {
      margin: 1.25rem 1.5rem;
    }
  }
}

@media (max-width: 1199.98px) {
  .navbar-sidebar-aside-xl {
    .navbar-sidebar {
      .navbar-brand,
      .navbar-brand-badge {
        display: none;
      }
    }

    .nav-segment {
      margin: 1.25rem 1.5rem;
    }
  }
}

@media (max-width: 1399.98px) {
  .navbar-sidebar-aside-xxl {
    .navbar-sidebar {
      .navbar-brand,
      .navbar-brand-badge {
        display: none;
      }
    }

    .nav-segment {
      margin: 1.25rem 1.5rem;
    }
  }
}

/*------------------------------------
  Navbar Skins
------------------------------------*/

.navbar-light {
  .navbar-brand {
    color: #51596c;

    &:hover,
    &:focus {
      color: #51596c;
    }
  }

  .navbar-toggler {
    color: #51596c;  
  }

  .navbar-nav .nav-link {
    color: #51596c;

    &.active,
    &:hover,
    &:focus {
      color: #321844;
    }
  }
}

.navbar-dark {
  .navbar-nav .nav-item:hover .nav-link {
    color: #fff;

    &:hover,
    &:focus {
      color: #fff;
    }
  }

  .nav-link.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='rgba(255, 255, 255, 0.55)' xmlns='http://www.w3.org/2000/svg'><path d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/></svg>");
  }

  .nav-divider {
    border-color: rgba(255, 255, 255, 0.2);
  }
}

/*------------------------------------
  Navbar Helpers
------------------------------------*/

.navbar-invisible {
  display: none;
}

.navbar-moved-up {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.navbar-faded {
  opacity: 0;
  visibility: hidden;
}

.navbar-section-hidden {
  position: relative;
}

.navbar {
  &[data-hs-header-options*="fixMoment"] {
    transition: 0.3s ease;
  }

  &.navbar-untransitioned {
    transition: none;
  }

  &.navbar-scrolled {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;

    .navbar-topbar {
      display: none;
    }
  }
}

.navbar-fix-top {
  position: fixed;
}

.navbar.navbar-fix-top[data-hs-header-options*="effectCompensation"] {
  transition: none;
}

/*------------------------------------
  Popover
------------------------------------*/

.popover {
  box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
}

.popover-header {
  font-size: 1.125rem;
  font-weight: 500;
}

/*------------------------------------
  Vertical Progress Bar
------------------------------------*/

.progress-vertical {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-color: #e7eaf3;
  width: 0.5rem;
  height: 12.5rem;
}

/*------------------------------------
  Shapes
------------------------------------*/

.shape-container {
  position: relative;
}

.shape {
  &[class*="text-"] > svg [fill]:not([fill="none"]) {
    fill: currentColor !important;
  }

  position: absolute;
  z-index: -1;
}

.shape-top {
  top: 0;
  left: 0;
  right: 0;

  > svg {
    width: 100%;
    height: auto;
    margin-bottom: -1px;
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center;
  }
}

.shape-bottom {
  bottom: 0;
  left: 0;
  right: 0;

  > svg {
    width: 100%;
    height: auto;
    margin-bottom: -1px;
    -webkit-transform-origin: top center;
    transform-origin: top center;
  }
}

/*------------------------------------
  Step
------------------------------------*/

.step {
  position: relative;
  list-style: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-right: calc(1.5rem / -2);
  margin-left: calc(1.5rem / -2);

  &.step-dashed .step-icon::after {
    border-left-style: dashed;
  }
}

.step-icon-border {
  border: 0.125rem solid rgba(0, 34, 28, 0.1);
}

.step-title {
  display: block;
  color: #321844;
  font-weight: 500;
}

.step-text:last-child {
  color: #51596c;
  margin-bottom: 0;
}

.step-border-last-0 .step-item:last-child .step-icon::after {
  display: none;
}

.step .step-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-bottom: 2.25rem;
}

.step-item-between .step-item:last-child {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}

.step {
  .step-content-wrapper {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }

  .step-content {
    -ms-flex: 1;
    flex: 1;
  }
}

.step-item {
  &.collapse:not(.show),
  .step-title-description {
    display: none;
  }

  &.focus .step-title-description {
    display: block;
  }
}

/*------------------------------------
  Step Avatar
------------------------------------*/

.step {
  .step-avatar {
    font-size: 1rem;
    font-weight: 500;
    width: 2.875rem;
    height: 2.875rem;
    border-radius: 50%;
    margin-right: 1rem;
  }

  .step-avatar-img {
    max-width: 100%;
    height: auto;
    border-radius: 50%;
  }

  .step-avatar::after {
    position: absolute;
    top: 3.625rem;
    left: 1.4375rem;
    height: calc(100% - 2.125rem);
    border-left: 0.125rem solid rgba(0, 34, 28, 0.1);
    content: "";
  }
}

/*------------------------------------
  Step Avatar
------------------------------------*/

.step-avatar-xs {
  .step-avatar,
  &.step-avatar {
    font-size: 0.75rem;
    width: 1.75rem;
    height: 1.75rem;
  }

  .step-avatar::after,
  &.step-avatar::after {
    top: 2.5rem;
    left: 0.8125rem;
    width: 1.625rem;
    height: calc(100% - 1rem);
  }

  .step-divider::after {
    left: 0.875rem;
  }
}

.step-avatar-sm {
  .step-avatar,
  &.step-avatar {
    font-size: 0.8125rem;
    width: 2.3125rem;
    height: 2.3125rem;
  }

  .step-avatar::after,
  &.step-avatar::after {
    top: 3.0625rem;
    left: 1.09375rem;
    width: 1.625rem;
    height: calc(100% - 1.5625rem);
  }

  .step-divider::after {
    left: 1.15625rem;
  }
}

.step-avatar-lg {
  .step-avatar,
  &.step-avatar {
    font-size: 1.125rem;
    width: 5.5rem;
    height: 5.5rem;
  }

  .step-avatar::after,
  &.step-avatar::after {
    top: 6.25rem;
    left: 2.6875rem;
    width: 1.625rem;
    height: calc(100% - 4.75rem);
  }

  .step-divider::after {
    left: 2.75rem;
  }
}

/*------------------------------------
  Step Divider
------------------------------------*/

.step-divider {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
  height: 1rem;
  font-weight: 500;

  &::after {
    position: absolute;
    top: 1.75rem;
    left: 1.4375rem;
    height: calc(100% - 0.25rem);
    border-left: 0.125rem solid rgba(0, 34, 28, 0.1);
    content: "";
  }
}

/*------------------------------------
  Step Icon
------------------------------------*/

.step {
  .step-icon {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    width: 2.875rem;
    height: 2.875rem;
    border-radius: 50%;
    margin-right: 1rem;

    &::after {
      position: absolute;
      top: 3.625rem;
      left: 1.4375rem;
      height: calc(100% - 2.125rem);
      border-left: 0.125rem solid rgba(0, 34, 28, 0.1);
      content: "";
    }
  }

  .step-icon-pseudo::before {
    display: block;
    width: 0.25rem;
    height: 0.25rem;
    background-color: #97a4af;
    border-radius: 50%;
    content: "";
  }
}

/*------------------------------------
  Step Icon Sizes
------------------------------------*/

.step-icon-xs {
  .step-icon,
  &.step-icon {
    font-size: 0.75rem;
    width: 1.75rem;
    height: 1.75rem;
  }

  .step-icon::after,
  &.step-icon::after {
    top: 2.5rem;
    left: 0.8125rem;
    width: 1.625rem;
    height: calc(100% - 1rem);
  }

  .step-divider::after {
    left: 0.875rem;
  }
}

.step-icon-sm {
  .step-icon,
  &.step-icon {
    font-size: 0.8125rem;
    width: 2.3125rem;
    height: 2.3125rem;
  }

  .step-icon::after,
  &.step-icon::after {
    top: 3.0625rem;
    left: 1.09375rem;
    width: 1.625rem;
    height: calc(100% - 1.5625rem);
  }

  .step-divider::after {
    left: 1.15625rem;
  }
}

.step-icon-lg {
  .step-icon,
  &.step-icon {
    font-size: 1.125rem;
    width: 5.5rem;
    height: 5.5rem;
  }

  .step-icon::after,
  &.step-icon::after {
    top: 6.25rem;
    left: 2.6875rem;
    width: 1.625rem;
    height: calc(100% - 4.75rem);
  }

  .step-divider::after {
    left: 2.75rem;
  }
}

/*------------------------------------
  Step Breakpoints
------------------------------------*/
@media (min-width: 576px) {
  .step-sm {
    &.step-dashed .step-icon::after {
      border-left: none;
      border-top-style: dashed;
    }

    .step-item {
      -ms-flex-positive: 1;
      flex-grow: 1;
      -ms-flex: 1;
      flex: 1;
      margin-bottom: 0;
    }

    &:not(.step-inline) .step-content-wrapper {
      display: block;
    }

    .step-icon {
      margin-bottom: 1rem;

      &::after {
        top: 1.4375rem;
        left: 4.375rem;
        width: calc(100% - 4.375rem);
        height: 1.625rem;
        border-top: 0.125rem solid rgba(0, 34, 28, 0.1);
        border-left: none;
      }
    }

    &.step-icon-xs .step-icon::after,
    .step-icon.step-icon-xs::after {
      top: 0.875rem;
      left: 3.25rem;
      width: calc(100% - 3.25rem);
    }

    &.step-icon-sm .step-icon::after,
    .step-icon.step-icon-sm::after {
      top: 1.15625rem;
      left: 3.8125rem;
      width: calc(100% - 3.8125rem);
    }

    &.step-icon-lg .step-icon::after,
    .step-icon.step-icon-lg::after {
      top: 2.75rem;
      left: 7rem;
      width: calc(100% - 7rem);
    }
  }
}

@media (min-width: 768px) {
  .step-md {
    &.step-dashed .step-icon::after {
      border-left: none;
      border-top-style: dashed;
    }

    .step-item {
      -ms-flex-positive: 1;
      flex-grow: 1;
      -ms-flex: 1;
      flex: 1;
      margin-bottom: 0;
    }

    &:not(.step-inline) .step-content-wrapper {
      display: block;
    }

    .step-icon {
      margin-bottom: 1rem;

      &::after {
        top: 1.4375rem;
        left: 4.375rem;
        width: calc(100% - 4.375rem);
        height: 1.625rem;
        border-top: 0.125rem solid rgba(0, 34, 28, 0.1);
        border-left: none;
      }
    }

    &.step-icon-xs .step-icon::after,
    .step-icon.step-icon-xs::after {
      top: 0.875rem;
      left: 3.25rem;
      width: calc(100% - 3.25rem);
    }

    &.step-icon-sm .step-icon::after,
    .step-icon.step-icon-sm::after {
      top: 1.15625rem;
      left: 3.8125rem;
      width: calc(100% - 3.8125rem);
    }

    &.step-icon-lg .step-icon::after,
    .step-icon.step-icon-lg::after {
      top: 2.75rem;
      left: 7rem;
      width: calc(100% - 7rem);
    }
  }
}

@media (min-width: 992px) {
  .step-lg {
    &.step-dashed .step-icon::after {
      border-left: none;
      border-top-style: dashed;
    }

    .step-item {
      -ms-flex-positive: 1;
      flex-grow: 1;
      -ms-flex: 1;
      flex: 1;
      margin-bottom: 0;
    }

    &:not(.step-inline) .step-content-wrapper {
      display: block;
    }

    .step-icon {
      margin-bottom: 1rem;

      &::after {
        top: 1.4375rem;
        left: 4.375rem;
        width: calc(100% - 4.375rem);
        height: 1.625rem;
        border-top: 0.125rem solid rgba(0, 34, 28, 0.1);
        border-left: none;
      }
    }

    &.step-icon-xs .step-icon::after,
    .step-icon.step-icon-xs::after {
      top: 0.875rem;
      left: 3.25rem;
      width: calc(100% - 3.25rem);
    }

    &.step-icon-sm .step-icon::after,
    .step-icon.step-icon-sm::after {
      top: 1.15625rem;
      left: 3.8125rem;
      width: calc(100% - 3.8125rem);
    }

    &.step-icon-lg .step-icon::after,
    .step-icon.step-icon-lg::after {
      top: 2.75rem;
      left: 7rem;
      width: calc(100% - 7rem);
    }
  }
}

@media (min-width: 1200px) {
  .step-xl {
    &.step-dashed .step-icon::after {
      border-left: none;
      border-top-style: dashed;
    }

    .step-item {
      -ms-flex-positive: 1;
      flex-grow: 1;
      -ms-flex: 1;
      flex: 1;
      margin-bottom: 0;
    }

    &:not(.step-inline) .step-content-wrapper {
      display: block;
    }

    .step-icon {
      margin-bottom: 1rem;

      &::after {
        top: 1.4375rem;
        left: 4.375rem;
        width: calc(100% - 4.375rem);
        height: 1.625rem;
        border-top: 0.125rem solid rgba(0, 34, 28, 0.1);
        border-left: none;
      }
    }

    &.step-icon-xs .step-icon::after,
    .step-icon.step-icon-xs::after {
      top: 0.875rem;
      left: 3.25rem;
      width: calc(100% - 3.25rem);
    }

    &.step-icon-sm .step-icon::after,
    .step-icon.step-icon-sm::after {
      top: 1.15625rem;
      left: 3.8125rem;
      width: calc(100% - 3.8125rem);
    }

    &.step-icon-lg .step-icon::after,
    .step-icon.step-icon-lg::after {
      top: 2.75rem;
      left: 7rem;
      width: calc(100% - 7rem);
    }
  }
}

/*------------------------------------
  Step Centered
------------------------------------*/
@media (min-width: 576px) {
  .step-sm.step-centered {
    text-align: center;

    .step-item:last-child .step-icon::after {
      display: none;
    }

    .step-icon {
      margin-left: auto;
      margin-right: auto;

      &::after {
        width: calc(100% - 4.375rem);
        left: calc(50% + 2.9375rem);
      }
    }

    &.step-icon-xs .step-icon::after,
    .step-icon.step-icon-xs::after {
      width: calc(100% - 3.25rem);
      left: calc(50% + 2.375rem);
    }

    &.step-icon-sm .step-icon::after,
    .step-icon.step-icon-sm::after {
      width: calc(100% - 3.8125rem);
      left: calc(50% + 2.65625rem);
    }

    &.step-icon-lg .step-icon::after,
    .step-icon.step-icon-lg::after {
      width: calc(100% - 7rem);
      left: calc(50% + 4.25rem);
    }
  }
}

@media (min-width: 768px) {
  .step-md.step-centered {
    text-align: center;

    .step-item:last-child .step-icon::after {
      display: none;
    }

    .step-icon {
      margin-left: auto;
      margin-right: auto;

      &::after {
        width: calc(100% - 4.375rem);
        left: calc(50% + 2.9375rem);
      }
    }

    &.step-icon-xs .step-icon::after,
    .step-icon.step-icon-xs::after {
      width: calc(100% - 3.25rem);
      left: calc(50% + 2.375rem);
    }

    &.step-icon-sm .step-icon::after,
    .step-icon.step-icon-sm::after {
      width: calc(100% - 3.8125rem);
      left: calc(50% + 2.65625rem);
    }

    &.step-icon-lg .step-icon::after,
    .step-icon.step-icon-lg::after {
      width: calc(100% - 7rem);
      left: calc(50% + 4.25rem);
    }
  }
}

@media (min-width: 992px) {
  .step-lg.step-centered {
    text-align: center;

    .step-item:last-child .step-icon::after {
      display: none;
    }

    .step-icon {
      margin-left: auto;
      margin-right: auto;

      &::after {
        width: calc(100% - 4.375rem);
        left: calc(50% + 2.9375rem);
      }
    }

    &.step-icon-xs .step-icon::after,
    .step-icon.step-icon-xs::after {
      width: calc(100% - 3.25rem);
      left: calc(50% + 2.375rem);
    }

    &.step-icon-sm .step-icon::after,
    .step-icon.step-icon-sm::after {
      width: calc(100% - 3.8125rem);
      left: calc(50% + 2.65625rem);
    }

    &.step-icon-lg .step-icon::after,
    .step-icon.step-icon-lg::after {
      width: calc(100% - 7rem);
      left: calc(50% + 4.25rem);
    }
  }
}

@media (min-width: 992px) {
  .step-lg.step-centered {
    text-align: center;

    .step-item:last-child .step-icon::after {
      display: none;
    }

    .step-icon {
      margin-left: auto;
      margin-right: auto;

      &::after {
        width: calc(100% - 4.375rem);
        left: calc(50% + 2.9375rem);
      }
    }

    &.step-icon-xs .step-icon::after,
    .step-icon.step-icon-xs::after {
      width: calc(100% - 3.25rem);
      left: calc(50% + 2.375rem);
    }

    &.step-icon-sm .step-icon::after,
    .step-icon.step-icon-sm::after {
      width: calc(100% - 3.8125rem);
      left: calc(50% + 2.65625rem);
    }

    &.step-icon-lg .step-icon::after,
    .step-icon.step-icon-lg::after {
      width: calc(100% - 7rem);
      left: calc(50% + 4.25rem);
    }
  }
}

/*------------------------------------
  Step States
------------------------------------*/

.step {
  .step-is-valid-icon,
  .step-is-invalid-icon {
    display: none;
  }

  .active {
    .step-icon,
    &.is-valid .step-icon {
      color: #fff;
      background-color: #321844;
    }

    .step-title,
    &.is-valid .step-title {
      color: #321844;
    }
  }

  .is-valid {
    .step-icon {
      color: #fff;
      background-color: #321844;
    }

    .step-title {
      color: #321844;
    }

    .step-is-valid-icon {
      display: -ms-inline-flexbox;
      display: inline-flex;
    }

    .step-is-default-icon,
    .step-is-invalid-icon {
      display: none;
    }
  }

  .is-invalid {
    .step-icon {
      color: #fff;
      background-color: #692340;
    }

    .step-title {
      color: #692340;
    }

    .step-is-invalid-icon {
      display: -ms-inline-flexbox;
      display: inline-flex;
    }

    .step-is-default-icon,
    .step-is-valid-icon {
      display: none;
    }
  }
}

/*------------------------------------
  Step Colors
------------------------------------*/

.step-icon-primary {
  color: #fff;
  background-color: #321844;

  &.step-icon-pseudo::before {
    background-color: #fff;
  }
}

.step-icon-secondary {
  color: #fff;
  background-color: #51596c;

  &.step-icon-pseudo::before {
    background-color: #fff;
  }
}

.step-icon-success {
  color: #fff;
  background-color: #077c76;

  &.step-icon-pseudo::before {
    background-color: #fff;
  }
}

.step-icon-info {
  color: #fff;
  background-color: #334ac0;

  &.step-icon-pseudo::before {
    background-color: #fff;
  }
}

.step-icon-warning {
  color: #000;
  background-color: #f1b980;

  &.step-icon-pseudo::before {
    background-color: #000;
  }
}

.step-icon-danger {
  color: #fff;
  background-color: #692340;

  &.step-icon-pseudo::before {
    background-color: #fff;
  }
}

.step-icon-light {
  color: #000;
  background-color: #f9fafc;

  &.step-icon-pseudo::before {
    background-color: #000;
  }
}

.step-icon-dark {
  color: #fff;
  background-color: #321844;

  &.step-icon-pseudo::before {
    background-color: #fff;
  }
}

.step-icon-soft-primary {
  color: #321844;
  background-color: rgba(0, 128, 96, 0.1);

  &.step-icon-pseudo::before {
    background-color: #321844;
  }
}

.step-icon-soft-secondary {
  color: #51596c;
  background-color: rgba(81, 89, 108, 0.1);

  &.step-icon-pseudo::before {
    background-color: #51596c;
  }
}

.step-icon-soft-success {
  color: #077c76;
  background-color: rgba(7, 124, 118, 0.1);

  &.step-icon-pseudo::before {
    background-color: #077c76;
  }
}

.step-icon-soft-info {
  color: #334ac0;
  background-color: rgba(51, 74, 192, 0.1);

  &.step-icon-pseudo::before {
    background-color: #334ac0;
  }
}

.step-icon-soft-warning {
  color: #f1b980;
  background-color: rgba(241, 185, 128, 0.1);

  &.step-icon-pseudo::before {
    background-color: #f1b980;
  }
}

.step-icon-soft-danger {
  color: #692340;
  background-color: rgba(105, 35, 64, 0.1);

  &.step-icon-pseudo::before {
    background-color: #692340;
  }
}

.step-icon-soft-light {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);

  &.step-icon-pseudo::before {
    background-color: #f9fafc;
  }
}

.step-icon-soft-dark {
  color: #321844;
  background-color: rgba(0, 34, 28, 0.1);

  &.step-icon-pseudo::before {
    background-color: #321844;
  }
}

/*------------------------------------
  Step Inline
------------------------------------*/

.step-inline {
  .step-content-wrapper {
    -ms-flex-align: center;
    align-items: center;
  }

  .step-item:last-child .step-title::after {
    display: none;
  }

  .step-title {
    display: inline-block;
  }
}

@media (min-width: 576px) {
  .step-sm.step-inline {
    &.step-dashed .step-title::after {
      border-top-style: dashed;
    }

    .step-item {
      overflow: hidden;
    }

    .step-icon {
      margin-bottom: 0;

      &::after {
        display: none;
      }
    }

    .step-title::after {
      position: absolute;
      top: 1.4375rem;
      width: 100%;
      height: 1.625rem;
      border-top: 0.125rem solid rgba(0, 34, 28, 0.1);
      margin-left: 1.5rem;
      content: "";
    }

    &.step-icon-xs .step-content .step-title::after,
    .step-icon-xs + .step-content .step-title::after {
      top: 0.875rem;
    }

    &.step-icon-sm .step-content .step-title::after,
    .step-icon-sm + .step-content .step-title::after {
      top: 1.15625rem;
    }

    &.step-icon-lg .step-content .step-title::after,
    .step-icon-lg + .step-content .step-title::after {
      top: 2.75rem;
    }
  }
}

@media (min-width: 768px) {
  .step-md.step-inline {
    &.step-dashed .step-title::after {
      border-top-style: dashed;
    }

    .step-item {
      overflow: hidden;
    }

    .step-icon {
      margin-bottom: 0;

      &::after {
        display: none;
      }
    }

    .step-title::after {
      position: absolute;
      top: 1.4375rem;
      width: 100%;
      height: 1.625rem;
      border-top: 0.125rem solid rgba(0, 34, 28, 0.1);
      margin-left: 1.5rem;
      content: "";
    }

    &.step-icon-xs .step-content .step-title::after,
    .step-icon-xs + .step-content .step-title::after {
      top: 0.875rem;
    }

    &.step-icon-sm .step-content .step-title::after,
    .step-icon-sm + .step-content .step-title::after {
      top: 1.15625rem;
    }

    &.step-icon-lg .step-content .step-title::after,
    .step-icon-lg + .step-content .step-title::after {
      top: 2.75rem;
    }
  }
}

@media (min-width: 992px) {
  .step-lg.step-inline {
    &.step-dashed .step-title::after {
      border-top-style: dashed;
    }

    .step-item {
      overflow: hidden;
    }

    .step-icon {
      margin-bottom: 0;

      &::after {
        display: none;
      }
    }

    .step-title::after {
      position: absolute;
      top: 1.4375rem;
      width: 100%;
      height: 1.625rem;
      border-top: 0.125rem solid rgba(0, 34, 28, 0.1);
      margin-left: 1.5rem;
      content: "";
    }

    &.step-icon-xs .step-content .step-title::after,
    .step-icon-xs + .step-content .step-title::after {
      top: 0.875rem;
    }

    &.step-icon-sm .step-content .step-title::after,
    .step-icon-sm + .step-content .step-title::after {
      top: 1.15625rem;
    }

    &.step-icon-lg .step-content .step-title::after,
    .step-icon-lg + .step-content .step-title::after {
      top: 2.75rem;
    }
  }
}

@media (min-width: 1200px) {
  .step-xl.step-inline {
    &.step-dashed .step-title::after {
      border-top-style: dashed;
    }

    .step-item {
      overflow: hidden;
    }

    .step-icon {
      margin-bottom: 0;

      &::after {
        display: none;
      }
    }

    .step-title::after {
      position: absolute;
      top: 1.4375rem;
      width: 100%;
      height: 1.625rem;
      border-top: 0.125rem solid rgba(0, 34, 28, 0.1);
      margin-left: 1.5rem;
      content: "";
    }

    &.step-icon-xs .step-content .step-title::after,
    .step-icon-xs + .step-content .step-title::after {
      top: 0.875rem;
    }

    &.step-icon-sm .step-content .step-title::after,
    .step-icon-sm + .step-content .step-title::after {
      top: 1.15625rem;
    }

    &.step-icon-lg .step-content .step-title::after,
    .step-icon-lg + .step-content .step-title::after {
      top: 2.75rem;
    }
  }
}

/*------------------------------------
  Step Timeline
------------------------------------*/
@media (min-width: 576px) {
  .step-timeline-sm {
    margin-left: 0;
    margin-right: 0;

    .step-item {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: 0;
      padding-right: 0;
      margin-left: 50%;

      &:nth-child(even) {
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        text-align: right;
        margin-left: auto;
        margin-right: 50%;

        .step-content-wrapper {
          -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
        }

        .step-icon {
          margin-left: 0;
          margin-right: -1.4375rem;
        }

        .step-icon-xs {
          margin-right: -0.875rem;
        }

        .step-icon-sm {
          margin-right: -1.15625rem;
        }

        .step-icon-lg {
          margin-right: -2.75rem;
        }

        .step-content {
          margin-right: 1.5rem;
        }
      }
    }

    .step-icon {
      margin-left: -1.4375rem;

      &::after {
        left: auto;
        width: auto;
      }
    }

    .step-icon-xs {
      margin-left: -0.875rem;
    }

    .step-icon-sm {
      margin-left: -1.15625rem;
    }

    .step-icon-lg {
      margin-left: -2.75rem;
    }
  }
}

@media (min-width: 768px) {
  .step-timeline-md {
    margin-left: 0;
    margin-right: 0;

    .step-item {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: 0;
      padding-right: 0;
      margin-left: 50%;

      &:nth-child(even) {
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        text-align: right;
        margin-left: auto;
        margin-right: 50%;

        .step-content-wrapper {
          -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
        }

        .step-icon {
          margin-left: 0;
          margin-right: -1.4375rem;
        }

        .step-icon-xs {
          margin-right: -0.875rem;
        }

        .step-icon-sm {
          margin-right: -1.15625rem;
        }

        .step-icon-lg {
          margin-right: -2.75rem;
        }

        .step-content {
          margin-right: 1.5rem;
        }
      }
    }

    .step-icon {
      margin-left: -1.4375rem;

      &::after {
        left: auto;
        width: auto;
      }
    }

    .step-icon-xs {
      margin-left: -0.875rem;
    }

    .step-icon-sm {
      margin-left: -1.15625rem;
    }

    .step-icon-lg {
      margin-left: -2.75rem;
    }
  }
}

@media (min-width: 992px) {
  .step-timeline-lg {
    margin-left: 0;
    margin-right: 0;

    .step-item {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: 0;
      padding-right: 0;
      margin-left: 50%;

      &:nth-child(even) {
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        text-align: right;
        margin-left: auto;
        margin-right: 50%;

        .step-content-wrapper {
          -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
        }

        .step-icon {
          margin-left: 0;
          margin-right: -1.4375rem;
        }

        .step-icon-xs {
          margin-right: -0.875rem;
        }

        .step-icon-sm {
          margin-right: -1.15625rem;
        }

        .step-icon-lg {
          margin-right: -2.75rem;
        }

        .step-content {
          margin-right: 1.5rem;
        }
      }
    }

    .step-icon {
      margin-left: -1.4375rem;

      &::after {
        left: auto;
        width: auto;
      }
    }

    .step-icon-xs {
      margin-left: -0.875rem;
    }

    .step-icon-sm {
      margin-left: -1.15625rem;
    }

    .step-icon-lg {
      margin-left: -2.75rem;
    }
  }
}

@media (min-width: 1200px) {
  .step-timeline-xl {
    margin-left: 0;
    margin-right: 0;

    .step-item {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: 0;
      padding-right: 0;
      margin-left: 50%;

      &:nth-child(even) {
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        text-align: right;
        margin-left: auto;
        margin-right: 50%;

        .step-content-wrapper {
          -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
        }

        .step-icon {
          margin-left: 0;
          margin-right: -1.4375rem;
        }

        .step-icon-xs {
          margin-right: -0.875rem;
        }

        .step-icon-sm {
          margin-right: -1.15625rem;
        }

        .step-icon-lg {
          margin-right: -2.75rem;
        }

        .step-content {
          margin-right: 1.5rem;
        }
      }
    }

    .step-icon {
      margin-left: -1.4375rem;

      &::after {
        left: auto;
        width: auto;
      }
    }

    .step-icon-xs {
      margin-left: -0.875rem;
    }

    .step-icon-sm {
      margin-left: -1.15625rem;
    }

    .step-icon-lg {
      margin-left: -2.75rem;
    }
  }
}

/*------------------------------------
  SVG Icon
------------------------------------*/

.svg-icon {
  display: block;

  &[class*="text-"] > svg [fill]:not([fill="none"]) {
    fill: currentColor !important;
  }

  > svg {
    width: 3rem;
    height: 3rem;
  }
}

.svg-icon-xs > svg {
  width: 1.25rem;
  height: 1.25rem;
}

.svg-icon-sm > svg {
  width: 1.75rem;
  height: 1.75rem;
}

.svg-icon-lg > svg {
  width: 4rem;
  height: 4rem;
}

/*------------------------------------
  Toasts
------------------------------------*/

.toast-header .btn-close {
  margin-right: 0;
}

/*------------------------------------
  Tables
------------------------------------*/

.table {
  th {
    font-weight: 400;
  }

  tr {
    color: #321844;
  }

  thead th {
    color: #321844;
    font-weight: 500;
  }

  > {
    :not(:last-child) > :last-child > * {
      border-bottom-color: rgba(0, 34, 28, 0.1);
    }

    :not(caption) > *:last-child > * {
      border-bottom-width: 0;
    }
  }

  .btn {
    white-space: nowrap;
  }
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.table-align-middle {
  tbody tr,
  th,
  td {
    vertical-align: middle;
  }
}

.table-text-center {
  text-align: center;

  th,
  td {
    text-align: center;
  }
}

.table-text-end {
  text-align: right;

  th,
  td {
    text-align: right;
  }
}

.thead-light th {
  background-color: #f8fafd;
}

.table-lg > :not(caption) > * > * {
  padding: 1rem 1.5rem;
}

/*------------------------------------
  Background Image Style
------------------------------------*/

.bg-img-start {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.bg-img-end {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.bg-img-center {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

/*------------------------------------
  Lines
------------------------------------*/

.lines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.lines-container {
  position: relative;
  display: grid;
  grid: 1fr / repeat(4, 1fr);
  height: 100%;
  max-width: 1160px;
  margin: 0 auto;
}

.lines-item {
  width: 0.0625rem;
  height: 100%;
  border-left: 0.0625rem solid rgba(0, 34, 28, 0.1);

  &:last-of-type {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.lines-light .lines-item {
  border-left-color: rgba(255, 255, 255, 0.1);
}

/*------------------------------------
  Link
------------------------------------*/

.link {
  font-weight: 500;
}

.link-primary {
  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.link-secondary {
  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.link-success {
  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.link-info {
  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.link-warning {
  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.link-danger {
  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.link-light {
  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.link-dark {
  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.link-sm {
  font-size: 0.875rem;
}

.link-bordered {
  border-bottom: 0.125rem solid #321844;

  &.link-primary {
    border-bottom-color: #321844;
  }

  &.link-secondary {
    border-bottom-color: #51596c;
  }

  &.link-success {
    border-bottom-color: #077c76;
  }

  &.link-info {
    border-bottom-color: #334ac0;
  }

  &.link-warning {
    border-bottom-color: #f1b980;
  }

  &.link-danger {
    border-bottom-color: #692340;
  }

  &.link-light {
    border-bottom-color: #f9fafc;
  }

  &.link-dark {
    border-bottom-color: #321844;
  }
}

.link-collapse {
  &[aria-expanded="false"] {
    .link-collapse-default {
      display: inline-block;
    }

    .link-collapse-active {
      display: none;
    }
  }

  &[aria-expanded="true"] {
    .link-collapse-default {
      display: none;
    }

    .link-collapse-active {
      display: inline-block;
    }
  }
}

/*------------------------------------
  Typography
------------------------------------*/

.text-cap {
  display: block;
  color: #321844;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.03125rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

/*------------------------------------
  Text Colors
------------------------------------*/

.text-dark {
  color: #321844 !important;

  &[href]:hover {
    color: #321844 !important;
  }
}

.text-secondary[href]:hover,
.text-muted[href]:hover,
.text-body[href]:hover,
a:hover .text-inherit {
  color: #321844 !important;
}

.lead {
  color: #425466;
  font-size: 16px !important;
}

.heading-color {
  color: #0a2540;
}

/*------------------------------------
  Transform
------------------------------------*/

.transform-0 {
  -webkit-transform: translate(0rem, 0rem);
  transform: translate(0rem, 0rem);
}

.transform-y-5rem {
  -webkit-transform: translateY(5rem);
  transform: translateY(5rem);
}

@media (max-width: 767.98px) {
  .transform-y-5rem {
    -webkit-transform: translateY(3rem);
    transform: translateY(3rem);
  }
}

.rotated-3d-left {
  -webkit-transform: perspective(1140px) rotateX(7deg) rotateY(-17deg)
    rotateZ(4deg);
  transform: perspective(1140px) rotateX(7deg) rotateY(-17deg) rotateZ(4deg);
}

.rotated-3d-right {
  -webkit-transform: perspective(1140px) rotateX(7deg) rotateY(17deg)
    rotateZ(-4deg);
  transform: perspective(1140px) rotateX(7deg) rotateY(17deg) rotateZ(-4deg);
}

/*------------------------------------
  Divider
------------------------------------*/

.divider-start {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: #8c98a4;

  &::after {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    border-top: 0.0625rem solid rgba(0, 34, 28, 0.1);
    margin-top: 0.0625rem;
    content: "";
    margin-left: 1.5rem;
  }
}

.divider-end {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: #8c98a4;

  &::before {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    border-top: 0.0625rem solid rgba(0, 34, 28, 0.1);
    margin-top: 0.0625rem;
    content: "";
    margin-right: 1.5rem;
  }
}

.divider-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: #8c98a4;

  &::before,
  &::after {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    border-top: 0.0625rem solid rgba(0, 34, 28, 0.1);
    margin-top: 0.0625rem;
    content: "";
  }

  &::before {
    margin-right: 1.5rem;
  }

  &::after {
    margin-left: 1.5rem;
  }
}

/*------------------------------------
  Nav Scroll Horizontal
------------------------------------*/

.hs-nav-scroller-horizontal {
  position: relative;

  .nav {
    overflow-x: auto;
    overflow-y: hidden;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    white-space: nowrap;
    scroll-behavior: smooth;

    .nav-item {
      white-space: nowrap;
    }

    .nav-link {
      white-space: normal;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .nav-tabs {
    padding-bottom: 0.1875rem;
  }

  .hs-nav-scroller-arrow-prev,
  .hs-nav-scroller-arrow-next {
    position: absolute;
    height: 100%;
    z-index: 1;
    font-size: 1.5rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }

  .hs-nav-scroller-arrow-link {
    width: 2.875rem;
    color: #51596c;

    &:hover {
      color: #321844;
    }
  }

  .hs-nav-scroller-arrow-prev {
    left: 0;
    margin-left: -0.125rem;
    background-image: linear-gradient(
      to right,
      #fff 50%,
      rgba(255, 255, 255, 0) 100%
    );

    .hs-nav-scroller-arrow-link {
      padding: 0.5rem 0;
    }
  }

  .hs-nav-scroller-arrow-next {
    right: 0;
    margin-right: -0.125rem;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      #fff 50%
    );

    .hs-nav-scroller-arrow-link {
      padding: 0.5rem 0;
      text-align: right;
    }
  }

  .hs-nav-scroller-arrow-dark-prev .hs-nav-scroller-arrow-link,
  .hs-nav-scroller-arrow-dark-next .hs-nav-scroller-arrow-link {
    color: rgba(255, 255, 255, 0.7);
  }

  .hs-nav-scroller-arrow-dark-prev .hs-nav-scroller-arrow-link:hover,
  .hs-nav-scroller-arrow-dark-next .hs-nav-scroller-arrow-link:hover {
    color: #fff;
  }

  .hs-nav-scroller-arrow-dark-prev {
    background-image: linear-gradient(
      to right,
      #321844 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .hs-nav-scroller-arrow-dark-next {
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      #321844 50%
    );
  }
}

.hs-nav-scroller-vertical {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.6125rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
  }
}

.hs-nav-scroller-unfold {
  position: static;
}

/*--------------------------------------------------
  Fancybox
----------------------------------------------------*/

.fancybox-custom {
  .fancybox-slide.animated {
    display: block;
    opacity: 0;
    z-index: 0;

    &.fancybox-slide--current {
      opacity: 1;
      z-index: 1;
    }
  }

  .fancybox-content {
    background-color: transparent;
  }

  .fancybox-bg {
    background-color: #223b41;
  }

  .fancybox-button svg {
    margin-bottom: 0;
  }

  .fancybox-progress {
    background-color: #321844;
  }
}

.fancybox-blur {
  header,
  aside,
  main,
  footer {
    -webkit-filter: blur(30px);
    filter: blur(30px);
  }
}

/*------------------------------------
  Swiper
------------------------------------*/

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  z-index: 1;
}

.swiper-button-next,
.swiper-button-prev {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  color: #677788;
  width: 2.875rem;
  height: 2.875rem;
  background-color: #fff;
  box-shadow: 0rem 0.1875rem 0.4375rem 0rem rgba(140, 152, 164, 0.4);
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  width: 1rem;
  height: 1rem;
  font-family: inherit;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  content: "";
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.5;
  box-shadow: none;
}

.swiper-button-next {
  &:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23677788'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }

  &:hover:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23008060'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }
}

.swiper-button-prev {
  &:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23677788'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
  }

  &:hover:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23008060'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
  }
}

.swiper-pagination {
  position: static;
  width: 100% !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 2rem;
}

.swiper-horizontal
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 0%;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

.swiper-pagination-bullets {
  &.swiper-pagination-bullets-dynamic {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  .swiper-pagination-bullet {
    margin: 0 0.25rem;
  }
}

.swiper-pagination-bullet {
  position: relative;
  opacity: 1;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.0625rem solid transparent;
  background-color: transparent;
  transition: 0.2s;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.25rem;
    height: 0.25rem;
    background-color: #bdc5d1;
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transition: 0.2s;
    content: "";
  }

  &:hover {
    border-color: rgba(0, 128, 96, 0.5);

    &::before {
      background-color: rgba(0, 128, 96, 0.5);
    }
  }
}

.swiper-pagination-bullet-active {
  border-color: #321844;

  &:hover {
    border-color: #321844;
  }

  &::before,
  &:hover::before {
    background-color: #321844;
  }
}

.swiper-pagination-light {
  .swiper-pagination-bullet {
    &::before {
      background-color: rgba(255, 255, 255, 0.5);
    }

    &:hover {
      border-color: #fff;

      &::before {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .swiper-pagination-bullet-active {
    border-color: #fff;

    &:hover {
      border-color: #fff;
    }

    &::before,
    &:hover::before {
      background-color: #fff;
    }
  }
}

.swiper-pagination-progress {
  cursor: pointer;
}

.swiper-pagination-progress-body {
  position: relative;
  display: block;
  width: 100%;
  height: 0.25rem;
  background-color: rgba(0, 34, 28, 0.25);
}

.swiper-pagination-progress-body-helper {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 0;
  height: 100%;
  background-color: #321844;
  transition: none;
}

.swiper-slide-thumb-active .swiper-pagination-progress-body-helper {
  transition-property: width;
  transition-timing-function: linear;
  width: 100%;
}

.swiper-pagination-progress-light {
  .swiper-pagination-progress-body {
    background-color: rgba(255, 255, 255, 0.5);
  }

  .swiper-pagination-progress-body-helper {
    background-color: #fff;
  }
}

.swiper-pagination-progressbar {
  background-color: rgba(0, 34, 28, 0.25);

  .swiper-pagination-progressbar-fill {
    background-color: #321844;
  }
}

.swiper-pagination-fraction {
  display: block;
  letter-spacing: 0.125rem;

  .swiper-pagination-current {
    font-size: 4rem;
    line-height: 4rem;
  }
}

.swiper-thumbs {
  box-sizing: border-box;

  .swiper-slide {
    cursor: pointer;
    opacity: 0.4;
  }

  .swiper-slide-thumb-active {
    opacity: 1;
  }
}

.swiper-equal-height {
  .swiper-wrapper {
    display: -ms-flexbox;
    display: flex;
  }

  .swiper-slide {
    display: -ms-flexbox;
    display: flex;
    height: auto;
  }
}

.swiper-pagination-vertical.swiper-pagination-bullets,
.swiper-vertical > .swiper-pagination-bullets {
  position: absolute;
  width: auto !important;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin: 0;
}

.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0.25rem 0;
}

/*------------------------------------
  File for your custom SCSS style
------------------------------------*/

.font-weight-normal {
  font-weight: 400 !important;
}

main#content {
  margin-top: 50px;
  @media (min-width: 1024px) {
    margin-top: 70px;
  }
}

.line-height-1 {
  line-height: 1;
}

.aic {
  align-items: center;
}
.aife {
  align-items: flex-end;
}
.jcsb {
  justify-content: space-between;
}
.jcc {
  justify-content: center;
}

